import Vue from "vue";
import Vuex, { ActionContext } from "vuex";
import { UserLoggedInfoModel } from "../models/userModels";
import eventHub from "../eventHub";
import api from "@/services/api";
import VuexPersistence from "vuex-persist";
import { associaDistributoriPacchettiExtended } from "@/models/carrello/prodotti";
import { anagraficaPacchettiCategorie } from "@/models/carrello/categoria";
import { parametriPreventivatoreItem } from "@/models/carrello/parametriPreventivatore";
import { CarrelloInfoProdotto } from "@/models/carrello/infoProdotto";
import { stat } from "fs";
import { RigaCarrelloInfo } from "@/models/carrello/rigaCarrello";
import { PartecipantiCarrello } from "@/models/carrello/partecipantiCarrello";
import { parametriDichiarazioni } from "@/models/carrello/parametriDichiarazioni";
import { datiViaggio } from "@/models/carrello/datiViaggio";
import { TextValueItem } from "@/models/TextValueItem";

Vue.use(Vuex);

var VERSION = "0.0.0.2";

class Store {
	constructor() {
		this.user = null;
		this.appCulture = "it-IT";
		// this.appCulture = localStorage.getItem("app-culutre")
		//   ?  localStorage.getItem("app-culutre")
		//   : "it-IT";
		this.version = VERSION;
		this.width = 0;
		this.height = 0;
		this.requestCount = 0;
		this.prodotti = [];
		this.prodottoSelezionato = null;
		this.prodottiCarrello = [];
		this.parametri = {};
		this.categoria = null;
		this.showPreferiti = false;
		this.itemsSelected = [];
		this.datiViaggio = new datiViaggio();
		this.searchedProducts = [];
	}
	public user: UserLoggedInfoModel | null;
	public appCulture: string;
	public width: number;
	public height: number;
	public version: string;
	public requestCount: number | null;
	public prodotti: associaDistributoriPacchettiExtended[] | null;
	public prodottoSelezionato: CarrelloInfoProdotto | null;
	public prodottiCarrello: RigaCarrelloInfo[] | null;
	public parametri: parametriPreventivatoreItem | {};
	public categoria: anagraficaPacchettiCategorie | null;
	public showPreferiti: boolean | null;
	public itemsSelected: number[] | null;
	public datiViaggio: datiViaggio | null;

	public searchedProducts: TextValueItem[];
	// public hasRole(role: string): boolean {
	// 	if (role == null || role.length == 0)
	// 		if (this.user == null || this.user.roles == null) return false;
	// 	return this.user.roles.find(x => x == role) != null;
	// }
	// public hasAnyRole(roles: string[]): boolean {
	// 	if (roles == null || roles.length == 0) return true;
	// 	if (this.user == null || this.user.roles == null) return false;
	// 	return (
	// 		this.user.roles.some(x => roles.find(r => (r = x)) != null) != null
	// 	);
	// }
	// public hasAllRole(roles: string[]): boolean {
	// 	if (roles == null || roles.length == 0)
	// 		if (this.user == null || this.user.roles == null) return false;
	// 	return (
	// 		this.user.roles.some(x => roles.find(r => (r = x)) != null) != null
	// 	);
	// }
}

const vuexLocal = new VuexPersistence({
	storage: window.sessionStorage
});

export default new Vuex.Store<any>({
	state: new Store(),
	getters: {
		// hasRole: state => (rule: string): boolean => {
		// 	return state.hasRole(rule);
		// },
		// hasAnyRole: state => (roles: string[]): boolean => {
		// 	return state.hasAnyRole(roles);
		// },
		// hasAllRole: state => (roles: string[]): boolean => {
		// 	return state.hasAllRole(roles);
		// },
		getRequestCount: state => {
			return state.requestCount;
		},
		// getIsAnyRequestPanding: state => {
		// 	return state.requestCount > 0;
		// },
		getAppCulture: state => {
			return state.appCulture;
		},
		getUser: state => {
			return state.user;
		},
		getIsAuthenticated: state => {
			return state.user != null;
		},

		appWidth: state => {
			return state.width;
		},
		getToken: state => {
			if (state.token) {
				return state.token;
			}

			return localStorage.getItem("overins-token");
		},

		getSearchedProduct: state => {
			if (state.searchedProducts && state.searchedProducts.length) 
				return state.searchedProducts;

			return null;
		},
		getCategoria: state => {
			if (state.categoria) {
				return state.categoria;
			}

			return '';
		}
	},
	mutations: {
		initialiseStore(state) {
			// Check if the store exists
			// if (localStorage.getItem("store")) {
			// 	let store = JSON.parse(localStorage.getItem("store"));
			if (sessionStorage.getItem("store")) {
				let store = JSON.parse(sessionStorage.getItem("store"));

				// Check the version stored against current. If different, don't
				// load the cached version
				if (store.version == VERSION) {
					this.replaceState(Object.assign(state, store));
				} else {
					state.version = VERSION;
				}
			}
		},
		clearDatiRicercaSelezionati(state:Store) { 
			state.prodottoSelezionato = null;
			state.prodottiCarrello = [];
			state.parametri = {};
			state.categoria = null;
			// state.showPreferiti = false;
			state.itemsSelected = [];
			state.datiViaggio = new datiViaggio();
		},
		setWindowSize(state, size: any) {
			state.width = window.innerWidth;
			state.height = window.innerHeight;
		},
		// setRequestCountIncrease(state) {
		// 	state.requestCount++;
		// },
		// setRequestCountDecrease(state) {
		// 	state.requestCount--;
		// },
		setUser(state, userInfo: UserLoggedInfoModel) {
			state.user = userInfo;
		},
		setAppCulture(state, culture: string) {
			state.appCulture = culture;
			eventHub.$emit(eventHub.events.CULTURE_CHANGE, culture);
		},
		setProdotti(state, prodotti: associaDistributoriPacchettiExtended[]) {
			state.prodotti = prodotti;
		},
		setProdottoPreferito(state, idProdotto: number) {
			var prod: associaDistributoriPacchettiExtended = state.prodotti.find(item => item.itemID == idProdotto);
			prod.preferito = !prod.preferito;
			if (!prod.preferito && (state.showPreferiti || state.categoria && state.categoria.descrizione == "preferiti")) this.commit("removeProdottoPreferito", prod);

			state.prodotti = state.prodotti.sort((a, b) => a.preferito && !b.preferito ? -1 : 1);
		},
		setProdottoSelezionato(state, prodotto: CarrelloInfoProdotto) {
			state.prodottoSelezionato = prodotto;
		},
		setProdottiCarrello(state, prodotti: RigaCarrelloInfo[]) {
			state.prodottiCarrello = prodotti;
		},
		addProdottoPreferito(state, prodotto: associaDistributoriPacchettiExtended) {
			var prod: associaDistributoriPacchettiExtended = state.prodotti.find(item => item.itemID == prodotto.itemID);
			if (!prod) {
				state.prodotti.unshift(prodotto);
			}
		},
		removeProdottoPreferito(state, prodotto: associaDistributoriPacchettiExtended) {
			var index = null;
			state.prodotti.every((item, i) => {
				if (item.itemID == prodotto.itemID && !state.itemsSelected.includes(item.pacchettoID) && prodotto.categoriaID != ((state.categoria && state.categoria.itemID) || -1)) {
					index = i;
					return false;
				} else return true;
			});

			if (index != null) state.prodotti.splice(index, 1);
		},
		setCategoria(state, categoria: anagraficaPacchettiCategorie) {
			state.categoria = categoria;
		},
		setShowPreferiti(state, showPreferiti: boolean) {
			state.showPreferiti = showPreferiti;
		},
		setItemsSelected(state, itemsSelected: number[]) {
			state.itemsSelected = itemsSelected;
		},
		setParametri(state, parametri: parametriPreventivatoreItem) {
			state.parametri = parametri;
		},
		setDatiViaggio(state, datiViaggio: datiViaggio) {
			state.datiViaggio = datiViaggio;
		},
		setToken(state, token: string) {
			state.token = token;
		},

		setProductSearched(state, items: TextValueItem[]) {
			state.searchedProducts = items;
		},
		resetProductSearched(state) {
			state.searchedProducts = null;
		}
	},
	actions: {
		loginUser(context, token) {
			localStorage.setItem("overins-token", token);
			context.commit("setToken", token);
			// eventHub.$emit(eventHub.events.USER_LOGIN);
			return context.dispatch("updateUserInfo");
		},
		logoutUser(context) {
			return api
				.doLogout()
				.then(res => {
					//    localStorage.clear();
					// localStorage.removeItem("overins-token");
					// sessionStorage.clear();

					// eventHub.$emit(eventHub.events.USER_LOGOUT);
					// localStorage.setItem("store", JSON.stringify(new Store()));
					// this.dispatch("initialiseStore");

					//this.replaceState(Object.assign(this.state, new Store()));

					// context.commit("setCurrentCustomer", null);
					return res;
				})
				.catch(error => {
					console.error(error);
				})
				.finally(() => {
					localStorage.removeItem("overins-token");
					// sessionStorage.clear();
					context.commit("setUser", null);

					eventHub.$emit(eventHub.events.USER_LOGOUT);
				});
		},
		updateUserInfo(context) {
			return api
				.getUserInfo()
				.then(data => {
					context.commit("setUser", data.userInfo);
					eventHub.$emit(eventHub.events.USER_LOGIN);
					return data;
				})
				.catch(error => {
					console.error(error);
				});
		},
		increseRequestCount(context) {
			return new Promise<void>((resolve, reject) => {
				context.commit("setRequestCountIncrease");
				resolve();
			});
		},
		decreaseRequestCount(context) {
			return new Promise<void>((resolve, reject) => {
				context.commit("setRequestCountDecrease");
				resolve();
			});
		}
	},
	plugins: [vuexLocal.plugin]
});
