import "normalize.css";

import "vue-multiselect/dist/vue-multiselect.min.css"
import(`@/assets/app_customization/${process.env.VUE_APP_CUSTOMIZATION_FOLDER}/css/app.scss`)

import "@progress/kendo-theme-bootstrap/dist/all.scss";

// import "./app-modal.scss";
import "./transitions/transitions.scss";
import(`@/assets/app_customization/${process.env.VUE_APP_CUSTOMIZATION_FOLDER}/css/app-custom.scss`)



