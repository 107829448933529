import { datiViaggio } from "@/models/carrello/datiViaggio";
import { parametriDichiarazioni } from "@/models/carrello/parametriDichiarazioni";
import api from "@/services/api";
import { Component } from "vue-property-decorator";
import { mapMutations, mapState } from "vuex";
import baseUserContextComponent from "./baseUserContextComponent";
import helpers from "@/helpers";

@Component({
	computed: {
		...mapState({
			datiViaggio: (state: any) => state.datiViaggio
		})
	},

	methods: {
		...mapMutations(['setDatiViaggio'])
	}
})


export default class dichiarazioniMobile extends baseUserContextComponent {

	adeguatezza: boolean = false;
	checkPolizza: boolean = false;
	checkPrivacy: boolean = false;

	datiViaggio!: datiViaggio;
	setDatiViaggio!: (setDatiViaggio: datiViaggio) => void;

	created() {
		this.initDichiarazioni();
	}

	initDichiarazioni(){
		if( this.datiViaggio && this.datiViaggio.parametriDichiarazione ){
			this.adeguatezza =  this.datiViaggio.parametriDichiarazione.adeguatezza;
			this.checkPolizza =  this.datiViaggio.parametriDichiarazione.checkPolizza;
			this.checkPrivacy =  this.datiViaggio.parametriDichiarazione.checkPrivacy;
		}
	}

	beforeDestroy() {
		this.$root.$off("updateDichiarazioni");
	}
	mounted() {
		var self = this;
		this.$root.$on("updateDichiarazioni", () => {
			self.initDichiarazioni();
		});
	}
	
	onChangeParametri(){
		var obj = {
			adeguatezza: this.adeguatezza,
			checkPolizza: this.checkPolizza,
			checkPrivacy: this.checkPrivacy
		}
		this.datiViaggio.parametriDichiarazione = obj;
		this.setDatiViaggio(this.datiViaggio);
	}

	async getTerminiECondizioni() {
		var self = this;

		
		helpers.getTerminiECondizioni(this,this.datiViaggio.pacchettoID, null);

		/*api.getWithAllDataResponse(`${api.endpoint.CARRELLO.TERMINI_E_CONDIZIONI}?pacchettoId=${this.datiViaggio.pacchettoID}`, { responseType: "blob" })
		.then(res => {
			const blob = new Blob([res.data], { type: res.headers['content-type'] });

			if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
                (window.navigator as any).msSaveOrOpenBlob(blob);
            } else {
                var fileURL = URL.createObjectURL(blob);
                window.open(fileURL, '_blank');
            }
		}).catch(err => {
			self.$bvToast.toast(self.$i18n.t("errore.fileNotFound").toString(), {
				variant: "danger",
				title: self.$i18n.t("generico.errore").toString(),
				solid: true
			});
		});*/
	}
}