// import Vue from 'vue'
import baseContextComponent from "@/components/baseContextComponent";
import { associaDistributoriPacchettiExtended, schedaProdotto } from "@/models/carrello/prodotti";
import { Component, Prop } from "vue-property-decorator";
import { FileInvioOnLine } from '../models/reportFornitori/FileInvioOnLine';

@Component({})
export default class checkParametri extends baseContextComponent {
	@Prop({ default: () => null }) parametro: schedaProdotto;
	@Prop({ default: () => null }) prodotto: associaDistributoriPacchettiExtended;

	created() {}

	mounted() {}

	get rightFieldType() {
		if (this.prodottoParametro && (this.prodottoParametro as any).fieldType == -1) return (this.prodottoParametro as any).fieldType;

		return (this.parametro as any).fieldType;
	}

	get prodottoParametro() {
		if (this.parametro == null || this.prodotto == null || this.prodotto.parametri == null || this.prodotto.parametri.length == 0) {
			return null;
		}

		var p = this.prodotto.parametri.find(x => x.categoria == this.parametro.categoria && x.nomeParametro == this.parametro.nomeParametro);
		return p;
	}

	get checkedTipo1() {
		var result = false;
		if (this.prodottoParametro) {
			result = this.prodottoParametro.valore == "1";
		}
		return result;
	}

	get checkedTipo2() {
		var result = "";
		var parametro = this.prodottoParametro;
		if (parametro && parametro.valore) {
			if (parseInt(parametro.valore) > 0) {
				result = "€ " + parametro.valore;
			} else if (parametro.valore == "-1") {
				result = "Illimitato";
			}
		}
		return result;
	}

	get checkedTipo3() {
		var result = "";
		var parametro = this.prodottoParametro;
		if (parametro && parametro.valore) {
			result = parametro.valoreTesto;
		}
		return result;
	}

	get checkedTipo4() {
		var result = "";
		var parametro = this.prodottoParametro;
		if (parametro && parametro.valore) {
			return parametro.valore || parametro.valore == "0";
			// switch (parametro.valore) {
			// 	case "1":
			// 		result = "Entro 70";
			// 	case "2":
			// 		result = "Entro 70 e 75";
			// 	case "3":
			// 		result = "Entro 75 e 90";
			// 	case "4":
			// 		result = "Oltre 90";
			// }
		}
		return result;
	}

	get checkedTipo5() {
		var result = "";
		var parametro = this.prodottoParametro;
		if (parametro && parametro.valore) {
			return parametro.valore || parametro.valore == "0";
			// switch (parametro.valore) {
			// 	case "1":
			// 		result = "Individiale";
			// 	case "2":
			// 		result = "Gruppo";
			// }
		}
		return result;
	}

	get checkedTipo6() {
		var result = "";
		
		var parametro = this.prodottoParametro;
		if (parametro && parametro.valore) {
			return parametro.valoreTesto;
		}
		return result;
	}

	get checkedTipo7() {
		var result = "";
		var parametro = this.prodottoParametro;
		if (parametro && parametro.valore) {
			return parametro.valoreTesto;
		}
		return result;
	}

	get checkedTipoText() {
		var result = "";
		var parametro = this.prodottoParametro;
		if (parametro && parametro.valore) {
			return parametro.valoreTesto;
		}
		return result;
	}
}
