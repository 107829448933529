import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { mapActions, mapGetters, mapMutations } from "vuex";
import eventHub from "./eventHub";
import axios, { AxiosPromise } from "axios";
// import { ResponseMessage, MessageType } from "./models/messageModels";
// import splashScreen from "./components/splashScreen.vue";
// import moment from "moment";
import { MessageType, responseMessage } from "./models/messageModels";
import baseUserContextComponent from "./components/baseUserContextComponent";
import { UserLoggedInfoModel, UserMeInfo } from "./models/userModels";
import api from "./services/api";
import { loadLanguageAsync } from "./i18n/i18n";
import helpers from "./helpers";
//import moment from "moment";
import moment from 'moment-timezone'

@Component({
	methods: {
		// ...mapMutations(["setWindowSize", "setUser", "setRequestCountIncrease", "setRequestCountDecrease"]),
		...mapMutations(["setWindowSize", "setUser"]),
		...mapActions(["updateUserInfo", "logoutUser"])
	}
})
export default class App extends baseUserContextComponent {
	@Watch('variant')
	onVariantChange(newValue: string, oldValue: string) {
		document.body.classList.remove(`theme-${oldValue}`);
		document.body.classList.add(`theme-${newValue}`);
	}
	requestCount: number = 0;
	forcedLoading: boolean = false;
	appBootstraped: boolean = false;
	appMounted: boolean = false;
	get appLoaded(): boolean {
		return this.appBootstraped && this.appMounted;
	}
	setWindowSize!: (size: any | null) => void;
	setUser!: (user: UserLoggedInfoModel | null) => void;
	// setRequestCountIncrease: () => void;
	// setRequestCountDecrease: () => void;
	updateUserInfo!: () => Promise<UserMeInfo>;
	logoutUser!: () => Promise<boolean>;
	onAxiosResponseError(error: any) {
		var self = this;

		if (error.response == null) {
			self.$bvToast.toast(error.message, {
				variant: "danger",
				title: self.$i18n.t('msg.erroreGenerico').toString(),
				solid: true
			});
		} else if (error.response.status == 401) {
			self.logoutUser();
			var allowanonymous = self.$route.meta.allowanonymous;
			if (!allowanonymous)
				self.$router.push({ name: "login" });
		} else if (error.response.status == 403) {
			// self.logoutUser();
			// self.$router.push({ name: "login" });
			self.$bvToast.toast('NO PERMISSIONS', {
				variant: "danger",
				title: 'Request ERROR',
				solid: true
			});
		} else if (error.response.status == 400) {
			if (
				error.response.data != null &&
				error.response.data.messages != null &&
				Array.isArray(error.response.data.messages)
			) {
				(error.response.data.messages as Array<
					responseMessage
				>).forEach(x => {
					self.$bvToast.toast(self.$i18n.t(x.text).toString(), {
						variant:
							x.type == null || x.type == MessageType.error
								? "danger"
								: x.type == MessageType.warning
									? "warning"
									: "info",
						title: self.$i18n.t(x.code).toString(),
						solid: true
					});
				});
			} else {
				self.$bvToast.toast(
					error.response.data && error.response.data.text
						? self.$i18n.t(error.response.data.text).toString()
						: error.message,
					{
						variant: "danger",
						title: "",
						solid: true
					}
				);
			}
		} else if (error.response.status == 500) {
			self.$bvToast.toast(error.message, {
				variant: "danger",
				title: self.$i18n.t('msg.erroreGenerico').toString(),
				solid: true
			});
		}
	}
	onResize(event) {
		//console.log("window has been resized", event);
		this.setWindowSize({
			width: window.innerWidth,
			height: window.innerHeight
		});
	}
	beforeDestroy() {
		// Unregister the event listener before destroying this Vue self
		window.removeEventListener("resize", this.onResize);
		
		this.$root.$off("setForcedLoading");
	}
	beforeMount() {
		var self = this;
	}
	mounted() {
		var self = this;

		this.$root.$on("setForcedLoading", show => {
			self.forcedLoading = show || false;
		});

		window.addEventListener("resize", self.onResize);
		self.$nextTick(function () {
			// Code that will run only after the
			// entire view has been rendered
			self.onResize(null);
			self.appMounted = true;
		});
	}
	beforeCreate() { }
	created() {
		var self = this;

		Date.prototype.toJSON = function () { 
			var m = moment(this);
			//var destinationTimeZone = moment.tz.guess();
			var destinationTimeZone = "Europe/Rome";
			var newDate = m.tz(destinationTimeZone);
			return newDate.format()
		}


		Date.prototype.toJSON = function () { 
			var m = moment(this);
			return m.format("YYYY-MM-DD")
		}

		
		self.bootstrap().finally(() => {
			eventHub.$on(
				eventHub.events.AXIOS_RESPONSE_ERROR,
				self.onAxiosResponseError
			);
			eventHub.$on(
				eventHub.events.AXIOS_REQUEST_STARTED,
				() => self.requestCount++);
			eventHub.$on(
				eventHub.events.AXIOS_REQUEST_ENDED,
				() => self.requestCount--);

			// self.bootstrapRouteCheck();
			self.appBootstraped = true;
		});


		// await helpers.getPopupCarrello();
	}

	get layout(): string {
		if (
			this.$route.meta.layout &&
			this.$route.meta.layout in this.$options.components
		)
			return this.$route.meta.layout;
		return "default-layout";
	}
	get layoutkey(): string {
		var key = "nameless";
		if (
			this.$route.meta.layout
		)
			key = this.$route.meta.layout;
		return key == "empty-layout" ? this.$route.name || key : key;
	}
	bootstrap(): Promise<boolean> {
		var self = this;
		return new Promise(function (resolve, reject) {
			self.updateUserInfo()
				.then(data => {
					self.bootstrapUserSettings().finally(() => { 
						self.bootstrapRoute().finally(() => resolve(true)); 
						var currentRoute = self.$router.currentRoute.name;

						if (data.redirectTo && data.redirectTo.length > 0 && currentRoute != "ritorno-fm-ok") {
							self.$router.push({ name: data.redirectTo});
						}
					});
				})
				.catch(() => {
					self.logoutUser().finally(() => {
						self.bootstrapUserSettings().finally(() => { 
							self.bootstrapRoute().finally(() => resolve(true)); 
						});
					});
				});
		});
	}
	bootstrapRoute(): Promise<boolean> {
		var self = this;
		self.$router.beforeEach((to, from, next) => {
			
			if (to.name != 'login') {
				let isAuthenticated = self.getIsAuthenticated;
				let allowanonymous = (to.meta || {}).allowanonymous || false;
				let permissions = (to.meta || {}).permissions || null;
				let attributiUtente = (to.meta || {}).attributiUtente || null;
				if (allowanonymous ||
					(isAuthenticated && self.hasPermessi(permissions) && self.hasAttributiUtente(attributiUtente))
				) {
					next();
				} else {

					let returnUrl = to.fullPath;
					if (isAuthenticated == false && returnUrl != null && returnUrl != '' && returnUrl != '/') {
						self.logoutUser();
						next({ name: "login", query: { returnUrl: returnUrl } });
					}
					else {
						self.logoutUser();
						next({ name: "login" });
					}
				}
			}
			else if (to.name != from.name)
				next();
		});
		// if (self.$route.name != 'login') {
		return new Promise(function (resolve, reject) {
			let isAuthenticated = self.getIsAuthenticated;
			let allowanonymous = (self.$route.meta || {}).allowanonymous || false;
			let permissions = (self.$route.meta || {}).permissions || null;
			let attributiUtente = (self.$route.meta || {}).attributiUtente || null;
			if (!(allowanonymous
				||
				(isAuthenticated && self.hasPermessi(permissions) && self.hasAttributiUtente(attributiUtente)))) {

				let returnUrl = self.$route.fullPath;
				if (returnUrl != null && returnUrl != '' && returnUrl != '/') {
					self.logoutUser();
					self.$router.replace({ name: "login", query: { returnUrl: returnUrl } }).finally(() => resolve(true));
				}
				else {
					self.logoutUser();
					self.$router.replace({ name: "login" }).finally(() => resolve(true));
				}
			}
			else {
				resolve(true)
			}

		});

	}
	bootstrapUserSettings(): Promise<string> {
		var self = this;
		var currentCulture = self.getAppCulture;
		kendo.culture(currentCulture);
		return loadLanguageAsync(currentCulture);
	}
}
