"use strict";

function _typeof(obj) {
	"@babel/helpers - typeof";
	if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
		_typeof = function (_typeof2) {
			function _typeof(_x) {
				return _typeof2.apply(this, arguments);
			}
			_typeof.toString = function () {
				return _typeof2.toString();
			};
			return _typeof;
		}(function (obj) {
			return typeof obj;
		});
	} else {
		_typeof = function (_typeof3) {
			function _typeof(_x2) {
				return _typeof3.apply(this, arguments);
			}
			_typeof.toString = function () {
				return _typeof3.toString();
			};
			return _typeof;
		}(function (obj) {
			return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
		});
	}
	return _typeof(obj);
}

var __assign = undefined && undefined.__assign || function () {
	__assign = Object.assign || function (t) {
		for (var s, i = 1, n = arguments.length; i < n; i++) {
			s = arguments[i];

			for (var p in s) {
				if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
			}
		}

		return t;
	};

	return __assign.apply(this, arguments);
};

var __rest = undefined && undefined.__rest || function (s, e) {
	var t = {};

	for (var p in s) {
		if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
	}

	if (s != null && typeof Object.getOwnPropertySymbols === "function")
		for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
			if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
		}
	return t;
};

var __spreadArrays = undefined && undefined.__spreadArrays || function () {
	for (var s = 0, i = 0, il = arguments.length; i < il; i++) {
		s += arguments[i].length;
	}

	for (var r = Array(s), k = 0, i = 0; i < il; i++) {
		for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++) {
			r[k] = a[j];
		}
	}

	return r;
};

Object.defineProperty(exports, "__esModule", {
	value: true
});
exports.Grid = void 0; // @ts-ignore

var Vue = require("vue");

var allVue = Vue;
var gh = allVue.h;
var ref = allVue.ref;
var markRaw = allVue.markRaw;

var kendo_vue_common_1 = require("@progress/kendo-vue-common");

var kendo_vue_data_tools_1 = require("@progress/kendo-vue-data-tools");

var GridSelectionCell_1 = require("./cells/GridSelectionCell");

var GridHierarchyCell_1 = require("./cells/GridHierarchyCell");

var GridEditCell_1 = require("./cells/GridEditCell");

var Header_1 = require("./header/Header");

var HeaderRow_1 = require("./header/HeaderRow");

var FilterRow_1 = require("./header/FilterRow");

var GroupPanel_1 = require("./header/GroupPanel");

var Footer_1 = require("./footer/Footer");

var FooterRow_1 = require("./footer/FooterRow");

var Pager_1 = require("./paging/Pager");

var VirtualScroll_1 = require("./VirtualScroll");

var ColumnResize_1 = require("./drag/ColumnResize");

var CommonDragLogic_1 = require("./drag/CommonDragLogic");

var DragClue_1 = require("./drag/DragClue");

var DropClue_1 = require("./drag/DropClue");

var index_1 = require("./utils/index"); // import { GridCellProps } from './interfaces/GridCellProps';


var GridCell_1 = require("./cells/GridCell"); // import { GridToolbar } from './GridToolbar';


var GridGroupCell_1 = require("./cells/GridGroupCell");

var GridRow_1 = require("./rows/GridRow");

var GridHeaderSelectionCell_1 = require("./header/GridHeaderSelectionCell"); // import { ScrollMode } from './ScrollMode';


var GridNoRecords_1 = require("./GridNoRecords");
/**
 * Represents the default `Grid` component.
 */


var Grid = {
	name: 'KendoGrid',
	props: {
		alternatePerGroup: Boolean,
		columns: Array,
		columnVirtualization: Boolean,
		dataItems: [Array, Object],
		sortable: [Boolean, Object],
		sort: Array,
		filterable: Boolean,
		filterCellRender: [String, Function, Object],
		headerCellRender: [String, Function, Object],
		filter: Object,
		pageable: [Boolean, Object],
		pageSize: Number,
		total: Number,
		skip: Number,
		take: Number,
		expandField: String,
		selectedField: String,
		cellRender: [String, Function, Object],
		rowRender: [String, Function, Object],
		resizable: Boolean,
		reorderable: Boolean,
		group: Array,
		groupable: [Boolean, Object],
		editField: String,
		scrollable: {
			type: String,
			default: 'scrollable'
		},
		rowHeight: Number,
		detail: [String, Function, Object],
		columnMenu: [Boolean, String, Function, Object],
		removeTranslateY: {
			type: Boolean,
			default: true
		},
	},
	watch: {
		skip: function skip(value, oldValue) {
			// @ts-ignore
			this.onSkipChanged(value, oldValue);
		},
		total: function total(value, oldValue) {
			// @ts-ignore
			this.onTotalChanged(value, oldValue);
		},
		rowHeight: function rowHeight(value, oldValue) {
			// @ts-ignore
			this.onRowHeightChanged(value, oldValue);
		}
	},
	created: function created() {
		this.initialHeight = null;
		this._columns = [];
		var groupable = this.$props.groupable === true || _typeof(this.$props.groupable) === 'object' && this.$props.groupable.enabled !== false;
		this.vs = new VirtualScroll_1.VirtualScroll(groupable || this.$props.rowHeight === undefined || this.$props.rowHeight === 0);
		// aggiunto per problemi con k-dilog nelle sottogriglie: la position fixed diventa relativa all'elemento con style='transform: translate...'
		this.vs.removeTranslateY = this.removeTranslateY;
		this.dragLogic = new CommonDragLogic_1.CommonDragLogic(this.columnReorder.bind(this), this.groupReorder.bind(this), this.columnToGroup.bind(this));
		this.columnResize = new ColumnResize_1.ColumnResize(this.onResize.bind(this));
		this._columnsMap = [
			[]
		];
		this._header = null;
		this._footer = null;
		this.forceUpdateTimeout = undefined;
	},
	mounted: function mounted() {
		// @ts-ignore
		this.initialHeight = this.$el.style ? this.$el.style.height : null;
		this.vs.removeTranslateY = this.removeTranslateY;
		this.setRefs();
	},
	updated: function updated() {
		this.setRefs();
	},
	destroyed: !!gh ? undefined : function () {
		this.gridUnmounted();
	},
	// @ts-ignore
	unmounted: function unmounted() {
		this.gridUnmounted();
	},
	computed: {
		getCorrectHeight: {
			get: function get() {
				if (this.$props.scrollable === 'virtual') {
					return this.initialHeight || '450px';
				} else {
					return null;
				}
			}
		}
	},
	methods: {
		/**
		 * A getter of the current columns. Gets the current column width or current columns,
		 * or any other [`GridColumnProps`]({% slug api_grid_native_columnprops %}) for each defined column.
		 * Can be used on each Grid instance. To obtain the instance of the rendered Grid, use the `ref`
		 * callback. The following example demonstrates how to reorder the columns by dragging their handlers
		 * and check the properties afterwards. You can check the result in the browser console.
		 */
		getColumns: function getColumns() {
			var shuffledColumns = this._columns.filter(function (q) {
				return q.declarationIndex >= 0 && q.parentIndex === -1;
			});

			var sanitize = function sanitize(columns) {
				columns.sort(function (a, b) {
					return a.declarationIndex - b.declarationIndex;
				});
				return columns.map(function (column) {
					var declarationIndex = column.declarationIndex,
						parentIndex = column.parentIndex,
						depth = column.depth,
						colSpan = column.colSpan,
						rowSpan = column.rowSpan,
						index = column.index,
						kFirst = column.kFirst,
						children = column.children,
						props = __rest(column, ["declarationIndex", "parentIndex", "depth", "colSpan", "rowSpan", "index", "kFirst", "children"]);

					return children.length ? __assign({
						children: sanitize(children)
					}, props) : props;
				});
			};

			return sanitize(shuffledColumns);
		},
		setRefs: function setRefs() {
			var groupPanelDiv = this.v3 ? this.groupPanelDivRef : this.$refs.groupPanelDiv;

			if (groupPanelDiv) {
				var elementString = '$el';
				var groupPanelDivElement = groupPanelDiv[elementString] || null;
				this.dragLogic.refGroupPanelDiv(groupPanelDivElement);
			}

			var dropElementClue = this.v3 ? this.dropElementClueRef : this.$refs.dropElementClue;
			var dragElementClue = this.v3 ? this.dragElementClueRef : this.$refs.dragElementClue;
			this.dragLogic.refDropElementClue(dropElementClue);
			this.dragLogic.refDragElementClue(dragElementClue);
			this.columnResize.colGroupMain = this.v3 ? this.colGroupRef : this.$refs.colGroup;
			this._header = this.v3 ? this.headerRef : this.$refs.header;
			this._footer = this.v3 ? this.footerRef : this.$refs.footer;
			this.vs.container = this.v3 ? this.scrollContainerRef : this.$refs.scrollContainer;
			this.vs.table = this.v3 ? this.scrollTableRef : this.$refs.scrollTable;
			this.resetTableWidth();
			this.vs.tableBody = this.v3 ? this.scrollTableBodyRef : this.$refs.scrollTableBody;
		},
		gridUnmounted: function gridUnmounted() {
			clearTimeout(this.forceUpdateTimeout);
			this.columnResize.columns = [];
			this.dragLogic.columns = [];

			if (this.dragLogic && this.dragLogic.dragElementClue) {
				this.dragLogic.dragElementClue.$el.remove();
				this.dragLogic.dropElementClue.$el.remove();
			}

			this._columns = [];
		},
		resetVirtual: function resetVirtual() {
			this.vs.PageChange = this.pageChangeHandler;
			this.vs.realSkip = this.$props.skip || 0;
			this.vs.pageSize = (this.$props.take !== undefined ? this.$props.take : this.$props.pageSize) || 0;
			this.vs.scrollableVirtual = this.$props.scrollable === 'virtual';
			this.vs.propsSkip = (this.$props.skip || 0) + (this.$props.scrollable === 'virtual' ? this.vs.topCacheCount + (this.vs.attendedSkip - (this.$props.skip || 0)) : 0);
		},
		onSkipChanged: function onSkipChanged(value, _oldValue) {
			if (Math.max(0, this.vs.attendedSkip) !== value && value !== undefined) {
				this.vs.attendedSkip = value;
				this.vs.propsSkip = (value || 0) + (this.$props.scrollable === 'virtual' ? this.vs.topCacheCount + (this.vs.attendedSkip - (value || 0)) : 0);
				this.vs.syncScroll();
			}
		},
		onTotalChanged: function onTotalChanged(_value, _oldValue) {
			var groupable = this.$props.groupable === true || _typeof(this.$props.groupable) === 'object' && this.$props.groupable.enabled !== false;
			this.vs.reset();
			this.vs = new VirtualScroll_1.VirtualScroll(groupable || this.$props.rowHeight === undefined || this.$props.rowHeight === 0);
			this.resetVirtual();
			this.setRefs();
		},
		onRowHeightChanged: function onRowHeightChanged(_value, _oldValue) {
			var groupable = this.$props.groupable === true || _typeof(this.$props.groupable) === 'object' && this.$props.groupable.enabled !== false;
			this.vs.reset();
			this.vs = new VirtualScroll_1.VirtualScroll(groupable || this.$props.rowHeight === undefined || this.$props.rowHeight === 0);
			this.resetVirtual();
			this.setRefs();
		},
		scrollHandler: function scrollHandler(event) {
			var _this = this;

			clearTimeout(this.forceUpdateTimeout);

			if (this.$props.columnVirtualization && !this.vs.scrollableVirtual) {
				this.forceUpdateTimeout = setTimeout(function () {
					_this.$forceUpdate();
				}, 0);
			}

			if (this._header) {
				this._header.setScrollLeft(event.currentTarget.scrollLeft);
			}

			if (this._footer) {
				this._footer.setScrollLeft(event.currentTarget.scrollLeft);
			}

			if (this.vs) {
				this.vs.scrollHandler(event);
			}
		},
		rowClick: function rowClick(e, item) {
			if (e.target.type !== 'checkbox') {
				this.$emit('rowclick', __assign({
					dataItem: item.dataItem
				}, this.getArguments(e)));
			}
		},
		rowDoubleClick: function rowDoubleClick(e, item) {
			if (e.target.type !== 'checkbox') {
				this.$emit('rowdblclick', __assign({
					dataItem: item.dataItem
				}, this.getArguments(e)));
			}
		},
		itemChange: function itemChange(event) {
			var itemChange = kendo_vue_common_1.hasListener.call(this, 'itemchange');

			if (event.field === this.$props.expandField || (this.$props.group || this.$props.detail) && event.field === undefined) {
				var expandChange = kendo_vue_common_1.hasListener.call(this, 'expandchange');

				if (expandChange) {
					this.$emit('expandchange', __assign(__assign({}, this.getArguments(event.event)), {
						dataItem: event.dataItem,
						value: event.value
					}));
				}

				return;
			}

			if (itemChange) {
				this.$emit('itemchange', __assign(__assign({}, this.getArguments(event.event)), {
					dataItem: event.dataItem,
					field: event.field,
					value: event.value
				}));
			}
		},
		cellClickHandler: function cellClickHandler(event) {
			this.$emit('cellclick', {
				dataItem: event.dataItem,
				field: event.field
			});
		},
		cellKeydownHandler: function cellKeydownHandler(event) {
			this.$emit('cellkeydown', event);
		},
		editHandler: function editHandler(dataItem) {
			this.$emit('edit', {
				dataItem: dataItem
			});
		},
		removeHandler: function removeHandler(dataItem) {
			this.$emit('remove', {
				dataItem: dataItem
			});
		},
		saveHandler: function saveHandler(dataItem) {
			this.$emit('save', {
				dataItem: dataItem
			});
		},
		cancelHandler: function cancelHandler(dataItem) {
			this.$emit('cancel', {
				dataItem: dataItem
			});
		},
		selectionChangeHandler: function selectionChangeHandler(event) {
			this.$emit('selectionchange', {
				event: event.event,
				dataItem: event.dataItem
			});
		},
		onHeaderSelectionChangeHandler: function onHeaderSelectionChangeHandler(event) {
			this.$emit('headerselectionchange', {
				field: event.field,
				event: event.event,
				target: this
			});
		},
		pageChangeHandler: function pageChangeHandler(page, event) {
			this.raiseDataEvent('pagechange', {
				page: page
			}, {
				skip: page.skip,
				take: page.take
			}, event);
		},
		sortChangeHandler: function sortChangeHandler(sort, event) {
			this.raiseDataEvent('sortchange', {
				sort: sort
			}, {
				sort: sort
			}, event);
		},
		filterChangeHandler: function filterChangeHandler(filter, event) {
			this.raiseDataEvent('filterchange', {
				filter: filter
			}, {
				filter: filter,
				skip: 0
			}, event);
		},
		groupChangeHandler: function groupChangeHandler(groups, event) {
			this.raiseDataEvent('groupchange', {
				group: groups
			}, {
				group: groups,
				skip: 0
			}, event);
		},
		raiseDataEvent: function raiseDataEvent(handler, data, moreData, event) {
			if (kendo_vue_common_1.hasListener.call(this, handler)) {
				this.$emit(handler, __assign(__assign({}, this.getArguments(event)), data));
			} else if (kendo_vue_common_1.hasListener.call(this, 'datastatechange')) {
				this.$emit('datastatechange', __assign(__assign({}, this.getArguments(event)), {
					data: __assign(__assign({}, this.getDataState()), moreData)
				}));
			}
		},
		columnReorder: function columnReorder(prev, next, event) {
			var _a;

			var _this = this;

			var depth = this._columns[prev].depth;

			var end = function end(index) {
				do {
					index++;
				} while (index < _this._columns.length && _this._columns[index].depth > depth);

				return index;
			};

			var spliced = this._columns.splice(prev, end(prev) - prev);

			(_a = this._columns).splice.apply(_a, __spreadArrays([prev < next ? end(next - spliced.length) : next, 0], spliced));

			this._columns.filter(function (q) {
				return q.declarationIndex >= 0;
			}).forEach(function (c, i) {
				return c.orderIndex = i;
			});

			var eventColumnProps = this.getColumns();
			this.$emit('columnreorder', {
				target: this,
				columns: eventColumnProps,
				event: event
			});
		},
		groupReorder: function groupReorder(prevIndex, nextIndex, event) {
			if (this.$props.group === undefined) {
				return;
			}

			var group = this.$props.group.slice();
			group.splice.apply(group, __spreadArrays([nextIndex, 0], group.splice(prevIndex, 1)));
			this.groupChangeHandler(group, event);
		},
		columnToGroup: function columnToGroup(columnIndex, groupIndex, event) {
			var field = this._columns[columnIndex].field;

			if (!field) {
				return;
			}

			var group = (this.$props.group || []).slice();
			group.splice(groupIndex, 0, {
				field: field
			});
			this.groupChangeHandler(group, event);
		},
		resetTableWidth: function resetTableWidth() {
			var totalWidth = 0;

			if (!this.columnResize.colGroupMain) {
				return;
			}

			var colElements = this.columnResize.colGroupMain.children;

			var pxOrPerc = false;
			if (this.columnResize && this.columnResize.columns && this.columnResize.columns.length) {
				if (this.columnResize.columns[0] && this.columnResize.columns[0].width && isNaN(this.columnResize.columns[0].width) && this.columnResize.columns[0].width.indexOf("%") >= 0) {
					pxOrPerc = true;
				}
			}

			for (var i = 0; i < colElements.length; i++) {
				var width = colElements[i].width;

				if (!width) {
					return;
				}

				totalWidth += parseFloat(width.toString());
			}

			totalWidth = Math.round(totalWidth);

			if (this._header) {
				this._header.setWidth(totalWidth, pxOrPerc);
			}

			if (this._footer) {
				this._footer.setWidth(totalWidth);
			}

			if (this.vs.table) {
				this.vs.table.style.width = totalWidth + (!pxOrPerc ? 'px': '%');
			}
		},
		onResize: function onResize(index, newWidth, oldWidth, event, end) {
			this.resetTableWidth();
			this.$emit('columnresize', {
				columns: this.getColumns(),
				index: index,
				event: event,
				newWidth: newWidth,
				oldWidth: oldWidth,
				end: end,
				target: this
			});
		},
		initColumns: function initColumns(columnElements, groupCount) {
			var _this = this;

			this._columns = index_1.readColumns(columnElements, this.getColumns());

			if (this._columns.length === 0) {
				var currentColumns = index_1.autoGenerateColumns(this.$props.dataItems, this.$props.group, this.$props.expandField);
				this._columns = currentColumns;
			}

			if (this.$props.selectedField) {
				var that_1 = this;

				this._columns.filter(function (c) {
					return c.field === _this.$props.selectedField;
				}).forEach(function (c) {
					c.width = c.width || '50px';
					c.internalCell = that_1.v3 ? markRaw(GridSelectionCell_1.GridSelectionCell) : GridSelectionCell_1.GridSelectionCell;
					c.internalHeaderCell = that_1.v3 ? markRaw(GridHeaderSelectionCell_1.GridHeaderSelectionCell) : GridHeaderSelectionCell_1.GridHeaderSelectionCell;
				});
			}

			var defaultServiceProps = {
				resizable: true,
				width: '32px',
				title: ' ',
				declarationIndex: -1,
				orderIndex: -1,
				children: [],
				parentIndex: -1,
				depth: 0,
				colSpan: 0,
				rowSpan: 0,
				left: 0,
				right: 0,
				// position: 'sticky',
				index: 0,
				rightBorder: false
			};
			var columnIndexOffset = 0;

			if (this.$props.expandField && kendo_vue_common_1.hasListener.call(this, 'expandchange') && this.$props.detail) {
				this._columns.unshift(__assign(__assign({}, defaultServiceProps), {
					internalCell: this.v3 ? markRaw(GridHierarchyCell_1.GridHierarchyCell) : GridHierarchyCell_1.GridHierarchyCell,
					field: this.$props.expandField,
					headerClassName: 'k-hierarchy-cell k-header'
				}));

				columnIndexOffset++;
			}

			for (var i = 0; i < groupCount; i++) {

				this._columns.unshift(__assign(__assign({}, defaultServiceProps), {
					internalCell: this.v3 ? markRaw(GridGroupCell_1.GridGroupCell) : GridGroupCell_1.GridGroupCell,
					field: 'value',
				}));

				columnIndexOffset++;
			}

			this._columns.slice(columnIndexOffset).forEach(function (c) {
				return c.parentIndex >= 0 && (c.parentIndex += columnIndexOffset);
			});

			this._columnsMap = index_1.mapColumns(this._columns);
			this.columnResize.columns = this._columns;
			this.dragLogic.columns = this._columns;
		},
		resolveTitle: function resolveTitle(field) {
			// @ts-ignore
			var column = this.$props.columns.find(function (c) {
				return c.field === field;
			});
			var title = column && (column.title || column.field);
			return title === undefined ? field : title;
		},
		// menca fix per grouping
		getFieldTypeForGroupCell: function getFieldTypeForGroupCell(field) {
			// @ts-ignore
			var column = this.$props.columns.find(function (c) {
				return c.field === field;
			});
			var type = column && (column.type || column.type);
			return type === undefined ? "string" : type;
		},
		// menca fix per grouping
		getFieldValueForGroupCell: function getFieldValueForGroupCell(field) {
			// @ts-ignore
			var column = this.$props.columns.find(function (c) {
				return c.field === field;
			});
			var values = column && (column.values || column.values);
			return values === undefined ? null : values;
		},
		getDataState: function getDataState() {
			return {
				filter: this.$props.filter,
				sort: this.$props.sort,
				skip: this.$props.skip,
				take: this.$props.take !== undefined ? this.$props.take : this.$props.pageSize,
				group: this.$props.group
			};
		},
		getArguments: function getArguments(event) {
			return {
				event: event,
				target: this
			};
		}
	},
	// @ts-ignore
	setup: !gh ? undefined : function () {

		var v3 = !!gh;
		var groupPanelDivRef = ref(null);
		var dropElementClueRef = ref(null);
		var dragElementClueRef = ref(null);
		var headerRef = ref(null);
		var footerRef = ref(null);
		var colGroupRef = ref(null);
		var scrollContainerRef = ref(null);
		var scrollTableRef = ref(null);
		var scrollTableBodyRef = ref(null);
		return {
			v3: v3,
			groupPanelDivRef: groupPanelDivRef,
			dropElementClueRef: dropElementClueRef,
			dragElementClueRef: dragElementClueRef,
			headerRef: headerRef,
			footerRef: footerRef,
			colGroupRef: colGroupRef,
			scrollContainerRef: scrollContainerRef,
			scrollTableRef: scrollTableRef,
			scrollTableBodyRef: scrollTableBodyRef
		};
	},
	// @ts-ignore
	render: function render(createElement) {
		var _this = this;

		var h = gh || createElement;
		var defaultSlot = kendo_vue_common_1.getDefaultSlots(this);
		var total = this.$props.total || 0;
		var gridData = [];

		if (Array.isArray(this.$props.dataItems)) {
			gridData = this.$props.dataItems;
		} else if (this.$props.dataItems) {
			gridData = this.$props.dataItems.data;
			total = total || this.$props.dataItems.total;
		}

		var groupable = this.$props.groupable === true || _typeof(this.$props.groupable) === 'object' && this.$props.groupable.enabled !== false;
		this.columnResize.resizable = this.$props.resizable || false;
		this.dragLogic.reorderable = this.$props.reorderable || false;
		this.dragLogic.groupable = groupable;
		this.resetVirtual();
		this.vs.total = total;

		if (this.$props.rowHeight !== undefined && this.$props.rowHeight > 0 && !groupable) {
			this.vs.containerHeight = Math.min(1533915, this.$props.rowHeight * (total || 0));
		} else {
			this.vs.containerHeight = 1533915;
		}

		var children = defaultSlot || [];
		var groupingFooter = _typeof(this.$props.groupable) === 'object' && this.$props.groupable.footer || 'none';
		var data = [];
		var resolvedGroupsCount = index_1.flatData(data, gridData, groupingFooter, {
			index: this.$props.skip || 0
		}, this.$props.group !== undefined, this.$props.expandField);
		var notHiddenColumns = this.$props.columns ? this.$props.columns.filter(function (column) {
			return !column.hidden;
		}) : this.$props.columns;
		this.initColumns(notHiddenColumns, resolvedGroupsCount);
		var toolbar = children.filter(function (child) {
			return child && child.tag && child.tag.toLowerCase().indexOf('toolbar') !== -1 || child.componentOptions && child.componentOptions.tag && child.componentOptions.tag.toLowerCase().indexOf('toolbar') !== -1 || child.type && child.type.name && child.type.name.toLowerCase().indexOf('toolbar') !== -1;
		});
		var noRecords = children.filter(function (child) {
			return child && child.tag && child.tag.toLowerCase().indexOf('records') !== -1 || child.componentOptions && child.componentOptions.tag && child.componentOptions.tag.toLowerCase().indexOf('records') !== -1 || child.type && child.type.name && child.type.name.toLowerCase().indexOf('records') !== -1;
		});

		var columnsWithColGroup = this._columns.filter(function (c) {
			return c.children.length === 0;
		}); // @ts-ignore


		var groupingPanel = groupable && h(GroupPanel_1.GroupPanel, {
			ref: this.v3 ? function (el) {
				_this.groupPanelDivRef = el;
			} : 'groupPanelDiv',
			group: this.$props.group || [],
			attrs: this.v3 ? undefined : {
				group: this.$props.group || [],
				resolveTitle: this.resolveTitle,
				getFieldTypeForGroupCell: this.getFieldTypeForGroupCell,
				getFieldValueForGroupCell: this.getFieldValueForGroupCell
			},
			onGroupChange: this.groupChangeHandler,
			on: this.v3 ? undefined : {
				"groupChange": this.groupChangeHandler,
				"pressHandler": this.dragLogic.pressHandler,
				"dragHandler": this.dragLogic.dragHandler,
				"releaseHandler": this.dragLogic.releaseHandler
			},
			onPressHandler: this.dragLogic.pressHandler,
			onDragHandler: this.dragLogic.dragHandler,
			onReleaseHandler: this.dragLogic.releaseHandler,
			resolveTitle: this.resolveTitle,
			getFieldTypeForGroupCell: this.getFieldTypeForGroupCell,
			getFieldValueForGroupCell: this.getFieldValueForGroupCell
		});
		var clues = (this.dragLogic.reorderable || this.dragLogic.groupable) && document && document.body // @ts-ignore
			&&
			[h(DropClue_1.DropClue, {
					ref: this.v3 ? function (el) {
						_this.dropElementClueRef = el;
					} : 'dropElementClue'
				}), // @ts-ignore
				h(DragClue_1.DragClue, {
					ref: this.v3 ? function (el) {
						_this.dragElementClueRef = el;
					} : 'dragElementClue'
				})
			]; // @ts-ignore

		var header = h(Header_1.Header, {
			columnResize: this.columnResize,
			attrs: this.v3 ? undefined : {
				columnResize: this.columnResize,
				staticHeaders: this.$props.scrollable !== 'none',
				// @ts-ignore
				headerRow: h(HeaderRow_1.HeaderRow, {
					grid: this,
					attrs: this.v3 ? undefined : {
						grid: this,
						sort: this.$props.sort,
						groupable: this.$props.groupable,
						reorderable: this.$props.reorderable,
						sortable: this.$props.sortable,
						filter: this.$props.filter,
						filterable: this.$props.filterable,
						columnMenu: this.$props.columnMenu,
						columns: this._columns,
						columnResize: this.columnResize,
						columnsMap: this._columnsMap,
						cellRender: this.$props.headerCellRender,
						filterRow: this.$props.filterable // @ts-ignore    
							&&
							h(FilterRow_1.FilterRow, {
								grid: this,
								attrs: this.v3 ? undefined : {
									grid: this,
									columns: this._columns,
									filter: this.$props.filter,
									sort: this.$props.sort,
									cellRender: this.$props.filterCellRender
								},
								columns: this._columns,
								filter: this.$props.filter,
								onFilterchange: this.filterChangeHandler,
								on: this.v3 ? undefined : {
									"filterchange": this.filterChangeHandler
								},
								sort: this.$props.sort,
								cellRender: this.$props.filterCellRender
							}) || undefined
					},
					sort: this.$props.sort,
					groupable: this.$props.groupable,
					reorderable: this.$props.reorderable,
					sortable: this.$props.sortable,
					onSortChange: this.sortChangeHandler,
					on: this.v3 ? undefined : {
						"sortChange": this.sortChangeHandler,
						"filterChange": this.filterChangeHandler,
						"selectionchange": this.onHeaderSelectionChangeHandler,
						"pressHandler": this.dragLogic.pressHandler,
						"dragHandler": this.dragLogic.dragHandler,
						"releaseHandler": this.dragLogic.releaseHandler
					},
					filter: this.$props.filter,
					filterable: this.$props.filterable,
					onFilterChange: this.filterChangeHandler,
					columnMenu: this.$props.columnMenu,
					onSelectionchange: this.onHeaderSelectionChangeHandler,
					columns: this._columns,
					columnResize: this.columnResize,
					onPressHandler: this.dragLogic.pressHandler,
					onDragHandler: this.dragLogic.dragHandler,
					onReleaseHandler: this.dragLogic.releaseHandler,
					columnsMap: this._columnsMap,
					cellRender: this.$props.headerCellRender,
					filterRow: this.$props.filterable && h(FilterRow_1.FilterRow, {
						grid: this,
						attrs: this.v3 ? undefined : {
							grid: this,
							columns: this._columns,
							filter: this.$props.filter,
							sort: this.$props.sort,
							cellRender: this.$props.filterCellRender
						},
						columns: this._columns,
						filter: this.$props.filter,
						onFilterchange: this.filterChangeHandler,
						on: this.v3 ? undefined : {
							"filterchange": this.filterChangeHandler
						},
						sort: this.$props.sort,
						cellRender: this.$props.filterCellRender
					}) || undefined
				}),
				cols: columnsWithColGroup.map(function (column, index) {
					return h("col", {
						key: index.toString(),
						width: column.width !== undefined ? isNaN(column.width) ? column.width : Math.floor(parseFloat(column.width.toString())) + 'px' : undefined,
						attrs: this.v3 ? undefined : {
							width: column.width !== undefined ? isNaN(column.width) ? column.width : Math.floor(parseFloat(column.width.toString())) + 'px' : undefined
						},
						style: (index > 0 ? "min-width:80px" : "")
					});
				}, this)
			},
			staticHeaders: this.$props.scrollable !== 'none',
			ref: this.v3 ? function (el) {
				_this.headerRef = el;
			} : 'header',
			headerRow: h(HeaderRow_1.HeaderRow, {
				grid: this,
				attrs: this.v3 ? undefined : {
					grid: this,
					sort: this.$props.sort,
					groupable: this.$props.groupable,
					reorderable: this.$props.reorderable,
					sortable: this.$props.sortable,
					filter: this.$props.filter,
					filterable: this.$props.filterable,
					columnMenu: this.$props.columnMenu,
					columns: this._columns,
					columnResize: this.columnResize,
					columnsMap: this._columnsMap,
					cellRender: this.$props.headerCellRender,
					filterRow: this.$props.filterable && h(FilterRow_1.FilterRow, {
						grid: this,
						attrs: this.v3 ? undefined : {
							grid: this,
							columns: this._columns,
							filter: this.$props.filter,
							sort: this.$props.sort,
							cellRender: this.$props.filterCellRender
						},
						columns: this._columns,
						filter: this.$props.filter,
						onFilterchange: this.filterChangeHandler,
						on: this.v3 ? undefined : {
							"filterchange": this.filterChangeHandler
						},
						sort: this.$props.sort,
						cellRender: this.$props.filterCellRender
					}) || undefined
				},
				sort: this.$props.sort,
				groupable: this.$props.groupable,
				reorderable: this.$props.reorderable,
				sortable: this.$props.sortable,
				onSortChange: this.sortChangeHandler,
				on: this.v3 ? undefined : {
					"sortChange": this.sortChangeHandler,
					"filterChange": this.filterChangeHandler,
					"selectionchange": this.onHeaderSelectionChangeHandler,
					"pressHandler": this.dragLogic.pressHandler,
					"dragHandler": this.dragLogic.dragHandler,
					"releaseHandler": this.dragLogic.releaseHandler
				},
				filter: this.$props.filter,
				filterable: this.$props.filterable,
				onFilterChange: this.filterChangeHandler,
				columnMenu: this.$props.columnMenu,
				onSelectionchange: this.onHeaderSelectionChangeHandler,
				columns: this._columns,
				columnResize: this.columnResize,
				onPressHandler: this.dragLogic.pressHandler,
				onDragHandler: this.dragLogic.dragHandler,
				onReleaseHandler: this.dragLogic.releaseHandler,
				columnsMap: this._columnsMap,
				cellRender: this.$props.headerCellRender,
				filterRow: this.$props.filterable && h(FilterRow_1.FilterRow, {
					grid: this,
					attrs: this.v3 ? undefined : {
						grid: this,
						columns: this._columns,
						filter: this.$props.filter,
						sort: this.$props.sort,
						cellRender: this.$props.filterCellRender
					},
					columns: this._columns,
					filter: this.$props.filter,
					onFilterchange: this.filterChangeHandler,
					on: this.v3 ? undefined : {
						"filterchange": this.filterChangeHandler
					},
					sort: this.$props.sort,
					cellRender: this.$props.filterCellRender
				}) || undefined
			}),
			cols: columnsWithColGroup.map(function (column, index) {
				return h("col", {
					key: index.toString(),
					width: column.width !== undefined ? isNaN(column.width) ? column.width : Math.floor(parseFloat(column.width.toString())) + 'px' : undefined,
					attrs: this.v3 ? undefined : {
						width: column.width !== undefined ? isNaN(column.width) ? column.width : Math.floor(parseFloat(column.width.toString())) + 'px' : undefined
					},
					style: (index > 0 ? "min-width:80px" : "")
				});
			}, this)
		});
		var footer = this._columns.some(function (c) {
				return Boolean(c.footerCell);
			}) ? // @ts-ignore
			h(Footer_1.Footer, {
				columnResize: this.columnResize,
				attrs: this.v3 ? undefined : {
					columnResize: this.columnResize,
					staticHeaders: this.$props.scrollable !== 'none',
					row: // @ts-ignore
						h(FooterRow_1.FooterRow, {
							columns: this._columns.map(function (col) {
								return __assign(__assign({}, col), {
									footerCell: kendo_vue_common_1.templateRendering.call(this, col.footerCell, kendo_vue_common_1.getListeners.call(this))
								});
							}, this),
							attrs: this.v3 ? undefined : {
								columns: this._columns.map(function (col) {
									return __assign(__assign({}, col), {
										footerCell: kendo_vue_common_1.templateRendering.call(this, col.footerCell, kendo_vue_common_1.getListeners.call(this))
									});
								}, this)
							}
						}),
					cols: columnsWithColGroup.map(function (column, index) {
						return h("col", {
							key: index.toString(),
							width: column.width !== undefined ? isNaN(column.width) ? column.width : Math.floor(parseFloat(column.width.toString())) + 'px' : undefined,
							attrs: this.v3 ? undefined : {
								width: column.width !== undefined ? isNaN(column.width) ? column.width : Math.floor(parseFloat(column.width.toString())) + 'px' : undefined
							},
							style: (index > 0 ? "min-width:80px" : "")
						});
					}, this)
				},
				staticHeaders: this.$props.scrollable !== 'none',
				ref: this.v3 ? function (el) {
					_this.footerRef = el;
				} : 'footer',
				row: h(FooterRow_1.FooterRow, {
					columns: this._columns.map(function (col) {
						return __assign(__assign({}, col), {
							footerCell: kendo_vue_common_1.templateRendering.call(this, col.footerCell, kendo_vue_common_1.getListeners.call(this))
						});
					}, this),
					attrs: this.v3 ? undefined : {
						columns: this._columns.map(function (col) {
							return __assign(__assign({}, col), {
								footerCell: kendo_vue_common_1.templateRendering.call(this, col.footerCell, kendo_vue_common_1.getListeners.call(this))
							});
						}, this)
					}
				}),
				cols: columnsWithColGroup.map(function (column, index) {
					return h("col", {
						key: index.toString(),
						width: column.width !== undefined ? isNaN(column.width) ? column.width : Math.floor(parseFloat(column.width.toString())) + 'px' : undefined,
						attrs: this.v3 ? undefined : {
							width: column.width !== undefined ? isNaN(column.width) ? column.width : Math.floor(parseFloat(column.width.toString())) + 'px' : undefined
						},
						style: (index > 0 ? "min-width:80px" : "")
					});
				}, this)
			}) : h("span");
		var cellRenderFunction = kendo_vue_common_1.templateRendering.call(this, this.$props.cellRender, kendo_vue_common_1.getListeners.call(this));
		var leftWidth = 0;
		var vnodeDataStyle = this.v3 ? this.$attrs.style : this.$vnode && this.$vnode.data ? this.$vnode.data.style : null;
		var widthProp = 'width';
		var elementInlineStyleWidth = vnodeDataStyle && (typeof vnodeDataStyle === "undefined" ? "undefined" : _typeof(vnodeDataStyle)) === 'object' ? vnodeDataStyle[widthProp] || '' : '';
		var tableWidth = parseFloat(elementInlineStyleWidth.toString());
		var scrollLeft = this.vs && this.vs.container && this.vs.container.scrollLeft || 0;

		var _a = kendo_vue_data_tools_1.tableColumnsVirtualization({
				enabled: this.$props.columnVirtualization,
				columns: this._columns,
				tableViewPortWidth: tableWidth,
				scrollLeft: scrollLeft
			}),
			colSpans = _a.colSpans,
			isColHidden = _a.hiddenColumns;

		var dataRow = function dataRow(item) {
			return this._columns.map(function (column, index) {
				if (isColHidden[index]) {
					return null;
				}

				var columnCellRenderFunction;

				if (column.cell) {
					columnCellRenderFunction = kendo_vue_common_1.templateRendering.call(this, column.cell, kendo_vue_common_1.getListeners.call(this));
				}

				if (column.internalCell) {
					let _columnsCount = this._columns.filter(function (c) {
						return !c.children.length;
					}).length
					return h(column.internalCell, {
						key: index,
						colSpan: colSpans[index],
						attrs: this.v3 ? undefined : {
							colSpan: colSpans[index],
							//menca tests
							//colSpan: item.rowType == 'groupHeader' && index <= item.level ? _columnsCount - index : colSpans[index],
							dataItem: item.dataItem,
							field: column.field || '' // todo
								,
							editor: column.editor,
							format: column.format,
							className: column.className,
							render: columnCellRenderFunction || cellRenderFunction,
							columnIndex: index,
							columnsCount: _columnsCount,
							rowType: item.rowType,
							level: item.level,
							expanded: item.expanded,
							dataIndex: item.dataIndex,
							groupTitle: this.resolveTitle(item.dataItem.field),
							values: this.getFieldValueForGroupCell(item.dataItem.field),
							type: this.getFieldTypeForGroupCell(item.dataItem.field),
						},
						dataItem: item.dataItem,
						field: column.field || '',
						editor: column.editor,
						format: column.format,
						className: column.className,
						render: columnCellRenderFunction || cellRenderFunction,
						onChange: this.itemChange,
						on: this.v3 ? undefined : {
							"change": this.itemChange,
							"selectionchange": this.selectionChangeHandler
						},
						onSelectionchange: this.selectionChangeHandler,
						columnIndex: index,
						columnsCount: this._columns.filter(function (c) {
							return !c.children.length;
						}).length,
						rowType: item.rowType,
						level: item.level,
						expanded: item.expanded,
						dataIndex: item.dataIndex,
						style: column.left !== undefined && {
							position: 'sticky',
							left: column.left + 'px',
							right: column.right + 'px',
							zIndex: 1,
							background: '#f6f6f6',
							borderRightWidth: column.rightBorder ? '1px' : ''
						} || {},
					});
				}

				if (column.editable && this.$props.editField) {
					var inEdit = index_1.getNestedValue(this.$props.editField, item.dataItem);

					if (inEdit === true || inEdit === column.field) {
						// @ts-ignore
						return h(GridEditCell_1.GridEditCell, {
							key: index,
							colSpan: colSpans[index],
							attrs: this.v3 ? undefined : {
								colSpan: colSpans[index],
								dataItem: item.dataItem,
								field: column.field || '' // todo
									,
								editor: column.editor,
								format: column.format,
								className: column.className,
								render: columnCellRenderFunction || cellRenderFunction,
								columnIndex: index,
								columnsCount: this._columns.filter(function (c) {
									return !c.children.length;
								}).length,
								rowType: item.rowType,
								level: item.level,
								expanded: item.expanded,
								dataIndex: item.dataIndex
							},
							dataItem: item.dataItem,
							field: column.field || '',
							editor: column.editor,
							format: column.format,
							className: column.className,
							render: columnCellRenderFunction || cellRenderFunction,
							onEdit: this.editHandler,
							on: this.v3 ? undefined : {
								"edit": this.editHandler,
								"remove": this.removeHandler,
								"save": this.saveHandler,
								"cancel": this.cancelHandler,
								"change": this.itemChange
							},
							onRemove: this.removeHandler,
							onSave: this.saveHandler,
							onCancel: this.cancelHandler,
							onChange: this.itemChange,
							columnIndex: index,
							columnsCount: this._columns.filter(function (c) {
								return !c.children.length;
							}).length,
							rowType: item.rowType,
							level: item.level,
							expanded: item.expanded,
							dataIndex: item.dataIndex,
							style: column.left !== undefined && {
								position: 'sticky',
								left: column.left + 'px',
								right: column.right + 'px',
								zIndex: 1,
								background: '#f6f6f6',
								borderRightWidth: column.rightBorder ? '1px' : ''
							} || {}
						});
					}
				} // @ts-ignore


				var inbuiltSlot = h(GridCell_1.GridCell, {
					key: index,
					colSpan: colSpans[index],
					attrs: this.v3 ? undefined : {
						colSpan: colSpans[index],
						dataItem: item.dataItem,
						field: column.field || '' // todo
							,
						editor: column.editor,
						format: column.format,
						className: column.className,
						render: columnCellRenderFunction || cellRenderFunction,
						columnIndex: index,
						columnsCount: this._columns.filter(function (c) {
							return !c.children.length;
						}).length,
						rowType: item.rowType,
						level: item.level,
						expanded: item.expanded,
						dataIndex: item.dataIndex,

						//custom properties
						type: column.type,
						renderFunction: column.renderFunction,
						disabled: column.disabled,
						pathFallback: column.pathFallback,
						values: column.values,
						customButtons: column.customButtons,
						label: column.label,
						downloadFileUrlType: column.downloadFileUrlType,
						fieldSourceText: column.fieldSourceText,
						fieldSourceValue: column.fieldSourceValue
					},
					dataItem: item.dataItem,
					field: column.field || '',
					editor: column.editor,
					format: column.format,
					className: column.className,
					render: columnCellRenderFunction || cellRenderFunction,
					onCellclick: this.cellClickHandler,
					on: this.v3 ? undefined : {
						"cellclick": this.cellClickHandler,
						"cellkeydown": this.cellKeydownHandler,
						"edit": this.editHandler,
						"remove": this.removeHandler,
						"save": this.saveHandler,
						"cancel": this.cancelHandler,
						"change": this.itemChange,
						"selectionchange": this.selectionChangeHandler
					},
					onCellkeydown: this.cellKeydownHandler,
					onEdit: this.editHandler,
					onRemove: this.removeHandler,
					onSave: this.saveHandler,
					onCancel: this.cancelHandler,
					onChange: this.itemChange,
					onSelectionchange: this.selectionChangeHandler,
					columnIndex: index,
					columnsCount: this._columns.filter(function (c) {
						return !c.children.length;
					}).length,
					rowType: item.rowType,
					level: item.level,
					expanded: item.expanded,
					dataIndex: item.dataIndex,
					style: column.left !== undefined && {
						position: 'sticky',
						left: column.left + 'px',
						right: column.right + 'px',
						zIndex: 1,
						background: '#f6f6f6',
						borderRightWidth: column.rightBorder ? '1px' : ''
					} || {}
				});
				return inbuiltSlot;
			}, this);
		};

		var hiddenRows = 0;

		if (this.$props.scrollable === 'virtual') {
			for (var i = 0; i < this.vs.topCacheCount + this.vs.attendedSkip - (this.$props.skip || 0); i++) {
				var item = data.shift();

				if (item) {
					data.push(item);
					hiddenRows++;

					if (item.rowType === 'groupHeader') {
						i--;
					}
				} else {
					break;
				}
			}
		}

		var hidden = function hidden(index) {
			return index >= data.length - hiddenRows;
		};

		var absoluteIndex = function absoluteIndex(index) {
			return index + (_this.vs.propsSkip || 0);
		};

		var detailRenderFunction = kendo_vue_common_1.templateRendering.call(this, this.$props.detail, kendo_vue_common_1.getListeners.call(this));

		var detailRender = function detailRender(args) {
			return kendo_vue_common_1.getTemplate.call(this, {
				h: h,
				template: detailRenderFunction,
				additionalProps: args
			});
		};

		var absoluteDataIndex = this.vs.propsSkip || 0; // @ts-ignore

		var body = data.length && data.map(function (item, index) {
			var _this2 = this;

			var _this = this;

			if (item.rowType === 'data') {
				absoluteDataIndex++;
			}

			if (this.$props.alternatePerGroup && item.rowType === 'groupHeader') {
				absoluteDataIndex = 0;
			}

			var isAlt = absoluteDataIndex % 2 === 0;
			leftWidth = 0;
			var rowRenderFunction = kendo_vue_common_1.templateRendering.call(this, this.$props.rowRender, kendo_vue_common_1.getListeners.call(this));
			return [ // @ts-ignore function children
				h(GridRow_1.GridRow, {
					key: absoluteIndex(index) * 2,
					dataItem: item.dataItem,
					attrs: this.v3 ? undefined : {
						dataItem: item.dataItem,
						isAltRow: isAlt,
						rowType: item.rowType,
						isHidden: hidden(index),
						selectedField: this.$props.selectedField,
						rowHeight: this.$props.rowHeight,
						render: rowRenderFunction
					},
					isAltRow: isAlt,
					rowType: item.rowType,
					isHidden: hidden(index),
					onRowclick: function onRowclick(e) {
						return _this.rowClick(e, item);
					},
					on: this.v3 ? undefined : {
						"rowclick": function onRowclick(e) {
							return _this.rowClick(e, item);
						},
						"rowdblclick": function rowdblclick(e) {
							return _this.rowDoubleClick(e, item);
						}
					},
					onRowdblclick: function rowdblclick(e) {
						return _this.rowDoubleClick(e, item);
					},
					selectedField: this.$props.selectedField,
					rowHeight: this.$props.rowHeight,
					render: rowRenderFunction
				}, this.v3 ? function () {
					return [dataRow.call(_this2, item)];
				} : [dataRow.call(_this2, item)]), this.$props.detail && item.rowType === 'data' && item.expanded && h("tr", {
					key: absoluteIndex(index) * 2 + 1,
					"class": isAlt ? 'k-detail-row k-alt' : 'k-detail-row',
					style: {
						visibility: hidden(index) ? 'hidden' : ''
					}
				}, [this.$props.group && this.$props.group.map(function (group, idx) {
					// @ts-ignore

					return h(GridGroupCell_1.GridGroupCell, {
						dataIndex: item.dataIndex,
						attrs: this.v3 ? undefined : {
							dataIndex: item.dataIndex,
							field: group.field,
							dataItem: item.dataItem
						},
						field: group.field,
						dataItem: item.dataItem,
						key: idx
					});
				}, this), this.$props.expandField && h("td", {
					"class": "k-hierarchy-cell"
				}), h("td", {
					"class": "k-detail-cell",
					colSpan: this._columns.filter(function (c) {
						return !c.children.length;
					}).length - (this.$props.expandField ? 1 : 0) - (this.$props.group ? this.$props.group.length : 0),
					attrs: this.v3 ? undefined : {
						colSpan: this._columns.filter(function (c) {
							return !c.children.length;
						}).length - (this.$props.expandField ? 1 : 0) - (this.$props.group ? this.$props.group.length : 0)
					}
				}, [detailRender.call(this, {
					dataItem: item.dataItem
				})])])
			];
		}, this) || h("tr", {
			"class": "k-grid-norecords"
		}, [h("td", {
			colSpan: this._columns.filter(function (c) {
				return !c.children.length;
			}).length,
			attrs: this.v3 ? undefined : {
				colSpan: this._columns.filter(function (c) {
					return !c.children.length;
				}).length
			}
		}, [ // @ts-ignore
			noRecords.length ? noRecords : h(GridNoRecords_1.GridNoRecords)
		])]); // @ts-ignore

		var pager = this.$props.pageable && h(Pager_1.Pager, {
			onPageChange: this.pageChangeHandler,
			on: this.v3 ? undefined : {
				"pageChange": this.pageChangeHandler
			},
			total: total,
			attrs: this.v3 ? undefined : {
				total: total,
				skip: this.vs.propsSkip || 0,
				pageSize: (this.$props.take !== undefined ? this.$props.take : this.$props.pageSize) || 10,
				settings: this.$props.pageable
			},
			skip: this.vs.propsSkip || 0,
			pageSize: (this.$props.take !== undefined ? this.$props.take : this.$props.pageSize) || 10,
			settings: this.$props.pageable
		});

		var sorted = function sorted(field) {
			return _this.$props.sort && _this.$props.sort.filter(function (descriptor) {
				return descriptor.field === field;
			}).length > 0;
		};

		var colGroups = h("colgroup", {
			ref: this.v3 ? function (el) {
				_this.colGroupRef = el;
			} : 'colGroup'
		}, [columnsWithColGroup.map(function (column, index) {
			return h("col", {
				key: index.toString(),
				"class": sorted(column.field) ? 'k-sorted' : undefined,
				width: column.width !== undefined ? isNaN(column.width) ? column.width : Math.floor(parseFloat(column.width.toString())) + 'px' : undefined,
				attrs: this.v3 ? undefined : {
					width: column.width !== undefined ? isNaN(column.width) ? column.width : Math.floor(parseFloat(column.width.toString())) + 'px' : undefined
				},
				style: (index > 0 ? "min-width:80px" : "")
			});
		}, this)]);
		var wrapperStyle = {
			height: this.getCorrectHeight
		};

		if (this.$props.scrollable === 'none') {
			return h("div", {
				style: wrapperStyle,
				"class": "k-widget k-grid"
			}, [toolbar, groupingPanel, h("table", [colGroups, header, h("tbody", [body]), footer]), pager, clues]);
		}

		return h("div", {
			style: wrapperStyle,
			"class": 'k-widget k-grid' + (this.$props.scrollable === 'virtual' ? ' k-grid-virtual' : '')
		}, [toolbar, groupingPanel, header, h("div", {
			"class": "k-grid-container"
		}, [h("div", {
			ref: this.v3 ? function (el) {
				_this.scrollContainerRef = el;
			} : 'scrollContainer',
			"class": "k-grid-content k-virtual-content",
			onScroll: this.scrollHandler,
			on: this.v3 ? undefined : {
				"scroll": this.scrollHandler
			}
		}, [h("div", {
			style: {
				'position': 'relative'
			}
		}, [h("table", {
			tabIndex: -1,
			attrs: this.v3 ? undefined : {
				tabIndex: -1
			},
			"class": 'k-grid-table',
			ref: this.v3 ? function (el) {
				_this.scrollTableRef = el;
			} : 'scrollTable'
		}, [colGroups, h("tbody", {
			ref: this.v3 ? function (el) {
				_this.scrollTableBodyRef = el;
			} : 'scrollTableBody'
		}, [body])])]), h("div", {
			"class": "k-height-container"
		}, [h("div", {
			style: this.$props.scrollable === 'virtual' ? {
				'height': this.vs.containerHeight + 'px'
			} : {}
		})])])]), footer, pager, clues]);
	}
};
exports.Grid = Grid;