class Permessi {

	public readonly WEB =
		{
			Anagrafica:
			{
				ACCESSO_CARRELLO: "WEB_ANAGRAFICA_ACCESSO_CARRELLO"
			},
			MyWebins: 
			{
				UTENTI: "WEB_MYWEBINS_UTENTI",
				PAGAMENTI: "WEB_MYWEBINS_PAGAMENTI",
				CREDITI: "WEB_MYWEBINS_CREDITI",
				ESTRATTICONTO: "WEB_MYWEBINS_ESTRATTICONTO",
				ACQUISTI_POLIZZE: "WEB_MYWEBINS_ACQUISTI_POLIZZE",
				TUOI_ACQUISTI: "WEB_MYWEBINS_TUOI_ACQUISTI",
				COMMERCIALE_DISTRIBUTORI: "COMMERCIALE_DISTRIBUTORI",
				ACQUISTI_NETWORK: "ACQUISTI_NETWORK",
				ESTRATTICONTO_NETWORK: "ESTRATTICONTO_NETWORK"
			}
		}
		public readonly BK =
		{
			Impostazioni:
			{
				ACCEDICOME: "BK_IMPOSTAZIONI_ACCEDICOME",
			},
		}
}


const permessi = new Permessi();
export default permessi;

export enum AttributiUtente {
	ALL = 'ALL',
	fornitore = 'fornitore',
	distributore = 'distributore',
	commerciale = 'commerciale',
	distributore_network = 'distributore_network',
	distributore_agenziadettagliante = 'distributore_agenziadettagliante',
	distributore_touroperator = 'distributore_touroperator'
}