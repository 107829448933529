import gridComponentBase from "./grid/gridComponentBase.vue";
import { Component } from "vue-property-decorator";
import api from "@/services/api";
import { TextValueItem } from "@/models/TextValueItem";
import { customEventItem } from "@/models/grid/columnModels";
import { mapActions } from "vuex";
import { UserLoggedInfoModel } from "@/models/userModels";
import { LoginUserAsResponse } from "@/models/login/loginUserAsResponse";
import { Filter } from "@/models/grid/filter";

const _ENDPONT = api.endpoint.UTENTI_SIGN_IN_AS;

@Component({
	// methods: { ...mapActions(["loginUserAs"]) }
	methods: { ...mapActions(["loginUser"]) }
})
export default class AccediComeModalPage extends gridComponentBase {
	distributori: Array<TextValueItem> = [];
	fornitori: Array<TextValueItem> = [];

	textSearch: string = "";

	//loginUserAs!: (data: LoginUserAsResponse) => Promise<UserLoggedInfoModel>;

	loading: boolean = true;

	get columns(): Array<any> {
		return [
			{
				field: "itemID",
				headerCell: "myHeaderCellTemplate",
				title: "ID",
				width: 20,
				filterable: false,
				hidden: true,
				editHide: true
			},
			{
				field: "username",
				headerCell: "myHeaderCellTemplate",
				title: this.$i18n.t("generico.utente").toString(),
				export: true
			},
			{
				field: "nome",
				headerCell: "myHeaderCellTemplate",
				title: this.$i18n.t("generico.nome").toString(),
				//width: 200,
				attributes: {
					class: "rsClick",
					style: "font-weight: bold;"
				},
				export: true
			},
			{
				field: "cognome",
				headerCell: "myHeaderCellTemplate",
				title: this.$i18n.t("generico.cognome").toString(),
				//width: 200,
				attributes: {
					class: "rsClick",
					style: "font-weight: bold;"
				},
				export: true
			},
			{
				field: "fornitoreID",
				title: this.$i18n.t("generico.fornitore").toString(),
				//width: 150,
				values: this.fornitori,
				groupable: true,
				sortBy: "fornitore",
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				itemsForFilter: this.fornitori,
				filterType: "select"
			},
			{
				field: "distributoreID",
				title: "Distributore",
				//width: 150,
				values: this.distributori,
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				sortBy: "distributore",
				filterable: true,
				itemsForFilter: this.distributori,
				filterType: "select"
			},
			{
				field: "_actions",
				title: " ",
				width: 150,
				cell: "defaultCellTemplate",
				type: "actions_list",
				filterable: false,
				headerCell: "myHeaderCellTemplate",
				customButtons: [{ command: "signInAs", label: "accedi", visible: true, variant: "success" }]
			}
		];
	}

	get columnsMobile(): Array<any> {
		return [
			{
				field: "itemID",
				headerCell: "myHeaderCellTemplate",
				title: "ID",
				width: 20,
				filterable: false,
				hidden: true,
				editHide: true
			},
			{
				field: "username",
				headerCell: "myHeaderCellTemplate",
				title: this.$i18n.t("generico.utente").toString(),
				export: true,
				width: 150
			},
			{
				field: "nome",
				headerCell: "myHeaderCellTemplate",
				title: this.$i18n.t("generico.nome").toString(),
				//width: 200,
				attributes: {
					class: "rsClick",
					style: "font-weight: bold;"
				},
				export: true
			},
			{
				field: "cognome",
				headerCell: "myHeaderCellTemplate",
				title: this.$i18n.t("generico.cognome").toString(),
				//width: 200,
				attributes: {
					class: "rsClick",
					style: "font-weight: bold;"
				},
				export: true
			},
			{
				field: "fornitoreID",
				title: this.$i18n.t("generico.fornitore").toString(),
				width: 150,
				values: this.fornitori,
				groupable: true,
				sortBy: "fornitore",
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				itemsForFilter: this.fornitori,
				filterType: "select"
			},
			{
				field: "distributoreID",
				title: "Distributore",
				width: 250,
				values: this.distributori,
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				sortBy: "distributore",
				filterable: true,
				itemsForFilter: this.distributori,
				filterType: "select",
				headerType: "select"
			},
			{
				field: "_actions",
				title: " ",
				width: 150,
				cell: "defaultCellTemplate",
				type: "actions_list",
				filterable: false,
				headerCell: "myHeaderCellTemplate",
				customButtons: [{ command: "signInAs", label: "ACCEDI", visible: true, variant: "success" }]
			}
		];
	}

	created() {
		if (!(this.isAppMobile == false)) {
			this.pageable["buttonCount"] = 3;
			this.pageable["info"] = false;
			this.pageable["pageSizes"] = undefined;
		}
	}

	mounted() {
		var self = this;
		var p = [
			api.getDatiDropDown(api.endpoint.DROPDOWN.DISTRIBUTORI_TUTTI).then(res => (self.distributori = res)),
			api.getDatiDropDown(api.endpoint.DROPDOWN.FORNITORI).then(res => (self.fornitori = res))
		];

		Promise.all(p)
			.then(responses => {
				this.getData();
				self.loading = false;
			})
			.catch(err => {
				console.log(err);
			});
	}

	getData() {
		var self = this;
		self.getGridDataFullTextSearch(_ENDPONT, this.textSearch);
	}

	keyHandler(e){
		e && e.which === 13 && this.getData();
	}

	onCustomButtonClick(eventCode: customEventItem) {
		if (eventCode) {
			let command = eventCode.command;
			let item = eventCode.item;

			if (command == "signInAs") {
				if (item.userId) {
					var self = this;
					api.signInAs(item.userId)
						.then(res => {
							let path = "/";
							self.loginUser(res.data.token).then(() => {
								self.$emit("user-changed", true);

								// per ora non facciamo controlli su padre... perchè non è previsto che ne abbiano
								// if (
								// 	self.$route.meta &&
								// 	self.hasPermessi(self.$route.meta.permissions) == false &&
								// 	self.$route.fullPath != null &&
								// 	self.$route.fullPath != "" &&
								// 	self.$route.fullPath != "/"
								// ) {
								// 	self.$router.push({ path: path as string });
								// }

								self.$router.push({ path: path as string });
							});
							self.isLoading = false;

							setTimeout(() => {
								location.reload();
							}, 500);
						})
						.catch(err => {
							console.log(err);
						});
				} else {
					this.$bvToast.toast(this.$i18n.t("generico.novalidusertosigninas").toString(), {
						variant: "warning",
						title: this.$i18n.t("generico.warning").toString(),
						solid: true
					});
				}
			}
		}
	}

	onClickSearch() {
		if (this.textSearch.length > 0) {
			this.getData();
		}
	}

	onClearSearchText() {
		this.textSearch = "";
		this.getData();
	}

	showfilterDialog: boolean = false;

	onApplyFiltersMobile(filtersMobile: Filter) {
		this.filter = filtersMobile;
		this.onFilterMobileApply();
		this.onCloseDialogMessage();
	}
	onRemoveAllFilters() {
		this.clearSortAndFilter();
		//this.onCloseDialogMessage();
	}

	onCloseDialogMessage() {
		this.showfilterDialog = false;
	}

	onOpenFilterDialog() {
		this.showfilterDialog = true;
	}
}
