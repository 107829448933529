export class PartecipantiCarrello {
	ItemID: number | null;
	Nome: string;
	Cognome: string;
	CodiceFiscale: string;
	DataNascita: Date | null;

	constructor() {
		this.Cognome = "";
		this.Nome = "";
		this.CodiceFiscale = "";
		this.DataNascita = null;
	}
}