import { Component, Prop, Watch } from "vue-property-decorator";
import { mapActions, mapGetters, mapMutations } from "vuex";
import baseUserContextComponent from "./baseUserContextComponent";

import axios from "axios";

var api = axios.create({
	baseURL: process.env.VUE_APP_BASE_URL
});

api.interceptors.request.use(config => {
	config.headers.common["Authorization"] = "bearer " + localStorage.getItem("overins-token");
	return config;
});

var setImgSrc = (el, binding) => {
	if (binding.oldValue === undefined || binding.value !== binding.oldValue) {
		var imageUrl = binding.value;
		api({
			method: "get",
			url: imageUrl,
			responseType: "arraybuffer"
		})
			.then(function(resp) {
				var mimeType = resp.headers["content-type"].toLowerCase();
				// var imgBase64 = new Buffer(resp.data, 'binary').toString('base64');
				var imgBase64 = Buffer.from(resp.data).toString("base64");
				el.src = "data:" + mimeType + ";base64," + imgBase64;
			})
			.catch(function() {
				el.src = imageUrl;
			});
	}
};

@Component({})
export default class appImage extends baseUserContextComponent {
	@Prop({ default: () => "" }) alt: string;
	@Prop({ default: () => false }) blank: boolean;
	@Prop({ default: () => "transparent" }) blankColor: string;
	@Prop({ default: () => false }) block: boolean;
	@Prop({ default: () => false }) center: boolean;
	@Prop({ default: () => false }) fluid: boolean;
	// Gives fluid images class `w-100` to make them grow to fit container
	@Prop({ default: () => false }) fluidGrow: boolean;
	@Prop({ default: () => false }) left: boolean;
	@Prop({ default: () => "0px" }) right: string;
	@Prop({ default: () => "0px" }) top: string;
	@Prop({ default: () => "relative" }) position: string;
	// Possible values:
	//   `false`: no rounding of corners
	//   `true`: slightly rounded corners
	//   'top': top corners rounded
	//   'right': right corners rounded
	//   'bottom': bottom corners rounded
	//   'left': left corners rounded
	//   'circle': circle/oval
	//   '0': force rounding off
	@Prop({ default: () => false }) rounded: boolean | string;
	@Prop({ default: () => "" }) sizes: string[];
	@Prop({ default: () => null }) src: string;
	@Prop({ default: () => null }) srcset: string[];
	@Prop({ default: () => false }) thumbnail: boolean;
	@Prop({ default: () => null }) width: number | string;
	@Prop({ default: () => null }) height: number | string;

	@Prop({ default: () => true }) api: boolean;
	@Prop({ default: () => null }) pathFallback: string;
	@Prop({ default: () => null }) fallbackImage: string;
	@Prop({ default: () => false }) addWrapper: boolean;
	@Prop({ default: () => false }) addWrapperBackground: boolean;

	isSvg: boolean = false;

	@Watch("src")
	onSrcChange(newValue: string, oldValue: string) {
		this.onS_Change(newValue, oldValue);
	}
	@Watch("pathFallback")
	onPathFallbackChange(newValue: string, oldValue: string) {
		this.onS_Change(newValue, oldValue);
	}
	onS_Change(newValue: string, oldValue: string) {
		var self = this;
		var imageUrl = newValue;

		if (oldValue === undefined || newValue !== oldValue) {
			if (self.api) {
				var url = `${this.$appConfig.baseApiUrl}/file/image/${imageUrl}${self.pathFallback != null ? "?fallback=" + self.pathFallback : ""}`;
				api({
					method: "get",
					url: url,
					responseType: "arraybuffer"
				})
					.then(function(resp) {
						var mimeType = resp.headers["content-type"].toLowerCase();

						if (mimeType != "image/svg") {
							// var imgBase64 = new Buffer(resp.data, 'binary').toString('base64');
							var imgBase64 = Buffer.from(resp.data).toString("base64");
							self.srcImag = "data:" + mimeType + ";base64," + imgBase64;
						} else {
							self.isSvg = true;
							self.srcImag = Buffer.from(resp.data).toString();
						}
					})
					.catch(function() {
						self.srcImag = imageUrl || self.fallbackImage;
					});
			} else self.srcImag = imageUrl || self.fallbackImage;
		} else {
			var url = `${this.$appConfig.baseApiUrl}/file/image/${self.fallbackImage}`;
			api({
				method: "get",
				url: url,
				responseType: "arraybuffer"
			})
				.then(function(resp) {
					var mimeType = resp.headers["content-type"].toLowerCase();
					if (mimeType != "image/svg") {
						// var imgBase64 = new Buffer(resp.data, 'binary').toString('base64');
						var imgBase64 = Buffer.from(resp.data).toString("base64");
						self.srcImag = "data:" + mimeType + ";base64," + imgBase64;
					} else {
						self.isSvg = true;
						self.srcImag = Buffer.from(resp.data).toString();
					}
				})
				.catch(function() {
					self.srcImag = imageUrl || self.fallbackImage;
				});
		}
	}
	get source() {
		var self = this;
		if (this.src == null || this.src == undefined || this.src == "") {
			return this.fallbackImage;
		}

		if (self.api) {
			var url = `${this.$appConfig.baseApiUrl}/file/image/${self.src}${self.pathFallback != null ? "?fallback=" + self.pathFallback : ""}`;
			api({
				method: "get",
				url: url,
				responseType: "arraybuffer"
			})
				.then(function(resp) {
					var mimeType = resp.headers["content-type"].toLowerCase();
					// var imgBase64 = new Buffer(resp.data, 'binary').toString('base64');
					var imgBase64 = Buffer.from(resp.data).toString("base64");
					//self.srcImag = 'data:' + mimeType + ';base64,' + imgBase64;
					return "data:" + mimeType + ";base64," + imgBase64;
				})
				.catch(function() {
					return self.src || self.fallbackImage;
				});
		} else return self.src;

		return "";
	}

	srcImag: string = null;
	created() {
		this.onSrcChange(this.src, null);
	}

	get getWidth() {
		if (this.$props.width == undefined || this.$props.width == null || this.$props.width == "0") return "auto";

		return this.$props.width + "px";
	}

	get getHeight() {
		if (this.$props.height == undefined || this.$props.height == null || this.$props.height == "0") return "auto";

		return this.$props.height + "px";
	}
	get wrapperHeight() {
		// if (this.sticky) return "100%";
		return this.getHeight;
	}
}
