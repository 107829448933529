import helpers from "@/helpers";
import { datiViaggio, totaliCarrello } from "@/models/carrello/datiViaggio";
import { CarrelloInfoProdotto } from "@/models/carrello/infoProdotto";
import { ParametriCarrelloItem } from "@/models/carrello/parametriCarrello";
import { parametriPreventivatoreItem } from "@/models/carrello/parametriPreventivatore";
import { TextValueExtended } from "@/models/TextValueItem";
import api from "@/services/api";
import { relativeTimeRounding } from "moment";
import { Component, Prop, Watch } from "vue-property-decorator";
import { mapMutations, mapState } from "vuex";
import baseUserContextComponent from "./baseUserContextComponent";

const _ENDPONT_TOTALI = api.endpoint.CARRELLO.TOTALI;

@Component({
	computed: {
		...mapState({
			datiViaggio: (state: any) => state.datiViaggio,
			prodottoSelezionato: (state: any) => state.prodottoSelezionato
		})
	},
	methods: {
		...mapMutations(["setDatiViaggio"])
	}
})
export default class complementareMobile extends baseUserContextComponent {
	expand: boolean = false;
	addComplemetare: boolean = false;
	showComplementareDetail: boolean = false;

	showDestinazione: boolean = false;
	showImporto: boolean = false;
	showNazionalita: boolean = false;

	nazionalita: TextValueExtended[] = [];
	destinazioni: TextValueExtended[] = [];

	nazionalitaID: number = 0;
	destinazioneID: number = 0;
	importoViaggio: number = 0;
	
	helpers: any = helpers;

	datiViaggio!: datiViaggio;
	prodottoSelezionato!: CarrelloInfoProdotto;

	totali: totaliCarrello | null = null;
	setDatiViaggio!: (setDatiViaggio: datiViaggio) => void;

	@Prop({ default: () => undefined }) complementare: CarrelloInfoProdotto;
	@Prop({ default: () => true }) showImportoViaggio: boolean;
	@Prop({ default: () => true }) showDestinazioneViaggio: boolean;
	@Prop({ default: () => true }) showNazionalitaViaggio: boolean;
	@Prop({ default: () => null }) defaultImportoViaggio: number;
	@Prop({ default: () => null }) defaultDestinazioneViaggio: number;
	@Prop({ default: () => null }) defaultNazionalitaViaggio: number;

	get showInputImportViaggio(): boolean {
		return this.showImporto && this.addComplemetare && this.showImportoViaggio;
	}
	get showInputDestinazione(): boolean {
		return this.showDestinazione && this.addComplemetare //&& this.showDestinazioneViaggio;
	}
	get showInputNazionalita(): boolean {
		return this.showNazionalita && this.addComplemetare && this.showNazionalitaViaggio;
	}
	get showArrow(): boolean {
		return this.showInputImportViaggio || this.showInputDestinazione || this.showInputNazionalita;
	}
	@Watch("datiViaggio.parametri.importoViaggio", { immediate: true })
	updateImporto() {
		this.importoViaggio = this.datiViaggio.parametri.importoViaggio;
	}
	@Watch("datiViaggio.parametri.destinazioneStato", { immediate: true })
	updateDestinazioneStato() {
		//this.destinazioneID = this.datiViaggio.parametri.destinazioneStato;
		var destCarrello = this.datiViaggio.parametri.destinazioneStato,
			destCompl = this.destinazioni.find(obj => obj.value == (destCarrello || {} as any).value) as any;
		destCompl && (this.destinazioneID = destCompl)
	}

	isObbligatorio() {
		if (!this.complementare) return false;

		if (helpers.getExtraInfoCarrello(this.complementare.extraInfo, helpers.tipiExtraInfo.complementareObbligatorio)) return true;
		return false;
	}

	beforeDestroy() {}
	beforeMount() {}
	mounted() {}
	beforeCreate() {}
	created() {
		this.initComplementare();
		//(this.$parent as any).calcolaPreventivo();
	}

	async initComplementare() {
		if (this.complementare.destinazioni && this.complementare.destinazioni.length) {
			this.destinazioni = this.complementare.destinazioni.map(function(obj) {
				var a = new TextValueExtended();
				a.value = obj.code;
				a.text = obj.nazione;
				return a;
			});
		}

		if (this.complementare.nazionalita && this.complementare.nazionalita.length) {
			this.nazionalita = this.complementare.nazionalita.map(function(obj) {
				var a = new TextValueExtended();
				a.value = obj.codice;
				a.text = obj.nazione;
				return a;
			});
		}

		this.checkVisibilitaParametri();

		this.importoViaggio = this.datiViaggio.parametri.importoViaggio;

		var currPCollegato = this.datiViaggio.prodottiCollegati.find(obj => obj.pacchettoID == this.complementare.pacchettoID);

		if (currPCollegato) {
			if (currPCollegato.nazionalitaID) this.nazionalitaID = this.nazionalita.find(obj => obj.value == (currPCollegato.nazionalitaID as any)) as any;

			if (currPCollegato.destinazioneID) this.destinazioneID = this.destinazioni.find(obj => obj.value == (currPCollegato.destinazioneID as any)) as any;

			this.addComplemetare = true;
			this.expand = true;
			await this.calcolaTotali(false);
			(this.$parent as any).forzaSommaTotali();
		} else if (this.isObbligatorio()) {
			this.addComplemetare = false;
			this.onAddComplemetare();
		}
	}

	checkVisibilitaParametri() {
		// mostro destinazione se quella scelta dall'utente non è presente nell'elenco del complementare
		var trovato = false,
			currDestID = this.datiViaggio.parametri.destinazioneStato ? (this.datiViaggio.parametri.destinazioneStato as any).value || "" : "";
		for (var i = 0; i < this.destinazioni.length; i++) {
			if (this.destinazioni[i].value === currDestID) {
				trovato = true;
				break;
			}
		}

		this.showDestinazione = !trovato;

		// mostro nazionalia se quella scelta dall'utente non è presente nell'elenco del complementare
		var trovato = false,
			currNazID = this.datiViaggio.parametri.nazionalita ? (this.datiViaggio.parametri.nazionalita as any).value || 0 : 0;
		for (var i = 0; i < this.nazionalita.length; i++) {
			if (this.nazionalita[i].value === currNazID) {
				trovato = true;
				break;
			}
		}

		// faccio vedere la tendina se ho piu di 1 nazionalita oppure se ce n'ho solo una e non è italia
		if( this.nazionalita.length> 1 || (this.nazionalita.length === 1 && this.nazionalita[0].value !== 1)){
			this.showNazionalita = true;
		}
		else
			this.showNazionalita = !trovato;

		if (helpers.getExtraInfoCarrello(this.complementare.extraInfo, helpers.tipiExtraInfo.importoViaggioObbligatorio)) {
			this.showImporto = true;
			this.importoViaggio = this.datiViaggio.parametri.importoViaggio;
		} else {
			this.showImporto = false;
		}
	}

	onAddComplemetare() {
		this.addComplemetare = !this.addComplemetare;

		var currPCollegato = this.datiViaggio.prodottiCollegati.find(obj => obj.pacchettoID == this.complementare.pacchettoID);

		if (!currPCollegato && this.addComplemetare) {
			this.datiViaggio.prodottiCollegati.push({ pacchettoID: this.complementare.pacchettoID });
		} else if (currPCollegato && !this.addComplemetare) {
			var index = this.datiViaggio.prodottiCollegati.findIndex(obj => obj.pacchettoID == this.complementare.pacchettoID);
			this.datiViaggio.prodottiCollegati.splice(index, 1);
			this.destinazioneID = 0;
			this.nazionalitaID = 0;
			this.importoViaggio = 0;
		}

		this.setDatiViaggio(this.datiViaggio);

		//this.calcolaTotali(true);
		this.$emit("onChangeComplemetare");
		if (this.addComplemetare) this.expand = true;
		else this.expand = false;
	}

	async calcolaTotali(validaDati) {
		var self = this;
		return new Promise(async function(resolve, reject) {
			if (!self.addComplemetare) {
				self.totali = null;
				resolve("");
				return;
			}
			var parent = self.$parent as any;

			if (validaDati && !parent.validaDatiPreventivo()) {
				resolve("");
				return null;
			}

			var datiProdotto = parent.getDati();
			datiProdotto.pacchettoID = self.complementare.pacchettoID;

			var datiCompl = self.getDati();
			if (datiCompl.nazionalitaID) datiProdotto.nazionalitaID = datiCompl.nazionalitaID;

			if (datiCompl.statoID) datiProdotto.statoID = datiCompl.statoID;

			delete datiProdotto.secondoImporto; // tolgo il secondo importo, se abilitato ( in teoria nessun complementare può avere secondoImporto )

			if (!self.validaDati()) {
				resolve("");
				return;
			}

			/*if (!self.getExtraInfo("ApplicazioneAlloggio") && !self.getExtraInfo("ApplicazioneImbarcazione"))
				delete datiProdotto.NumeroAlloggi;*/

			var tot = await api.chimataPost<totaliCarrello>(_ENDPONT_TOTALI, datiProdotto);

			if (!tot || !tot.data || (tot.data.erroriTotaleCarrello && tot.data.erroriTotaleCarrello.length)) {
				if (tot.data.erroriTotaleCarrello && tot.data.erroriTotaleCarrello.length) {
					for (var i = 0; i < tot.data.erroriTotaleCarrello.length; i++) {
						var codice = tot.data.erroriTotaleCarrello[i].codice;
						var errMsg = tot.data.erroriTotaleCarrello[i].descrizione;

						if (codice) {
							errMsg = self.$i18n.t(codice).toString();
							if (tot.data.erroriTotaleCarrello[i].data && (tot.data.erroriTotaleCarrello[i].data as any).pacchetto) {
								errMsg += ": " + (tot.data.erroriTotaleCarrello[i].data as any).pacchetto;
							}
						}

						self.$bvToast.toast(errMsg, {
							variant: "danger",
							title: self.$i18n.t("msg.erroreGenerico").toString(),
							solid: true
						});

						// self.onAddComplemetare();
					}

					resolve("");
					return;
				}

				alert("Dati non congruenti per il prodotto complementare. Contattare Webins");

				self.onAddComplemetare();

				resolve("");
				return;
			}

			self.totali = tot.data;
			resolve(tot.data);
			//parent.calcolaPreventivo();
		});
	}

	validaDati() {
		var datiCompl = this.getDati(),
			nome = this.complementare.nomeProdotto,
			err = [];

		if (this.showDestinazione && !datiCompl.statoID) {
			var testoDest = this.getTestoCarrello(helpers.tipiTestoCarrello.Destinazione);
			if( testoDest.toLowerCase().includes("massimale" ))
				err.push("Massimale richiesto per il complementare " + nome);
			else
				err.push(testoDest + " richiesta per il complementare " + nome);
		}
		if (this.showInputNazionalita && !datiCompl.nazionalitaID) {
			err.push("Area di partenza richiesta per il complementare " + nome);
		}
		if (this.showInputImportViaggio ) {
			if( !this.importoViaggio || parseInt( this.importoViaggio as any) < 1)
			err.push("Importo viaggio richiesto per il complementare " + nome);
		}

		for (var i = 0; i < err.length; i++) this.$bvToast.toast(err[i], { variant: "danger", title: this.$i18n.t("msg.erroreGenerico").toString(), solid: true });

		return err.length == 0;
	}

	getDati(): ParametriCarrelloItem {
		var dati = {} as ParametriCarrelloItem;
		var currPCollegato = this.datiViaggio.prodottiCollegati.find(obj => obj.pacchettoID == this.complementare.pacchettoID);
		//if (this.showDestinazione) dati.statoID = currPCollegato.destinazioneID || this.defaultDestinazioneViaggio;
		if (this.showDestinazione) dati.statoID = currPCollegato.destinazioneID;
		else dati.statoID = this.defaultDestinazioneViaggio;

		if (this.showInputNazionalita) dati.nazionalitaID = currPCollegato.nazionalitaID || this.defaultNazionalitaViaggio;
		else dati.nazionalitaID = this.defaultNazionalitaViaggio;

		if (this.showInputImportViaggio) dati.importoViaggio = this.importoViaggio;
		else dati.importoViaggio = this.defaultImportoViaggio;

		return dati;
	}

	changeDestinazione() {
		var currPCollegato = this.datiViaggio.prodottiCollegati.find(obj => obj.pacchettoID == this.complementare.pacchettoID);
		if (this.addComplemetare) {
			currPCollegato.destinazioneID = (this.destinazioneID as any).value || "";
			var index = this.datiViaggio.prodottiCollegati.findIndex(obj => obj.pacchettoID == this.complementare.pacchettoID);
			this.datiViaggio.prodottiCollegati[index] = currPCollegato;
			this.setDatiViaggio(this.datiViaggio);
			this.calcolaTotali(true);
		}
	}

	changeImportoViaggio() {
		//var currPCollegato = this.datiViaggio.prodottiCollegati.find((obj) => obj.pacchettoID == this.complementare.pacchettoID);
		if (this.addComplemetare) {
			/*currPCollegato.importoViaggio = this.importoViaggio || 0;
			var index = this.datiViaggio.prodottiCollegati.findIndex((obj) => obj.pacchettoID == this.complementare.pacchettoID);
			this.datiViaggio.prodottiCollegati[index] = currPCollegato;*/

			this.datiViaggio.parametri.importoViaggio = this.importoViaggio;
			this.setDatiViaggio(this.datiViaggio);
			this.calcolaTotali(true);
		}
	}

	changeNazionalita() {
		var currPCollegato = this.datiViaggio.prodottiCollegati.find(obj => obj.pacchettoID == this.complementare.pacchettoID);
		if (this.addComplemetare) {
			currPCollegato.nazionalitaID = (this.nazionalita as any).value || "";
			var index = this.datiViaggio.prodottiCollegati.findIndex(obj => obj.pacchettoID == this.complementare.pacchettoID);
			this.datiViaggio.prodottiCollegati[index] = currPCollegato;
			this.setDatiViaggio(this.datiViaggio);
			this.calcolaTotali(true);
		}
	}

	onLeggiDiPiu() {
		var message = this.$createElement("div", {
			domProps: {
				innerHTML:
					"<div class='dettaglioComplementare'>" +
					this.complementare.descrizioneSintetica
					+
					"<br>" +
					"<a target='_blank' href='"+ window.location.origin + "/terminiecondizioni?pacchettoId=" + this.complementare.pacchettoID + 
					"&prodottoId=" + this.complementare.prodottoID + "'" + 
					"' >Termini e Condizioni</a>" +
					"<br>" +
					this.complementare.descrizioneEstesa +
					
					"</div>"
			}
		});

		var title = this.complementare.pacchetto;
		this.$bvModal.msgBoxOk([message], {
			title: title,
			okVariant: "",
			okTitle: "Prosegui con gli acquisti",
			cancelVariant: "outline-theme",
			footerClass: "d-none",
			headerBgVariant: "",
			hideHeaderClose: false,
			size: "lg"
			// centered: true
		});
	}


	getTestoCarrello(tipoID) {
		var t = (this.complementare ? this.complementare.testiCarrello || [] : []).find(obj => {
			return obj.tipoID == tipoID;
		});

		return t && t.testo ? t.testo : helpers.getTipoTestoCarrelloDefault(tipoID);
	}
}
