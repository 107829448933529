import { anagraficaPacchettiCategorie } from "@/models/carrello/categoria";
import { datiViaggio, totaliCarrello } from "@/models/carrello/datiViaggio";
import { MessaggiUtentiItem } from "@/models/carrello/messaggiUtenteitem";
import { MessaggiUtentiLetti } from "@/models/carrello/messaggiUtentiLetti";
import { Preferiti } from "@/models/carrello/preferiti";
import { associaDistributoriPacchettiExtended } from "@/models/carrello/prodotti";
import { RigaCarrelloInfo } from "@/models/carrello/rigaCarrello";
import { RispostaAdesioneMultipla } from "@/models/carrello/rispostaAdesioneMultipla";
import { operationResultStatus } from "@/models/operationResult";
import { TextValueItem } from "@/models/TextValueItem";
import StoricoImportPratiche from "@/pages/praticheMultiple/importaPratiche.vue";
import api from "@/services/api";
import { filterBy, FilterDescriptor } from "@progress/kendo-data-query";
import { xor } from "lodash";
import { Component, Prop, Watch } from "vue-property-decorator";
import { mapGetters, mapMutations, mapState } from "vuex";
import baseUserContextComponent from "./baseUserContextComponent";
import eventHub from "@/eventHub";
import apiFile from "@/services/apiFile";

const _ENDPONT_PRODOTTI = api.endpoint.CARRELLO.CONFRONTA_PRODOTTI;
const _ENDPONT_PREFERITI = api.endpoint.CARRELLO.PREFERITI;
const _ENDPONT_CARRELLO = api.endpoint.CARRELLO.CARRELLO;
const _ENDPONT_MESSAGGI_UTENTE = api.endpoint.CARRELLO.MESSAGGI_UTENTI;

@Component({
	computed: {
		...mapState({
			prodottiCarrello: (state: any) => state.prodottiCarrello,
			width: (state: any) => state.width
		})
	},
	methods: {
		...mapMutations(["setProdotti", "setCategoria", "setItemsSelected", "setShowPreferiti", "setProdottiCarrello", "setProductSearched", "resetProductSearched", "setDatiViaggio"]),
		...mapGetters(["getSearchedProduct", "getCategoria"])
	}
})
export default class carrelloNavBar extends StoricoImportPratiche {
	@Prop({ default: () => "" }) sezione: string;

	width!: number;
	setProductSearched!: (setProductSearched: TextValueItem[]) => void;
	resetProductSearched!: () => void;
	getSearchedProduct: () => TextValueItem[] | null;
	getCategoria: () => any;
	setDatiViaggio!: (datiViaggio: datiViaggio) => void;
	prodotti: TextValueItem[] = [];
	prodottiSearch: string = null;
	adesioneMultipla: RispostaAdesioneMultipla = null;

	intervalGetMessages: any = null;
	messaggiUtente: MessaggiUtentiItem[] = [];
	showDialogMessages: boolean = false;

	ddlFilter: any = null;

	get prodottiSearchFilter(): FilterDescriptor {
		var self = this;
		if (self.prodottiSearch == null || self.prodottiSearch.trim() == "") return null;
		else return { field: "text", ignoreCase: true, value: self.prodottiSearch, operator: "contains" } as FilterDescriptor;
	}
	get prodottiFiltrati() {
		var self = this;
		if (self.prodotti == null) return [];
		// if (self.prodottiSearch == null || self.prodottiSearch.trim() == '')
		// 	return this.prodotti;
		var self = this;
		const data = self.prodotti.slice();
		if (self.prodottiSearchFilter == null) return data;
		var filtered = filterBy(data, self.prodottiSearchFilter);
		return filtered;
	}
	isProductSelected(p: TextValueItem) {
		var self = this;
		if (self.prodottiSelected == null) return false;
		return self.prodottiSelected.find(x => x.value == p.value) != null;
	}
	toglleProductSelection(p: TextValueItem) {
		var self = this;
		if (self.prodottiSelected == null) self.prodottiSelected = [];
		if (self.prodottiSelected.find(x => x.value == p.value) == null) {
			if (self.prodottiSelected.length == 4) {
				self.$bvToast.toast("Non è possibile selezionare più di 4 prodotti per il confronto", {
					variant: "warning",
					title: "ATTENZIONE",
					solid: true
				});

				return;
			}

			self.prodottiSelected.push(p);
		} else {
			self.prodottiSelected = self.prodottiSelected.filter(x => x.value != p.value);
		}
	}
	prodottiSelected: TextValueItem[] = null;
	get prodottiSelezionatiCount(): number {
		var self = this;
		return self.prodottiSelected == null ? 0 : self.prodottiSelected.length;
	}
	showCartModal: boolean = false;
	showConfrontaModal: boolean = false;
	showCartModalAcquistato: boolean = false;
	@Watch("showConfrontaModal", { immediate: true })
	async onChangeShowConfrontaModal(oldValue, newValue) {
		this.prodottiSearch = null;
		//this.prodottiSelected = [];
	}
	// @Watch("showCartModalAcquistato", { immediate: true })
	@Watch("showCartModalAcquistato")
	onChangeShowCartModalAcquistato(oldValue, newValue) {
		var self = this;

		//if (self.showCartModalAcquistato == false) if (self.$router.currentRoute.name != "listaProdotti") self.$router.push({ name: "listaProdotti" });
		if (self.showCartModalAcquistato == false) if (self.$router.currentRoute.name != "carrelloCategorie") self.$router.push({ name: "carrelloCategorie" });
	}

	get logoPath() {
		return `${this.$appConfig.baseUrl}logo.png`;
		// return `${process.env.BASE_URL}logo.png`;
		//return   `${process.env.VUE_APP_BASE_URL}/logo.png`;
	}

	prodottiCarrello!: RigaCarrelloInfo[];
	setProdotti!: (prodotti: associaDistributoriPacchettiExtended[]) => void;
	setItemsSelected!: (prodotti: number[]) => void;
	setCategoria!: (categoria: anagraficaPacchettiCategorie) => void;
	setShowPreferiti!: (showPreferiti: boolean) => void;
	setProdottiCarrello!: (setProdottiCarrello: RigaCarrelloInfo[]) => void;

	totali: totaliCarrello = new totaliCarrello();

	@Watch("prodottiCarrello", { immediate: true })
	async onChangeProdottiCarrello() {
		this.calcolaTotale();
	}

	beforeDestroy() {
		this.$root.$off("acquista1Click");
		this.$root.$off("openCarrello");
		this.$root.$off("closeCarrello");
	}
	beforeMount() {}
	mounted() {
		var self = this;
		var multiselect = this.$refs.multiselect as any;
		if (multiselect != null && multiselect.$children != null && multiselect.$children.length != null) {
			multiselect.$children[0].input.onkeyup = e => {
				if (e.which == 13) {
					self.onRicercaProdottoMultiselect();
				}
			};
		}

		var sp = this.getSearchedProduct();
		if (sp && sp.length) {
			this.prodottiSelected = sp;
		}

		eventHub.$on(
			eventHub.events.RESET_PRODUCT_SEARCHED,
			() => self.onClearProductSearched()
		);

		this.$root.$on("openCarrello", () => {
			this.showCartModal = true;
		});

		this.$root.$on("acquista1Click", () => {
			this.creaAdesioniDaCarrello();
		});

		this.$root.$on("closeCarrello", () => {
			this.showCartModal = false;
		});
	}
	beforeCreate() {}
	async created() {
		var self = this;
		this.ddlFilter = null;
		this.adesioneMultipla = null;
		api.getDatiDropDown(api.endpoint.DROPDOWN.PACCHETTI_DISTRIBUTORI).then(res => {
			self.prodotti = res;
		});
		var prodottiCarrello = await api.getCarrello();

		this.setProdottiCarrello(prodottiCarrello);

		this.startIntervalGetMessages();
		this.getUnreadMessages();

		eventHub.$on(eventHub.events.CART_CHANGED, async () => {
			var prodottiCarrello = await api.getCarrello();
			self.setProdottiCarrello(prodottiCarrello);
		});
	}
	onChangeProductFiltered(value: TextValueItem[]) {
		if (value && value.length) {
			if (this.isAppMobile && this.prodottiSelected.length > 4) {
				this.$bvToast.toast("Non è possibile selezionare più di 4 prodotti per il confronto", {
					variant: "warning",
					title: "ATTENZIONE",
					solid: true
				});

				this.prodottiSelected = this.prodottiSelected.slice(0, this.prodottiSelected.length -1);

				return;
			}
		} else {
			this.prodottiSelected = [];
			this.resetProductSearched();
		}
	}

	onClearProductSearched() {
		this.prodottiSelected = [];
		this.resetProductSearched();
	}

	onRicercaProdottoMultiselect() {
		this.setDatiViaggio(new datiViaggio());
		this.$root.$emit("refreshParametriDaStore");
		this.confrontaProdotti();
	}
	confrontaProdotti() {
		var self = this;
		var arrayId = [];

		if (!this.prodottiSelected || !this.prodottiSelected.length) {
			this.$bvToast.toast("Selezionare almeno un prodotto", { variant: "warning", title: this.$i18n.t("generico.warning").toString(), solid: true, autoHideDelay: 1500 });
			return;
		}

		for (var i = 0; i < this.prodottiSelected.length; i++) {
			arrayId.push(this.prodottiSelected[i].value);
		}

		//this.showConfrontaModal = false;// chiudo l'eventuale apertura e contestualmente resetto i parametri di ricerca/filtrati

		this.setProductSearched(this.prodottiSelected);

		api.chimataPost<associaDistributoriPacchettiExtended[]>(`${_ENDPONT_PRODOTTI}`, {
			listIDs: arrayId,
			categoriaID: null
		}).then(res => {
			//self.prodottiSelected = [];
			self.setItemsSelected(arrayId);
			self.setProdotti(res.data);
			self.setCategoria(null);

			eventHub.$emit(eventHub.events.PRODUCT_SEARCHED);

			if (self.$router.currentRoute.name != "listaProdotti") this.$router.push({ name: "listaProdotti" });
			self.showConfrontaModal = false;
		});
	}

	async goToPreferiti() {
		this.setDatiViaggio(new datiViaggio());
		this.prodottiSelected = [];
		this.resetProductSearched();

		this.setProdotti([]);
		var categoria = new anagraficaPacchettiCategorie();
		categoria.categoria = "Prodotti Preferiti";
		categoria.descrizione = "preferiti";
		this.setCategoria(categoria);

		eventHub.$emit(eventHub.events.TOGGLE_PREFERITI);

		if (this.$router.currentRoute.name != "listaProdotti") this.$router.push({ name: "listaProdotti" });
	}
	async goToPathname(pathname: string) {
		this.setDatiViaggio(new datiViaggio());
		this.prodottiSelected = [];
		this.resetProductSearched();

		if (this.$router.currentRoute.name != pathname) this.$router.push({ name: pathname });
		//this.$router.push({ name: pathname });
	}

	calcolaTotale() {
		//fai somma di tutti i totali e mostrali su cntTotale
		var dati = this.prodottiCarrello,
			totComm = 0,
			tot = 0,
			totPagare = 0;

		for (var i = 0; i < dati.length; i++) {
			var infoTot = dati[i].datiCarrello.dati.infoTotali ? JSON.parse(atob(dati[i].datiCarrello.dati.infoTotali)) : {};
			if (!infoTot["tot"]) continue;

			tot += infoTot["tot"].totaleViaggio;
			totComm += infoTot["tot"].commissioni;
			totPagare += infoTot["tot"].totaleViaggioDaPagare;
		}

		this.totali = new totaliCarrello();
		this.totali.totaleViaggio = tot;
		this.totali.commissioni = totComm;
		this.totali.totaleViaggioDaPagare = totPagare;
	}

	creaAdesioniDaCarrello() {
		var self = this;

		if( self.acquistaPremuto )
			return;
		self.acquistaPremuto = true;

		this.$root.$emit('setForcedLoading', true); 


		self.onClearProductSearched();

		api.creaAdesioniDaCarrello()
			.then(res => {
				self.acquistaPremuto = false;
				self.$root.$emit('setForcedLoading', false); 
				if (res.status == operationResultStatus.ok) {
					debugger
					if( res.data && res.data.adesioni && res.data.adesioni.length){
						for( var i = 0; i < res.data.adesioni.length; i++){
							var c = res.data.adesioni[i];
							c.datiAdesione.inizioViaggio
							if( c.datiAdesione && c.datiAdesione.inizioViaggio){
								c.datiAdesione.inizioViaggio = c.datiAdesione.inizioViaggio.substring(0,10);
							}
							if( c.datiAdesione && c.datiAdesione.fineViaggio){
								c.datiAdesione.fineViaggio = c.datiAdesione.fineViaggio.substring(0,10);
							}
						}
					}
					self.adesioneMultipla = res.data;
					self.showCartModal = false;

					api.getCarrello().then(res => self.setProdottiCarrello(res));

					if (res.data.adesioniErrori && res.data.adesioniErrori.length) {
						var messages = [];

						for (var i = 0; i < res.data.adesioniErrori.length; i++) {
							var codice = res.data.adesioniErrori[i].codice;
							var errMsg = res.data.adesioniErrori[i].descrizione;

							if (codice) {
								errMsg = self.$i18n.t(codice).toString();
								if (res.data.adesioniErrori[i].data && (res.data.adesioniErrori[i].data as any).pacchetto) {
									errMsg += ": " + (res.data.adesioniErrori[i].data as any).pacchetto;
								}
							}

							messages.push(errMsg);
						}

						this.$bvModal
							.msgBoxOk(messages, {
								title: self.$i18n.t("generico.warning").toString(),
								okVariant: "info",
								headerBgVariant: "warning",
								okTitle: "ok",
								cancelVariant: "outline-theme"
							})
							.then(val => {
								if (res.data && res.data.redirecUrl && res.data.redirecUrl.length) {
									location.href = res.data.redirecUrl;
								} else {
									self.showCartModalAcquistato = true;
									api.getCarrello().then(res => self.setProdottiCarrello(res));
								}
							})
							.catch(err => {
								console.log(err);
								if (res.data && res.data.redirecUrl && res.data.redirecUrl.length) {
									location.href = res.data.redirecUrl;
								} else {
									self.showCartModalAcquistato = true;
									api.getCarrello().then(res => self.setProdottiCarrello(res));
								}
							});
					} else {
						//if (res.data && res.data.redirecUrl && res.data.redirecUrl.length) {
						// nuova gestione -> se ho redirect url, ma ho gia adesioni acquistate con credito, le mostro subito
						if (res.data && res.data.redirecUrl && res.data.redirecUrl.length && (!res.data.adesioni || !res.data.adesioni.length)) {
							location.href = res.data.redirecUrl;
						} else {
							self.showCartModalAcquistato = true;
							//api.get(_ENDPONT_CARRELLO).then(res => self.setProdottiCarrello(res));
						}
					}
				}
			})
			.catch(err => {
				self.acquistaPremuto = false;
				self.$root.$emit('setForcedLoading', false); 
				console.log(err);
			});
	}

	svuotaCarrello(){
		if( !confirm("Svuotare il carrello?"))
			return;
		var self = this;
		api.chimataPost(_ENDPONT_CARRELLO + "/svuotacarrello", {})
		.then(res => {
			api.getCarrello().then(res => self.setProdottiCarrello(res));
		})
		.catch(err => {
			console.log(err);
		});
	}
	
	startIntervalGetMessages() {
		var self = this;
		clearInterval(this.intervalGetMessages);

		this.intervalGetMessages = setInterval(function() {
			self.getUnreadMessages();
		}, 30000);
	}

	getUnreadMessages() {
		var self = this;
		api.getBackground(_ENDPONT_MESSAGGI_UTENTE)
			.then((res: MessaggiUtentiItem[]) => {
				self.messaggiUtente = res;
			})
			.catch(err => {
				console.log(err);
			});
	}

	setMessagesRead() {
		if (this.messaggiUtente && this.messaggiUtente.length > 0) {
			var data = new MessaggiUtentiLetti();

			var messagesNotRead = this.messaggiUtente.filter(function(x) {
				return !x.hasBeenRead;
			});

			if (messagesNotRead && messagesNotRead.length > 0) {
				data.itemIDs = messagesNotRead.map(x => x.itemID);

				api.chimataPost(_ENDPONT_MESSAGGI_UTENTE, data)
					.then(res => {
						//do nothing
					})
					.catch(err => {
						console.log(err);
					});
			}
		}
	}

	openDialogMessage() {
		if (this.showDialogMessages == false) {
			clearInterval(this.intervalGetMessages);

			this.showDialogMessages = true;

			this.setMessagesRead();
		}
	}

	onCloseDialogMessage() {
		this.showDialogMessages = false;
		this.getUnreadMessages();

		this.startIntervalGetMessages();
	}

	get dropdownSource() {
		var self = this;
		const data = self.modelli.slice();
		if (self.ddlFilter == null) return data;
		var filtered = filterBy(data, this.ddlFilter);
		return filtered;
	}
	onFilterSelectChange(event: any) {
		this.ddlFilter = event.filter;
	}

	get messageCount() {
		if (this.messaggiUtente && this.messaggiUtente.length > 0) {
			return this.messaggiUtente.length;
		}

		return 0;
	}

	get messageNotReadCount() {
		if (this.messaggiUtente && this.messaggiUtente.length > 0) {
			var messagesNotRead = this.messaggiUtente.filter(function(x) {
				return !x.hasBeenRead;
			});

			return messagesNotRead.length;
		}

		return 0;
	}

	downloadGenericModel() {
		var self = this;
		var nomeFile = "Modello nominativi divisi per riga";

		apiFile
			.downloadFileModelloPraticheMultiple("Excel", nomeFile)
			.then(response => {
				const blob = new Blob([response.data], { type: "application/xlsx" });
				const link = document.createElement("a");
				link.href = URL.createObjectURL(blob);
				link.download = nomeFile + ".xls";
				link.click();
				URL.revokeObjectURL(link.href);
			})
			.catch(err => {
				console.log(err);
				var msg = this.$i18n.t("errore.fileDaScaricareNonTrovato").toString();
				self.$bvToast.toast(msg, {
					variant: "danger",
					title: self.$i18n.t("msg.erroreGenerico").toString(),
					solid: true
				});
			});
	}

	downloadModelPartecipantsSameRow() {
		var self = this;
		var nomeFile = "Modello nominativi su unica riga";

		apiFile
			.downloadFileModelloPraticheMultiple("Excel", nomeFile)
			.then(response => {
				const blob = new Blob([response.data], { type: "application/xlsx" });
				const link = document.createElement("a");
				link.href = URL.createObjectURL(blob);
				link.download = nomeFile + ".xls";
				link.click();
				URL.revokeObjectURL(link.href);
			})
			.catch(err => {
				console.log(err);
				var msg = this.$i18n.t("errore.fileDaScaricareNonTrovato").toString();
				self.$bvToast.toast(msg, {
					variant: "danger",
					title: self.$i18n.t("msg.erroreGenerico").toString(),
					solid: true
				});
			});
	}

	closeModalAcquisto() {
		if (this.adesioneMultipla && this.adesioneMultipla.redirecUrl) {
			if (confirm("Attenzione!! Alcune polizze devono essere ancora pagate. Annullare il pagamento?")) this.showCartModalAcquistato = !this.showCartModalAcquistato;
		} else this.showCartModalAcquistato = !this.showCartModalAcquistato;
	}

	get isActiveMenuCategorie() {
		if (this.$route.path.indexOf("carrelloCategorie") >= 0) {
			return "sub-menu-primary-active";
		}

		return "";
	}

	get isActiveMenuPreferiti() {
		if (this.$route.path.indexOf("listaProdotti") >= 0 && this.getCategoria() && this.getCategoria().categoria == "Prodotti Preferiti") {
			return "sub-menu-primary-active";
		}

		return "";
	}

	get totalePremioAcquistato() {
		if (this.adesioneMultipla && this.adesioneMultipla.adesioni && this.adesioneMultipla.adesioni.length) {
			let values = this.adesioneMultipla.adesioni.map(function(item) {
				return item.datiAdesione ? item.datiAdesione.totalePremio : 0;
			});

			var result = this._.sum(values);
			return result;
		}

		return 0;
	}

	get totaleCommissioniAcquistato() {
		if (this.adesioneMultipla && this.adesioneMultipla.adesioni && this.adesioneMultipla.adesioni.length) {
			let values = this.adesioneMultipla.adesioni.map(function(item) {
				return item.datiAdesione ? item.datiAdesione.commissioni : 0;
			});

			var result = this._.sum(values);
			return result;
		}

		return 0;
	}

	get totaleDaPagareAcquistato() {
		if (this.adesioneMultipla && this.adesioneMultipla.adesioni && this.adesioneMultipla.adesioni.length) {
			let values = this.adesioneMultipla.adesioni.map(function(item) {
				return item.datiAdesione ? item.datiAdesione.totalePremioDaPagare : 0;
			});

			var result = this._.sum(values);
			return result;
		}

		return 0;
	}

	get hideSubMenuMobile() {
		return this.$route.name != "carrelloCategorie";
	}
}
