import { ElencoAdesioni } from "./elencoAdesioni";

export class ModificaOperazioneItem {

	
	constructor() {
		this.adesioneModificata = new ElencoAdesioni();
		
	}

	adesioneModificata: ElencoAdesioni;
	richiestaModifica: boolean | null;
	chNomiFlagWarning: boolean | null;
	chNomiCausali: string;
	chDateFlagWarning: boolean | null;
	chDateCausale: string;
	chImportoViaggioFlagWarning: boolean | null;
	chImportoViaggioCausale: string;
	chNumeroAssicuratiFlagWarning: boolean | null;
	chNumeroAssicuratiCausale: string;
}