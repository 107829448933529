import { datiViaggio } from "@/models/carrello/datiViaggio";
import { Component, Prop } from "vue-property-decorator";
import { mapState } from "vuex";
import baseUserContextComponent from "./baseUserContextComponent";

@Component({
	computed: {
		...mapState({		
			datiViaggio: (state: any) => state.datiViaggio,
		})
	},
})
export default class infoProdottoPreventivo extends baseUserContextComponent {
	@Prop({ default: () => 0 })
	totaleComplessivo: number;
	datiViaggio!: datiViaggio;

	get textTotale() {
		//return this.datiViaggio && this.datiViaggio.parametri && this.datiViaggio.parametri.numeroAlloggi ? "totale per alloggio" :
				//"totale per persona";
		return "totale per persona";
	}
}
