import gridComponentBase from "../../components/grid/gridComponentBase.vue";
import { Component, Prop } from "vue-property-decorator";
import moment from "moment";
import api from "@/services/api";

const _ENDPONT = api.endpoint.CARRELLO.ELENCO_MODIFICHE_PRATICA;

@Component
export default class ElencoModifichePraticaPage extends gridComponentBase {

	@Prop({ default: () => 0 })
	praticaID: number;

	kendop: any = kendo;

	get columns(): Array<any> {
		return [
			{ field: "itemID", title: "ID", width: 20, filterable: false, hidden: true, cell: "defaultCellTemplate", headerCell: "myHeaderCellTemplate", editHide: true },

			{ field: "tipoModifica", title: "Tipo Modifica", headerCell: "myHeaderCellTemplate", filterable: true },
			{ field: "valoreOriginale", title: "Valore Originale", headerCell: "myHeaderCellTemplate", filterable: true },
			{ field: "valoreModificato", title: "Valore Modificato", headerCell: "myHeaderCellTemplate", filterable: true },
			{ field: "flagWarning", title: "Flag Warning", headerCell: "myHeaderCellTemplate", filterable: true, type: "boolean", filterType:"boolean", disabled: true  },
			{ field: "causale", title: "Causale", headerCell: "myHeaderCellTemplate", filterable: true },
			{ field: "richiestaModifica", title: "Richiesta Modifica", headerCell: "myHeaderCellTemplate", filterable: true, type: "boolean", filterType:"boolean" , disabled: true },
			{ field: "modificaEffettuala", title: "Modifica Effettuata", headerCell: "myHeaderCellTemplate", filterable: true, type: "boolean",  filterType:"boolean", disabled: true  },
			{ field: "modificaRifiutata", title: "Modifica Rifiutata", headerCell: "myHeaderCellTemplate", filterable: true, type: "boolean",  filterType:"boolean", disabled: true },
			{ field: "dataModifica", title: "Data Modifica", headerCell: "myHeaderCellTemplate", filterable: true, type: "datetime", filterType:"daterange" },
			{ field: "dataOperazione", title: "Data Operazione", headerCell: "myHeaderCellTemplate", filterable: true , type: "datetime", filterType:"daterange"},
		];
	}

	created() {
		this.getData();
	}

	getData() {
		this.getGridData(`${_ENDPONT}/${this.praticaID}`);
	}

}
