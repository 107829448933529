import baseContextComponent from "@/components/baseContextComponent";
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component({})
export default class CarrelloLayout extends baseContextComponent {
	// test() {

	// 	var elem = this.$refs['container'];
	// 	// (elem as any).scrollIntoView({ behavior: 'smooth' })
	// 	(elem as any).scrollTo(0, 0);
	// 	//document.getElementById('carello-layout-container').scrollIntoView({ behavior: 'smooth', block: "start" });
	// 	// document.getElementById("carello-layout-container").scrollTop = 0;
	// }
	get fixedFooter(): boolean {
		return this.$appConfig.footer.fixed == null ? true : this.$appConfig.footer.fixed;
	}
}
