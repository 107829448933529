import helpers from "@/helpers";
import { datiViaggio } from "@/models/carrello/datiViaggio";
import { CarrelloInfoProdotto } from "@/models/carrello/infoProdotto";
import { Preferiti } from "@/models/carrello/preferiti";
import { associaDistributoriPacchettiExtended, gruppoSchedaProdottoParametri, schedaProdottoParametri } from "@/models/carrello/prodotti";
import api from "@/services/api";
import { Component, Prop } from "vue-property-decorator";
import { mapMutations, mapState } from "vuex";
import baseUserContextComponent from "./baseUserContextComponent";
import Vue from "vue";

const _ENDPONT = api.endpoint.CARRELLO.PREFERITI,
	_ENDPONT_INFO_PRODOTTO = api.endpoint.CARRELLO.INFO_PRODOTTO;
@Component({
	computed: {
		...mapState({
			datiViaggio: (state: any) => state.datiViaggio,
		})
	},
	methods: {
		...mapMutations(["setProdottoPreferito", "setItemsSelected", "setProdottoSelezionato", "setDatiViaggio"])
	}
})
export default class prodottoCarrelloCard extends baseUserContextComponent {
	@Prop({ default: () => null }) prodotto: associaDistributoriPacchettiExtended;
	@Prop({ default: () => [] }) parametri: gruppoSchedaProdottoParametri[];


	datiViaggio!: datiViaggio;
	showDatail: boolean[] = [];
	showGaranzie: boolean = false;

	setProdottoPreferito!: (prodottoID: number) => void;
	setItemsSelected!: (itemsSelected: number[]) => void;
	setProdottoSelezionato!: (prodotto: CarrelloInfoProdotto) => void;
	setDatiViaggio!: (datiViaggio: datiViaggio) => void;

	beforeDestroy() { }
	beforeMount() { }
	mounted() { }
	beforeCreate() { }
	created() {
		for (let index = 0; index < this.parametri.length; index++) {
			Vue.set(this.showDatail, index, false);
		}
	}
	
	setShowDetail(index, value) {
		Vue.set(this.showDatail, index, value);
	}

	async aggiungiPreferito() {
		var preferito = new Preferiti();
		preferito.itemIDPacchetto = this.prodotto.itemID;

		var res = await api.chimataPost(_ENDPONT, preferito);
		if (res.status == 'ok') {
			this.setProdottoPreferito(this.prodotto.itemID);
		}
	}

	async onSelezionaProdotto() {
		var self = this;
		var id = this.prodotto.pacchettoID;

		var res = await api.get(`${_ENDPONT_INFO_PRODOTTO}/${id}`);

		this.setProdottoSelezionato(res);

		var d = new datiViaggio();
		d.pacchettoID = id;
		d.parametri = this.datiViaggio.parametri;

		this.setDatiViaggio(d);

		this.$router.push({ path: 'infoProdotto' as string });
	}

	async getTerminiECondizioni(prodotto) {
		var self = this;

		
		helpers.getTerminiECondizioni(this,prodotto.pacchettoID, prodotto.prodottoID);

		/*api.getWithAllDataResponse(`${api.endpoint.CARRELLO.TERMINI_E_CONDIZIONI}?pacchettoId=${prodotto.pacchettoID}&prodottoId=${prodotto.prodottoID}`, { responseType: "blob" })
		.then(res => {
			const blob = new Blob([res.data], { type: res.headers['content-type'] });

			if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
                (window.navigator as any).msSaveOrOpenBlob(blob);
            } else {
                var fileURL = URL.createObjectURL(blob);
                window.open(fileURL, '_blank');
            }
		}).catch(err => {
			self.$bvToast.toast(self.$i18n.t("errore.fileNotFound").toString(), {
				variant: "danger",
				title: self.$i18n.t("generico.errore").toString(),
				solid: true
			});
		});*/
	}
}
