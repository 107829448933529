import gridComponentBase from "@/components/grid/gridComponentBase.vue";
import eventHub from "@/eventHub";
import ImportPraticheResult from "@/models/praticheMultiple/importPraticheResult";
import api from "@/services/api";
import { Component } from "vue-property-decorator";
import { TextValueItem } from "../../models/TextValueItem";

const _ENDPONT_IMPORT_PRATICHE_MULTIPLE = api.endpoint.CARRELLO.IMPORT_PRATICHE_MULTIPLE;
@Component({})
export default class StoricoImportPratiche extends gridComponentBase {
	showEditModal: boolean = false;
	showImportPraticheResult: boolean = false;
	filePratiche: any = null;
	modelSelected: TextValueItem = null;

	modelli: TextValueItem[] = [];

	importPraticheResult: ImportPraticheResult = null;
	//importPraticheResult: ImportPraticheResult = JSON.parse(`{"messaggio":"","esitoControllo":{"tipoEsecuzione":2,"totalePratiche":0,"praticheConErrori":[{"rigaExcel":2,"riferimentoPratica":"21/xxxxxx - Verificare configurazione prodotto e Importo viaggio","contraente":"TEST TEST"},{"rigaExcel":2,"riferimentoPratica":"21/xxxxxx - Verificare configurazione prodotto e Importo viaggio","contraente":"TEST TEST"},{"rigaExcel":2,"riferimentoPratica":"21/xxxxxx - Verificare configurazione prodotto e Importo viaggio","contraente":"TEST TEST"},{"rigaExcel":2,"riferimentoPratica":"21/xxxxxx - Verificare configurazione prodotto e Importo viaggio","contraente":"TEST TEST"},{"rigaExcel":2,"riferimentoPratica":"21/xxxxxx - Verificare configurazione prodotto e Importo viaggio","contraente":"TEST TEST"},{"rigaExcel":2,"riferimentoPratica":"21/xxxxxx - Verificare configurazione prodotto e Importo viaggio","contraente":"TEST TEST"},{"rigaExcel":2,"riferimentoPratica":"21/xxxxxx - Verificare configurazione prodotto e Importo viaggio","contraente":"TEST TEST"},{"rigaExcel":2,"riferimentoPratica":"21/xxxxxx - Verificare configurazione prodotto e Importo viaggio","contraente":"TEST TEST"}]}}`);

	get columns(): Array<any> {
		return [
			{ field: "esito", title: this.$i18n.t("generico.esito").toString(), filterable: false, hidden: false, headerCell: "myHeaderCellTemplate", editHide: true, width: 100 },
			{ field: "dataElaborazione", title: this.$i18n.t("generico.dataImportazione").toString(), headerCell: "myHeaderCellTemplate" }
		];
	}

	created() {
		var self = this;
		api.getTemplatePraticheMultiple()
			.then(res => {
				self.modelli = res;
			})
			.catch(err => {
				console.log(err);
			});
	}

	importFromExcel() {
		this.showEditModal = !this.showEditModal;
	}

	onInputFileValue(value: File) {
		var self = this;
		this.filePratiche = value;

		if (!value) return;
	}

	onChangeDropdownValue(obj: any) {
		var self = this;
		var value = ((obj || {}).target || {}).value;

		if (value) this.modelSelected = value;
		else this.modelSelected = null;
	}

	onImportPratiche() {
		var self = this;

		var formData = new FormData();
		formData.append("filePratiche", this.filePratiche);
		formData.append("modelId", this.modelSelected.value.toString());

		this.$root.$emit('setForcedLoading', true);
		api.importPraticheFormData(_ENDPONT_IMPORT_PRATICHE_MULTIPLE, formData).then(res => {
			self.$root.$emit('setForcedLoading', false); 
			self.showEditModal = false;
			self.modelSelected = null;

			self.importPraticheResult = res;
			self.showImportPraticheResult = true;

			eventHub.$emit(eventHub.events.CART_CHANGED);

			setTimeout(function() {
				self.filePratiche = null;
			}, 3000);
		})
		.catch(err => {
			self.$root.$emit('setForcedLoading', false); 
			console.log(err);
		});
	}

	get tipoEsecuzione() {
		if (this.importPraticheResult && this.importPraticheResult.esitoControllo && this.importPraticheResult.esitoControllo.tipoEsecuzione) {
			return this.importPraticheResult.esitoControllo.tipoEsecuzione;
		}

		return "-----";
	}
	get totalePratiche() {
		if (this.importPraticheResult && this.importPraticheResult.esitoControllo && this.importPraticheResult.esitoControllo.totalePratiche) {
			return this.importPraticheResult.esitoControllo.totalePratiche;
		}

		return 0;
	}
	get praticheConErrori() {
		if (this.importPraticheResult && this.importPraticheResult.esitoControllo && this.importPraticheResult.esitoControllo.praticheConErrori && this.importPraticheResult.esitoControllo.praticheConErrori.length) {
			return this.importPraticheResult.esitoControllo.praticheConErrori;
		}

		return [];
	}

	clickOkRiepilogoImportPratiche(){
		this.showImportPraticheResult = !this.showImportPraticheResult;
		var tipoEs = this.tipoEsecuzione;
		if( tipoEs === 1){
			this.showCartModal = true;
		}
	}
}
