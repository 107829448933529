import { AttributiUtente } from "@/config/permessi";


export class UserLoginModel {
	constructor() {
		this.username = "";
		this.password = "";
	}
	public username: string;
	public password: string;
}

export class RecuperaPasswordModel {

	constructor() {
		this.username = "";
		this.isWeb = true;
	}
	username: string;
	isWeb: boolean;
}

export class UserLoggedInfoModel {
	constructor() {
		this.id = null;
		this.anagraficaID = null;
		this.firstName = "";
		this.lastName = "";
		this.defaultLanguage = "";
		this.userName = "";
		this.email = "";
		this.permessi = [];

		this.distributoreID = null;
		this.distributore = "";
		this.fornitoreID = null;
		this.fornitore = "";

		this.backOffice = false;
		this.web = true;

		this.attributiUtente = [];

		this.impersonatorFullName = "";
		this.hasImpersonateUser = false;
		this.mustPay = false;
		this.segnalazionePagamentoFineMese = false;
	}
	public id: string;
	public anagraficaID: number;
	public firstName: string;
	public lastName: string;
	public defaultLanguage: string;
	public userName: string;
	public email: string;
	public permessi: string[];
	public distributoreID: number;
	public distributore: string;
	public fornitoreID: number;
	public fornitore: string;

	public backOffice: boolean;
	public web: boolean;

	public attributiUtente: AttributiUtente[];

	public impersonatorFullName: string;
	public hasImpersonateUser: boolean;

	public mustPay: boolean;
	public segnalazionePagamentoFineMese: boolean;
}

export class UserMeInfo {
	userInfo: UserLoggedInfoModel;
	redirectTo: string;
	motivo: string;
}


export class ChangePasswordModel {
	constructor() {
		this.passwordNuova = "";
		this.passwordConferma = "";
		this.token = "";
	}
	passwordNuova: string;
	passwordConferma: string;
	token: string
}

export class CambioPasswordModel {
	constructor() {
		this.passwordVecchia = "";
		this.passwordNuova = "";
		this.passwordConferma = "";
	}
	passwordVecchia: string;
	passwordNuova: string;
	passwordConferma: string;
}

export class ForzaCambioPasswordModel {
	constructor() {
		this.username = "";
		this.passwordNuova = "";
		this.passwordConferma = "";
		this.inviaEmail = false;
	}
	username: string;
	passwordNuova: string;
	passwordConferma: string;
	inviaEmail: boolean;
}