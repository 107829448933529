// import Vue from 'vue'
import baseContextComponent from "@/components/baseContextComponent";
import eventHub from "@/eventHub";
import helpers from "@/helpers";
import { datiViaggio } from "@/models/carrello/datiViaggio";
import { CarrelloInfoProdotto } from "@/models/carrello/infoProdotto";
import { PopupCarrelloItem } from "@/models/popupCarrello";
import api from "@/services/api";
import { runInThisContext } from "vm";
import { Component, Watch } from "vue-property-decorator";
import { mapState } from "vuex";

@Component({
	computed: {
		...mapState({
			datiViaggio: (state: any) => state.datiViaggio,
			prodottoSelezionato: (state: any) => state.prodottoSelezionato
		})
	}
})
export default class popupCarelloModale extends baseContextComponent {
	nonMostrarePiu: boolean = false;
	prodottoSelezionato!: CarrelloInfoProdotto;
	datiViaggio!: datiViaggio;
	@Watch("$route", { deep: true })
	onRouteChange(oldValue, newValue) {
		var self = this;

		if ((oldValue || {}).name != (newValue || {}).name && self.$route != null) {
			self.loadPopUp();
		}
	}
	toOpen: PopupCarrelloItem[] = [];
	// gestisto l'apertura di un messaggio alla volta (non un array e n modali una sopra l'altra)
	current: PopupCarrelloItem = null;
	showModal = false;
	// gestendo l'apertura di un solo messaggio per volta
	@Watch("showModal")
	onShowModalChange(oldValue, newValue) {
		var self = this;
		if (self.showModal == false) {
			self.tryOpen();
		}
		// ogni volta che apro o chiudo (chiudere non serve) lo imposto a false
		self.nonMostrarePiu = false;
	}
	messaggi: PopupCarrelloItem[] = [];
	get messaggiPopup(): PopupCarrelloItem[] {
		return (this.messaggi || []).filter(x => x.modalitaVisualizzazione === helpers.modalitaVisualizzazioniPopup.popup);
	}

	get link(): string {
		if (this.current && this.current.link && this.current.link.length > 0) {
			return this.current.link;
		}

		return null;
	}

	get title(): string {
		var self = this;
		return (self.current ?? {}).titolo ?? "";
	}
	get pathFile(): string {
		var self = this;
		return (self.current ?? {}).pathFile ?? null;
	}
	get descrizione(): string {
		var self = this;
		return (self.current ?? {}).descrizione ?? null;
	}
	get tipoVisualizzazione(): string {
		var self = this;
		var tv = (self.current ?? {}).tipoVisualizzazione ?? null;
		if (tv == 1) return "non_mostrare_piu";
		if (tv == 2) return "conferma_presa_visione";
		if (tv == 3) return "mostra_sempre";
		return "";
	}
	get titleSecondary(): string {
		return "";
	}
	get subTitle(): string {
		return "";
	}
	created() {
		// this.getData();
		var self = this;
		api.getPopupCarrello().then(res => {
			this.messaggi = res || [];
			self.loadPopUp();
		});
		eventHub.$on(eventHub.events.USER_INFO_UPDATED, () => api.getPopupCarrello().then(res => (this.messaggi = res || [])));
	}
	mounted() {}
	tryOpen() {
		var self = this;
		if (self.toOpen != null && self.toOpen.length > 0) self.current = self.toOpen.pop();
		else self.current = null;

		if (self.current != null) self.showModal = true;
	}
	loadPopUp() {
		var self = this;
		if (self.messaggiPopup == null || self.messaggiPopup.length == 0) return;
		var pathname = (self.$route || {}).name;
		if (pathname == "home") self.loadLogin();
		//if (pathname == 'carrelloHome')
		if (pathname == "carrelloCategorie" || pathname == "preventivatore") self.getCarrelloHome();
		if (pathname == "infoProdotto") self.getInfoProdotto();
		if (pathname && pathname.startsWith("myoverins")) self.getMyoverins();
		self.tryOpen();
	}
	loadLogin() {
		var self = this;
		self.toOpen = self.messaggiPopup.filter(x => x.posizioneApertura === helpers.posizioniPopup.login).splice(0);
	}
	getCarrelloHome() {
		var self = this;
		self.toOpen = self.messaggiPopup.filter(x => x.posizioneApertura === helpers.posizioniPopup.carrello).splice(0);
	}
	getInfoProdotto() {
		var self = this;
		if (self.prodottoSelezionato == null) self.toOpen = [];
		else
			self.toOpen = self.messaggiPopup
				.filter(
					x => (x.posizioneApertura === helpers.posizioniPopup.sceltaProdotto && x.idProdotto === self.prodottoSelezionato.pacchettoID) || x.idPolizza === self.prodottoSelezionato.prodottoID
				)
				.splice(0);
	}
	getMyoverins() {
		var self = this;
		self.toOpen = self.messaggiPopup.filter(x => x.posizioneApertura === helpers.posizioniPopup.myOverins).splice(0);
	}
	onConfermaPresaVisione() {
		var self = this;
		api.confermaPresaVisione(self.current.itemID, self.current.versione).finally(() => {
			api.reloadPopupCarrello().then(res => {
				self.messaggi = res || [];
				self.showModal = false;
			});
		});
	}
	onNonMostrarePiu() {
		var self = this;
		if (self.nonMostrarePiu) {
			api.nonMostrarePiu(self.current.itemID, self.current.versione).finally(() => {
				api.reloadPopupCarrello().then(res => {
					self.messaggi = res || [];
					self.showModal = false;
				});
			});
		} else {
			self.showModal = false;
		}
	}
}
