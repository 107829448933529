// import Vue from 'vue'
import baseContextComponent from "@/components/baseContextComponent";
import { Component, Prop } from "vue-property-decorator";

@Component({})
export default class checkCategoriaParametri extends baseContextComponent {
	@Prop({ default: () => null }) parametri: any;
	@Prop({ default: () => null }) prodotto: any;

	created() {}

	mounted() {}

	get checked() {
		var result = false;
		this.prodotto.parametri.forEach(parametro => {
			if (parametro.categoria == this.parametri.categoria && parametro.valore && (parametro.tipo !== 1 ||  parametro.valore !== "0" )) {
				result = true;
			}
		});
		return result;
	}
}
