import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import baseContextComponent from "./baseContextComponent";
import { Filter } from "@/models/grid/filter";

@Component({})
export default class FilterMobile extends baseContextComponent {
	@Prop({ default: () => [] }) columns: any[];
	@Prop({ default: () => false }) showfilterDialog: boolean;
	@Prop({ default: () => [] }) itemsForFilter: [];

	filtersMobile: Filter = {
		filters: []
	};

	indexUpdateComponent: number = 0;

	stateFilters: any = {};

	filterTextCompareOptions: any[] = [
		{ id: "contains", name: this.$i18n.t("gridfilter.contains").toString() },
		{ id: "eq", name: this.$i18n.t("gridfilter.eq").toString() },
		{ id: "neq", name: this.$i18n.t("gridfilter.neq").toString() },
		{ id: "startswith", name: this.$i18n.t("gridfilter.startswith").toString() },
		{ id: "doesnotcontain", name: this.$i18n.t("gridfilter.doesnotcontain").toString() },
		{ id: "endswith", name: this.$i18n.t("gridfilter.endswith").toString() }
	];
	filterNumberCompareOptions: any[] = [
		{ id: "eq", name: this.$i18n.t("gridfilter.eq").toString() },
		{ id: "neq", name: this.$i18n.t("gridfilter.neq").toString() },
		{ id: "gte", name: this.$i18n.t("gridfilter.gte").toString() },
		{ id: "gt", name: this.$i18n.t("gridfilter.gt").toString() },
		{ id: "lte", name: this.$i18n.t("gridfilter.lte").toString() },
		{ id: "lt", name: this.$i18n.t("gridfilter.lt").toString() }
	];
	filterSelectCompareOptions: any[] = [
		{ id: "eq", name: this.$i18n.t("gridfilter.eq").toString() },
		{ id: "neq", name: this.$i18n.t("gridfilter.neq").toString() }
	];
	filterDateCompareOptions: any[] = [
		{ id: "gt", name: this.$i18n.t("gridfilter.dategt").toString() },
		{ id: "lt", name: this.$i18n.t("gridfilter.datelt").toString() }
		// { id: "gte", name: this.$i18n.t("gridfilter.dategte").toString() },
		// { id: "lte", name: this.$i18n.t("gridfilter.datelte").toString() }
	];

	created() {
		this.setStateFilters(this.columns);
	}

	@Watch("columns", { immediate: true })
	onColumnsChange(newValue: any, oldValue: string) {
		this.setStateFilters(newValue);
	}

	setStateFilters(columns: any[]) {
		for (var i = 0; i < columns.length; i++) {
			var col = columns[i];

			if (col.filterable == undefined || col.filterable) {
				if (col.headerType == "date" || col.headerType == "daterange") {
					Vue.set(this.stateFilters, `${col.field}_1`, {
						field: col.field,
						operator: this.filterDateCompareOptions[0],
						value: null
					});
					Vue.set(this.stateFilters, `${col.field}_2`, {
						field: col.field,
						operator: {
							id: null,
							text: null
						},
						value: null
					});
				} else if (col.headerType == "numeric" || col.headerType == "numeric-int") {
					Vue.set(this.stateFilters, col.field, {
						field: col.field,
						operator: this.filterNumberCompareOptions[0],
						value: null
					});
				} else if (col.headerType == "select") {
					Vue.set(this.stateFilters, col.field, {
						field: col.field,
						operator: this.filterSelectCompareOptions[0],
						value: null
					});
				} else {
					Vue.set(this.stateFilters, col.field, {
						field: col.field,
						operator: this.filterTextCompareOptions[0],
						value: null
					});
				}
				// this.stateFilters[col.field] = {
				// 	field: col.field,
				// 	operator: {
				// 		id: 'eq',
				// 		text: 'uguale a'
				// 	},
				// 	value: null
				// };
			}
		}

		this.indexUpdateComponent++;
	}

	changeOperator(event, field): void {
		this.indexUpdateComponent++;
		if (this.filtersMobile && this.filtersMobile.filters) {
			var filter = this.filtersMobile.filters.find(item => {
				return item.field == field;
			});

			if (filter) {
				filter.operator = event.value.id;
			} else {
				this.filtersMobile.filters.push({
					field: field,
					operator: event.value.id
				} as any);
			}
		}
	}

	changeValue(event, field): void {
		if (this.filtersMobile && this.filtersMobile.filters) {
			var filter = this.filtersMobile.filters.find(item => {
				return item.field == field;
			});

			if (filter) {
				filter.value = event;
			} else {
				this.filtersMobile.filters.push({
					field: field,
					value: event
				} as any);
			}
		}
	}

	changeValueNumeric(event, field): void {
		if (this.filtersMobile && this.filtersMobile.filters) {
			var filter = this.filtersMobile.filters.find(item => {
				return item.field == field;
			});

			if (filter) {
				filter.value = parseFloat(event);
			} else {
				this.filtersMobile.filters.push({
					field: field,
					value: parseFloat(event)
				} as any);
			}
		}
	}

	onChangeDate(event, field) {
		var value = ((event || {}).sender || {}).value();

		if (this.filtersMobile && this.filtersMobile.filters) {
			var filter = this.filtersMobile.filters.find(item => {
				return item.field == field;
			});

			if (filter) {
				filter.value = value;
			} else {
				this.filtersMobile.filters.push({
					field: field,
					value: value
				} as any);
			}
		}
	}

	onApplyFilters() {
		this.filtersMobile.filters = [];
		for (var i = 0; i < Object.keys(this.stateFilters).length; i++) {
			var key = Object.keys(this.stateFilters)[i];
			var flt = this.stateFilters[key];

			if (key.indexOf('_1') >= 0) key = key.replace('_1', '');
			if (key.indexOf('_2') >= 0) key = key.replace('_2', '');

			if ((flt.value || (flt.value != "" && flt.value == 0) || flt.value == "0") && flt.operator && flt.operator.id) {
				var col = this.columns.find(item => item.field == key);
				var value = flt.value;
				
				if (typeof value === 'object') {
					value = value.value;
				}

				if (!isNaN(value) && col && (col.headerType == "numeric" || col.headerType == "numeric-int")) {
					this.filtersMobile.filters.push({
						field: key,
						value: parseFloat(value),
						operator: flt.operator.id
					} as any);
				} else {
					this.filtersMobile.filters.push({
						field: key,
						value: value,
						operator: flt.operator.id
					} as any);
				}
			}
		}

		//this.showfilterDialog = false;
		this.$emit("filter-mobile-apply", this.filtersMobile);
	}

	onRemoveAllFilters() {
		//this.showfilterDialog = false;

		this.filtersMobile.filters = [];

		// for (var i = 0; i < Object.keys(this.stateFilters).length; i++) {
		// 	var st = this.stateFilters[Object.keys(this.stateFilters)[i]];
		// 	st.operator = {};
		// 	st.value = null;
		// }

		this.setStateFilters(this.columns);

		this.indexUpdateComponent++;

		this.$emit("filter-mobile-clear");
	}

	onCloseDialogMessage() {
		this.$emit("filter-mobile-close");
	}

	get getFilterableColumns() {
		return this.columns.filter(col => {
			return col.filterable == undefined || col.filterable;
		});
	}

	scrollToBottom(index: number) {
		console.log("ciao")
		if (this.getFilterableColumns && (index +1) == this.getFilterableColumns.length) {
			var el = document.getElementsByClassName('dialog-body-filter-mobile')[0].parentElement;
			setTimeout(function() {
				el.scrollTo(0, el.scrollHeight);
			}, 300); 
		}
	}
}
