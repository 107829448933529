import helpers from "@/helpers";
import { datiViaggio } from "@/models/carrello/datiViaggio";
import { CarrelloInfoProdotto } from "@/models/carrello/infoProdotto";
import { RigaCarrelloInfo } from "@/models/carrello/rigaCarrello";
import { Adesione, RispostaAdesione } from "@/models/carrello/rispostaAdesioneMultipla";
import api from "@/services/api";
import apiFile from "@/services/apiFile";
import { Component, Prop } from "vue-property-decorator";
import { mapMutations, mapState } from "vuex";
import baseUserContextComponent from "./baseUserContextComponent";

@Component({})
export default class adesioneMobileComponent extends baseUserContextComponent {
	expand: boolean = false;

	@Prop({ default: () => undefined }) adesione: RispostaAdesione;

	get datiAdesione(): Adesione {
		return this.adesione == null || this.adesione.datiAdesione == null ? ({} as Adesione) : this.adesione.datiAdesione;
	}

	get getDestinazione() {
		return this.datiAdesione.destinazione;
	}

	get getNazionalita() {
		return this.datiAdesione.nazionalita;
	}

	get datiAggiuntivi() {
		return this.datiAdesione.datiAggiuntivi ? JSON.parse(atob(this.datiAdesione.datiAggiuntivi)) : null;
	}

	// get infoTotali(){
	// 	var tot = this.adesione.datiAdesione.infoTotali;
	// 	tot = tot ? JSON.parse(atob(tot)) : {};
	// 	return tot;
	// }

	get getNomiPartecipanti() {
		var elencoPart = this.datiAdesione.partecipantiElencoLista != null ? this.datiAdesione.partecipantiElencoLista : [],
			parts = [];
		for (var i = 0; i < elencoPart.length; i++) {
			if (elencoPart[i].nome || elencoPart[i].cognome) parts.push((elencoPart[i].nome || "") + " " + elencoPart[i].cognome);
		}
		return parts.join(", ");
	}

	get getPrimoPartecipante() {
		var elencoPart = this.datiAdesione.partecipantiElencoLista != null && this.datiAdesione.partecipantiElencoLista.length > 0 ? this.datiAdesione.partecipantiElencoLista[0] : null;
		return elencoPart != null ? elencoPart.nome + " " + elencoPart.cognome : "ERRORE PARTECIPANTI";
	}

	beforeDestroy() {}
	beforeMount() {}
	mounted() {}
	beforeCreate() {}
	created() {}

	onScarica() {
		var self = this;
		apiFile
			.downloadAdesione(self.datiAdesione.itemGUID)
			.then(response => {
				const blob = new Blob([response.data], { type: "application/pdf" });
				const link = document.createElement("a");
				link.href = URL.createObjectURL(blob);
				link.download = "adesione";
				link.click();
				URL.revokeObjectURL(link.href);
			})
			.catch(err => {
				console.log(err);
				var msg = this.$i18n.t("errore.fileDaScaricareNonTrovato").toString();
				self.$bvToast.toast(msg, {
					variant: "danger",
					title: self.$i18n.t("msg.erroreGenerico").toString(),
					solid: true
				});
			});
	}

	getUlterioriDatiValore(item) {
		return helpers.getUlterioriDatiValore(item);
	}
}
