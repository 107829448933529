"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.HeaderRow = void 0; // @ts-ignore

var Vue = require("vue");

var allVue = Vue;
var gh = allVue.h;
var inject = allVue.inject;

var GridSortSettings_1 = require("../interfaces/GridSortSettings");

var ColumnResizer_1 = require("../drag/ColumnResizer");

var ColumnDraggable_1 = require("../drag/ColumnDraggable");

var GridHeaderCell_1 = require("./GridHeaderCell"); // import { GridColumnMenuProps } from '../interfaces/GridColumnMenuProps';


var ColumnMenu_1 = require("../columnMenu/ColumnMenu");

var kendo_vue_common_1 = require("@progress/kendo-vue-common");
/**
 * @hidden
 */


var ariaSortMap = {
  'none': '',
  'asc': 'ascending',
  'desc': 'descending'
};
/**
 * @hidden
 */

var sortSeqMap = {
  true: {
    'asc': 'desc',
    'desc': '',
    '': 'asc'
  },
  false: {
    'asc': 'desc',
    'desc': 'asc',
    '': 'asc'
  }
};
/**
 * Represents the default `HeaderRow` component.
 */

var HeaderRow = {
  props: {
    grid: Object,
    cellRender: [String, Function, Object],
    groupable: [Boolean, Object],
    reorderable: Boolean,
    sortable: [Boolean, Object],
    sort: {
      type: Array
    },
    filter: Object,
    filterable: Boolean,
    filterChange: Function,
    filterRow: Object,
    columns: Array,
    columnsMap: Array,
    columnResize: Object,
    columnMenu: [Boolean, String, Function, Object]
  },
  created: function created() {
    this._element = null;
    this.cellClick = this.cellClick.bind(this);
  },
  methods: {
    pressHandler: function pressHandler(event, element) {
      this.$emit('pressHandler', event, element);
    },
    dragHandler: function dragHandler(event, element) {
      this.$emit('dragHandler', event, element);
    },
    releaseHandler: function releaseHandler(event) {
      this.$emit('releaseHandler', event);
    },
    selectionChangeHandler: function selectionChangeHandler(options) {
      this.$emit('selectionchange', options);
    },
    cellClick: function cellClick(e, column) {
      e.preventDefault();

      if (!kendo_vue_common_1.hasListener.call(this, 'sortChange')) {
        return;
      }

      var _a = GridSortSettings_1.normalize(this.$props.sortable || false, column.sortable || false),
          allowUnsort = _a.allowUnsort,
          mode = _a.mode;

      var oldDescriptor = (this.$props.sort || []).filter(function (d) {
        return d.field === column.field;
      })[0];
      var dir = sortSeqMap[allowUnsort][oldDescriptor && oldDescriptor.dir || ''];
      var newDescriptor = mode === 'single' ? [] : (this.$props.sort || []).filter(function (d) {
        return d.field !== column.field;
      });

      if (dir !== '' && column.field) {
        newDescriptor.push({
          field: column.field,
          dir: dir
        });
      }

      this.sortChangeHandler(newDescriptor, {
        event: e,
        field: column.field
      });
    },
    sortChangeHandler: function sortChangeHandler(newDescriptor, e) {
      this.$emit('sortChange', newDescriptor, e);
    },
    filterChangeHandler: function filterChangeHandler(newDescriptor, e) {
      this.$emit('filterChange', newDescriptor, e);
    },
    cellClass: function cellClass(field, headerClassName) {
      var customClass = headerClassName ? ' ' + headerClassName : '';

      if (this.$props.sort && this.$props.sort.filter(function (descriptor) {
        return descriptor.field === field;
      }).length > 0) {
        return 'k-header k-sorted' + customClass;
      }

      return 'k-header' + customClass;
    },
    getTemplate: function getTemplate(template) {
      return kendo_vue_common_1.templateRendering.call(this.$props.grid, template, kendo_vue_common_1.getListeners.call(this.$props.grid));
    }
  },
  computed: {
    element: {
      get: function get() {
        return this._element;
      }
    }
  },
  // @ts-ignore
  setup: !gh ? undefined : function () {
    var v3 = !!gh;
    return {
      v3: v3
    };
  },
  // @ts-ignore
  render: function render(createElement) {
    var h = gh || createElement;

    var cells = function cells(rowIndexes) {
      return rowIndexes.map(function (columnIndex, index) {
        var _this = this;

        var column = this.$props.columns[columnIndex];
        var sortable = this.$props.sortable && column.sortable;
        var sortIndex = this.$props.sort ? this.$props.sort.findIndex(function (s) {
          return s.field === column.field;
        }) : -1;
        var sortDir = sortIndex >= 0 ? this.$props.sort[sortIndex].dir || 'none' : 'none';

        var sortIconRenderer = function sortIconRenderer(iconSortIndex) {
          if (!_this.$props.sort) {
            return null;
          }

          return iconSortIndex >= 0 && [h("span", {
            key: 1,
            "class": 'k-icon k-i-sort-' + _this.$props.sort[iconSortIndex].dir + '-sm'
          }), _this.$props.sort.length > 1 && h("span", {
            key: 2,
            "class": "k-sort-order"
          }, [iconSortIndex + 1])];
        };

        var sortIcon = sortIconRenderer(sortIndex);
        var className = (column.kFirst ? 'k-first ' : '') + this.cellClass(column.field, column.headerClassName);
        var columnMenu = column.columnMenu || column.columnMenu === false ? column.columnMenu : this.$props.columnMenu;
        var columnMenuRender = !columnMenu || typeof columnMenu === 'boolean' ? !!columnMenu : this.getTemplate(columnMenu);
        var style = column.left !== undefined ? {
          position: 'sticky',
          left: column.left + 'px',
          right: column.right + 'px',
          zIndex: 1,
          background: '#f6f6f6',
          borderRightWidth: column.rightBorder ? '1px' : ''
        } : {};
        return h("th", {
          "aria-sort": ariaSortMap[sortDir],
          attrs: this.v3 ? undefined : {
            "aria-sort": ariaSortMap[sortDir],
            colSpan: column.colSpan,
            rowSpan: column.rowSpan
          },
          key: index,
          colSpan: column.colSpan,
          rowSpan: column.rowSpan,
          "class": className,
          style: style
        }, [[// @ts-ignore
        column.children.length === 0 && columnMenu && h(ColumnMenu_1.ColumnMenu, {
          key: 0,
          column: {
            field: column.field,
            filter: column.filter
          },
          attrs: this.v3 ? undefined : {
            column: {
              field: column.field,
              filter: column.filter
            },
            sortable: sortable,
            sort: this.$props.sort,
            filter: this.$props.filter,
            filterable: this.$props.filterable && column.filterable,
            render: columnMenuRender,
          },
          sortable: sortable,
          sort: this.$props.sort,
          onSortchange: this.sortChangeHandler,
          on: this.v3 ? undefined : {
            "sortchange": this.sortChangeHandler,
            "filterchange": this.filterChangeHandler
          },
          filter: this.$props.filter,
          filterable: this.$props.filterable && column.filterable,
          onFilterchange: this.filterChangeHandler,
          render: columnMenuRender,
        }), column.internalHeaderCell && // @ts-ignore function children
        h(column.internalHeaderCell, {
          key: 1,
          field: column.field,
          attrs: this.v3 ? undefined : {
            field: column.field,
            sortable: sortable,
            selectionValue: column.headerSelectionValue,
            title: column.title,
            render: (column.headerCell || this.$props.cellRender) && this.getTemplate(column.headerCell || this.$props.cellRender),
          },
          sortable: sortable,
          onHeadercellclick: function onHeadercellclick(e) {
            return _this.cellClick(e, column);
          },
          on: this.v3 ? undefined : {
            "headercellclick": function onHeadercellclick(e) {
              return _this.cellClick(e, column);
            },
            "selectionchange": this.selectionChangeHandler
          },
          onSelectionchange: this.selectionChangeHandler,
          selectionValue: column.headerSelectionValue,
          title: column.title,
          render: (column.headerCell || this.$props.cellRender) && this.getTemplate(column.headerCell || this.$props.cellRender),
        }, this.v3 ? function () {
          return [sortIcon];
        } : [sortIcon]) || // @ts-ignore function children
        h(GridHeaderCell_1.GridHeaderCell, {
          key: 1,
          field: column.field,
          attrs: this.v3 ? undefined : {
            field: column.field,
            sortable: sortable,
            selectionValue: column.headerSelectionValue,
            title: column.title,
            render: (column.headerCell || this.$props.cellRender) && this.getTemplate(column.headerCell || this.$props.cellRender),
			filterType: column.filterType,
			filterable: column.filterable || false,
			sort: column.sort,
			sortBy: column.sortBy,
			itemsForFilter: column.itemsForFilter,
			headerType: column.headerType
          },
          sortable: sortable,
          onHeadercellclick: function onHeadercellclick(e) {
            return _this.cellClick(e, column);
          },
          on: this.v3 ? undefined : {
            "headercellclick": function onHeadercellclick(e) {
              return _this.cellClick(e, column);
            }
          },
          selectionValue: column.headerSelectionValue,
          title: column.title,
          render: (column.headerCell || this.$props.cellRender) && this.getTemplate(column.headerCell || this.$props.cellRender),
        }, this.v3 ? function () {
          return [sortIcon];
        } : [sortIcon]), this.$props.columnResize && this.$props.columnResize.resizable // @ts-ignore
        && column.resizable && h(ColumnResizer_1.ColumnResizer, {
          key: 2,
          onResize: function onResize(e, element, end) {
            return _this.$props.columnResize && _this.$props.columnResize.dragHandler(e, column, element, end);
          },
          on: this.v3 ? undefined : {
            "resize": function onResize(e, element, end) {
              return _this.$props.columnResize && _this.$props.columnResize.dragHandler(e, column, element, end);
            }
          }
        })]]);
      }, this);
    };

    return h("thead", [this.$props.columnsMap.map(function (rowIndexes, index) {
      var _this2 = this;

      return (this.$props.groupable || this.$props.reorderable) && // @ts-ignore function children
      h(ColumnDraggable_1.ColumnDraggable, {
        key: index,
        onPressHandler: this.pressHandler,
        on: this.v3 ? undefined : {
          "pressHandler": this.pressHandler,
          "dragHandler": this.dragHandler,
          "releaseHandler": this.releaseHandler
        },
        onDragHandler: this.dragHandler,
        onReleaseHandler: this.releaseHandler
      }, this.v3 ? function () {
        return [cells.call(_this2, rowIndexes)];
      } : [cells.call(_this2, rowIndexes)]) || h("tr", [cells.call(this, rowIndexes)]);
    }, this), this.$props.filterRow]);
  }
};
exports.HeaderRow = HeaderRow;