export class ElencoAdesioni {
	
	constructor() {
		this.itemID = null;
	}
	
	itemID: number;
	itemGUID: string | null;
	userId: string;
	wI_ItemID: number | null;
	pacchettoID: number | null;
	numeroPartecipanti: number | null;
	inizioViaggio: string | null;
	fineViaggio: string | null;
	durataViaggio: number | null;
	destinazioneAlpha3: string;
	nazionalitaID: number | null;
	importoViaggio: number | null;
	partecipantiElenco: string;
	praticheComplementari: string;
	note: string;
	riferimentoPratica: string;
	chkIVASS: boolean | null;
	chkTermini: boolean | null;
	chkPrivacy: boolean | null;
	chkCommerciale: boolean | null;
	datiAggiuntivi: string;
	totalePremio: number | null;
	totalePremioNetto: number | null;
	totalePremioImposte: number | null;
	totalePremioPersona: number | null;
	totalePremioPersonaImposte: number | null;
	commissioni: number | null;
	totalePremioDaPagare: number | null;
	istanteInserimento: string | null;
	contrattoNumero: string;
	nazionalita: string;
	destinazione: string;
	gruppoStati: string;
	pDFCopertura: string;
	numeroAdesione: number;
	fileInvioOnlineID: number;
	codiceRiepilogo: string;
	fileInvioOnLIne: string;
	pacchetto: string;
	importoSconto: number | null;
	importoPagato: number | null;
	dataPagamento: string | null;
	contraenteID: number | null;
	utente: string;
	distributore: string;
	codiceRaggruppamento: string;
	codiceTariffa: string;
	distributoreID: number | null;
	tipoDistributore: string;
	estrattoContoID: number | null;
	dataModifica: string | null;
	fornitoreID: number | null;
	dataInserimento: string | null;
	codiceCoupon: string;
	codiceRiepilogoOnline: string;
	numeroCompleto: string;
	imageElenco: string;
	imageFilePartecipanti: string;
	numeroAlloggi: number;
	secondoImporto: number | null;
	recuperoCredito: number | null;
}