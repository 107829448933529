import { Component, Prop } from "vue-property-decorator";
import baseUserContextComponent from "./baseUserContextComponent";
import * as s from "@/router/router";
import { appRoute } from "@/models/appRoute";
import { Route } from "vue-router";

@Component({})
export default class subTopNav extends baseUserContextComponent {
	@Prop({ default: () => 'tabs' })
	layout: string;
	beforeDestroy() { }
	beforeMount() { }
	mounted() { }
	beforeCreate() { }
	created() {
		var self = this;
	}
	get items() {
		var self = this;
		var name = (self.currentRoute.parent || {}).name;
		return self.routes
			.filter(x => x.parent != null && x.parent.name == name && x.meta != null && x.meta.topSubNav != null && self.hasPermessi(x.meta.permissions) && self.hasAttributiUtente(x.meta.attributiUtente))
			.sort((a, b) =>
				a.meta.topSubNav.displayOrder > b.meta.topSubNav.displayOrder
					? 1
					: -1
			);
	}
	get currentRoute(): appRoute {
		return this.routes.find(x => x.name == this.$route.name) || {} as appRoute;
	}
	get test() {
		var self = this;
		return self.$route;
	}
	getLabel(r: appRoute): string {
		return r.meta.label;
	}
	getPath(r: appRoute): string {
		return r.path;
	}
	isPressed(r: appRoute): boolean {
		var self = this;
		return self.$route.name == r.name;
	}

	async goToPathname(pathname: string) {
		if (this.$router.currentRoute.name != pathname) this.$router.push({ name: pathname });
	}

}
