"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GridGroupCell = void 0; // @ts-ignore

var Vue = require("vue");

var allVue = Vue;
var gh = allVue.h;

var kendo_vue_common_1 = require("@progress/kendo-vue-common");
/**
 * Represents the default `GridGroupCell` component.
 */


var GridGroupCell = {
  props: {
    field: String,
    dataItem: Object,
    format: String,
    colSpan: Number,
    className: String,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String,
    groupTitle: String,
    render: [String, Function, Object]
  },
  methods: {
    triggerKeydown: function triggerKeydown(e) {
      this.$emit('cellkeydown', {
        event: e,
        dataItem: this.$props.dataItem,
        field: this.$props.field,
        expanded: this.$props.expanded
      });
    },
    clickHandler: function clickHandler(e, dataItem, expanded) {
      e.preventDefault();
      this.$emit('change', {
        dataItem: dataItem,
        event: e,
        field: undefined,
        value: !expanded
      });
    }
  },
  // @ts-ignore
  setup: !gh ? undefined : function () {
    var v3 = !!gh;
    return {
      v3: v3
    };
  },
  // @ts-ignore
  render: function render(createElement) {
    var _this = this;

    var h = gh || createElement;
    var defaultRendering = null;
    var _a = this.$props,
        columnIndex = _a.columnIndex,
        level = _a.level,
        columnsCount = _a.columnsCount,
        rowType = _a.rowType,
        dataItem = _a.dataItem,
        field = _a.field,
        expanded = _a.expanded,
        render = _a.render;

    if (columnIndex === undefined || level === undefined || columnIndex < level || columnsCount === undefined || rowType !== 'groupHeader' || dataItem[field] === undefined) {
      defaultRendering = h("td", {
        key: 'g' + columnIndex,
        "class": "k-group-cell"
      });
    } else if (columnIndex <= level) {
      defaultRendering = h("td", {
        onKeydown: this.triggerKeydown,
        on: this.v3 ? undefined : {
          "keydown": this.triggerKeydown
        },
        key: 'g-colspan',
        colSpan: columnsCount - columnIndex,
        attrs: this.v3 ? undefined : {
          colSpan: columnsCount - columnIndex
        }
      }, [h("p", {
        "class": "k-reset"
      }, [h("a", {
        onClick: function onClick(e) {
          _this.clickHandler(e, dataItem, expanded);
        },
        on: this.v3 ? undefined : {
          "click": function onClick(e) {
            _this.clickHandler(e, dataItem, expanded);
          }
        },
        href: "#",
        attrs: this.v3 ? undefined : {
          href: "#",
          tabIndex: -1
        },
        tabIndex: -1,
        "class": expanded ? 'k-i-collapse k-icon' : 'k-i-expand k-icon'
      }), dataItem[field] ? dataItem[field].toString() : null])]);
    }

    return kendo_vue_common_1.getTemplate.call(this, {
      h: h,
      template: render,
      defaultRendering: defaultRendering,
      additionalProps: this.$props,
      additionalListeners: {
        keydown: this.triggerKeydown,
        click: this.clickHandler
      }
    });
  }
};
exports.GridGroupCell = GridGroupCell;