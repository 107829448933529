import Vue from "vue";

class eventClass extends Vue {
	public readonly events = {
		AXIOS_RESPONSE_ERROR: "axios-response-error",
		AXIOS_REQUEST_STARTED: "axios-request-started",
		AXIOS_REQUEST_ENDED: "axios-request-ended",
		USER_LOGIN: "user-login",
		USER_LOGOUT: "user-logout",
		USER_INFO_UPDATED: "user-info-updated",
		CULTURE_CHANGE: "culture-change",

		PRODUCT_SEARCHED: "product-searched",
		TOGGLE_PREFERITI: "toggle-preferiti",

		RESET_PRODUCT_SEARCHED: "reset-product-searched",

		CART_CHANGED: "cart-changed"
	};
}

const eventHub = new eventClass();
export default eventHub;
