import Vue from "vue";
// import Multiselect from "vue-multiselect";
// import "@progress/kendo-theme-bootstrap/dist/all.scss";


//import "@progress/kendo-theme-default/dist/all.css";
// import { Grid } from '@progress/kendo-vue-grid';
// import { Grid } from '../src/customPlugin/kendo/kendo-vue-grid/src/main';
import { Grid, GridToolbar, FooterRow, Footer } from './customPlugin/kendo/kendo-vue-grid/dist/npm/main';
import { IntlProvider } from "@progress/kendo-vue-intl";


import defaultLayout from "./layouts/default.vue";
import subMenuLayout from "./layouts/subMenu.vue";
import carrelloLayout from "./layouts/carrello.vue";
import emptyLayout from "./layouts/empty.vue";
import topNavBar from "./components/topNavBar.vue";
import subTopNav from "./components/subTopNav.vue";
import footer from "./components/footerBottom.vue";
import cellTemplate from "./components/grid/cellTemplate/cellTemplate.vue";
import actionCellTemplate from "./components/grid/cellTemplate/actionCellTemplate.vue";
import headerCellTemplate from "./components/grid/cellTemplate/headerTemplate.vue";
import editForm from "./components/grid/edit/editForm.vue";
import appImage from "./components/appImage.vue";
import carrelloNavBar from "./components/carrelloNavBar.vue";
import carrelloCategoria from "./components/carrelloCategoria.vue";
// import { ButtonsInstaller } from '@progress/kendo-buttons-vue-wrapper'
import { ButtonsInstaller } from '@progress/kendo-buttons-vue-wrapper'
import VSelect from '@alfsnd/vue-bootstrap-select';
import VueLodash from "vue-lodash";
import lodash from 'lodash';
import AppDatePicker from "./components/appDatePicker.vue";
import AssociaPermessi from "./components/associa-permessi.vue";
import { DateinputsInstaller } from "@progress/kendo-dateinputs-vue-wrapper";
import dir_auth_image from "./directives/vue-auth-image";
import { AutoComplete, ComboBox, DropDownList, MultiSelect } from '@progress/kendo-vue-dropdowns';
import { Dialog, DialogActionsBar, Window } from '@progress/kendo-vue-dialogs';
import editFormGrop from "./components/grid/edit/editFormGroup.vue";
import Dropdown from "./components/dropdown.vue";
import DatePickerComponent from "./components/datePicker.vue";
import ParametriCarrello from "./components/parametriCarrello.vue";
import ProdottoCarrello from "./components/prodottoCarrello.vue";
import CheckCategoriaParametri from "./components/checkCategoriaParametri.vue";
import CheckParametri from "./components/checkParametri.vue";
import InfoProdottoPreventivo from "./components/infoProdottoPreventivo.vue";
import GrigliaPartecipanti from "./components/grigliaPartecipanti.vue";
import GrigliaUlterioriDati from "./components/grigliaUlterioriDati.vue";
import Dichiarazioni from "./components/dichiarazioni.vue";
import DichiarazioniMobile from "./components/dichiarazioniMobile.vue";
import ProdottoSelezionato from "./components/prodottoSelezionato.vue";
import Complementari from "./components/complementari.vue";
import ComplementariMobile from "./components/complementariMobile.vue";
import { DatePicker } from '@progress/kendo-vue-dateinputs';
import { NumericTextBox } from "@progress/kendo-vue-inputs";

//pages
import plashScreen from "./pages/plashScreen.vue";
import CommercialeDistributoreDettaglioPage from "./pages/commercialeDistributoreDettaglio.vue";
import popupCarelloModale from "./components/popupCarello/popupCarelloModale.vue";
import AccediComeModalPage from "./components/accedi-come.vue";
import prodottoCarrelloCard from "./components/prodottoCarrelloCard.vue";
import adesioneComponent from "./components/adesione.vue";
import adesioneMobileComponent from "./components/adesioneMobile.vue";
import VueRecaptcha from "vue-recaptcha";
import ElencoModifichePraticaPage from "./pages/modificaPratica/elencoModifichePratica.vue";
import modificaPraticaDettaglioPage from "./pages/modificaPratica/modificaPraticaDettaglio.vue";
import carrelloCategoriaMobile from "./components/carrelloCategoriaMobile.vue";
import FilterMobile from "./components/filterMobile.vue";

class main_registraion {
	init = function (): void {
		Vue.component("top-nav-bar", topNavBar);
		Vue.component("sub-top-nav", subTopNav);
		Vue.component("app-footer", footer);
		Vue.component("default-layout", defaultLayout);
		Vue.component("sub-menu-layout", subMenuLayout);
		Vue.component("carrello-layout", carrelloLayout);
		Vue.component("empty-layout", emptyLayout);
		Vue.component("plash-screen", plashScreen);
		// Vue.component("multiselect", Multiselect);
		Vue.component("associa-permessi", AssociaPermessi);
		Vue.component("grid", Grid);
		Vue.component("footerrow", FooterRow);
		Vue.component("grid-toolbar", GridToolbar);
		Vue.component("intl-provider", IntlProvider);
		Vue.component("cell-template", cellTemplate);
		Vue.component("action-cell-template", actionCellTemplate);
		Vue.component("header-cell-template", headerCellTemplate);
		Vue.component("edit-form", editForm);
		Vue.component("edit-form-group", editFormGrop);
		Vue.component("app-image", appImage);
		Vue.component("app-date-picker", AppDatePicker);
		Vue.component("autocomplete", AutoComplete);
		Vue.component("combobox", ComboBox);
		Vue.component("dropdownlist", DropDownList);
		Vue.component("dropdown-list", Dropdown);
		Vue.component("kendo-numeric-textbox", NumericTextBox);
		Vue.component("k-dialog", Dialog);
		Vue.component("k-window", Window);
		Vue.component("dialog-actions-bar", DialogActionsBar);
		Vue.component("date-picker", DatePickerComponent);
		Vue.component("multiselect", MultiSelect);
		Vue.component("carrello-nav-bar", carrelloNavBar);
		Vue.component("carrello-categoria", carrelloCategoria);
		Vue.component("carrello-categoria-mobile", carrelloCategoriaMobile);
		Vue.component("parametri-carrello", ParametriCarrello);
		Vue.component("prodotto-carrello", ProdottoCarrello);
		Vue.component("prodotto-carrello-card", prodottoCarrelloCard);
		Vue.component("check-categoria-parametri", CheckCategoriaParametri);
		Vue.component("check-parametri", CheckParametri);
		Vue.component("info-prodotto-preventivo", InfoProdottoPreventivo);
		Vue.component("griglia-partecipanti", GrigliaPartecipanti);
		Vue.component("griglia-ulteriori-dati", GrigliaUlterioriDati);
		Vue.component("dichiarazioni", Dichiarazioni);
		Vue.component("dichiarazioni-mobile", DichiarazioniMobile);
		Vue.component("prodotto-selezionato", ProdottoSelezionato);
		Vue.component("adesione", adesioneComponent);
		Vue.component("adesione-mobile", adesioneMobileComponent);
		Vue.component("complementari", Complementari);
		Vue.component("complementari-mobile", ComplementariMobile);
		Vue.component("kendo-datepicker", DatePicker);

		Vue.component("commerciale-distributore-dettaglio", CommercialeDistributoreDettaglioPage);
		Vue.component("elenco-modifiche-pratica", ElencoModifichePraticaPage);
		Vue.component("modifica-pratica-dettaglio", modificaPraticaDettaglioPage);
		Vue.component("popup-carello-modale", popupCarelloModale);
		Vue.component("accedi-come", AccediComeModalPage);
		Vue.component("vue-recaptcha", VueRecaptcha);
		Vue.component("filter-mobile", FilterMobile);

		Vue.use(ButtonsInstaller);
		Vue.component("v-select", VSelect);
		Vue.use(VueLodash, { name: 'ld', lodash: lodash });
		Vue.use(DateinputsInstaller);
		


		//directives

		Vue.directive("auth-image", dir_auth_image);
	};
}

export default new main_registraion();
