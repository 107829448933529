import eventHub from "@/eventHub";
import Vuex, { ActionContext, Store } from "vuex";
// import store from "../store";
import { operationResult, operationResultStatus } from "@/models/operationResult";
import axios, { AxiosInstance } from "axios";
import Qs from "qs";
import { CambioPasswordModel, ChangePasswordModel, ForzaCambioPasswordModel, RecuperaPasswordModel, UserLoggedInfoModel, UserLoginModel, UserMeInfo } from "@/models/userModels";
import helpers from "@/helpers";
import { AnagraficaDistributore, AnagraficaDistributoreEdit, AnagraficaDistributoreLight } from "@/models/anafrafica/AnagraficaDistributore";
import { Permesso, RuoloPermesso } from "@/models/ruolo";
import { TextValueExtended, TextValueItem } from "@/models/TextValueItem";
import { PopupCarrelloItem } from "@/models/popupCarrello";
import { LoginUserAsResponse } from "@/models/login/LoginUserAsResponse";
import { RispostaAdesioneMultipla } from "@/models/carrello/rispostaAdesioneMultipla";
import { AgenzieRegistrazioneItem } from "@/models/agenzie/agenzieRegistrazioneItem";
import ImportPraticheResult from "@/models/praticheMultiple/importPraticheResult";
import moment from "moment";

class Api {

	private popupCarrello: PopupCarrelloItem[];
	private permessiList: Permesso[];

	public readonly endpoint = {
		DROPDOWN: {
			PACCHETTI_DISTRIBUTORI: "dropdown/pacchettiDistributori",
			PRODOTTI: "dropdown/prodotti",
			CONTRAENTI: "dropdown/contraenti",
			CONTRATTI: "dropdown/contratti",
			DISTRIBUTORI: "myoverins/distributori",
			DISTRIBUTORI_TUTTI: "dropdown/distributori",
			TIPI_DISTRIBUTORE: "dropdown/tipidistributore",
			DESTINAZIONI: "dropdown/destinazioni",
			STATI: "dropdown/stati",
			STATI_PREVENTIVATORE: "dropdown/statipreventivatore",
			GRUPPI_STATI: "dropdown/gruppistati",
			NAZIONALITA: "dropdown/nazionalita",
			TIPOLOGIE_PRODOTTI: "dropdown/tipologieProdotti",
			TIPOLOGIE_PRODOTTI_VISIBILI: "dropdown/tipologieProdottiVisibili",
			FORNITORI: "dropdown/fornitori",
			FORNITORIFILEINVIO: "dropdown/fornitorifileinvio",
			PROVINCE: "dropdown/province",
			REGIONI: "dropdown/regioni",
			ANAGRAFICA_PROVINCE: "dropdown/anagrafiche/province",
			ANAGRAFICA_COMUNI: "dropdown/anagrafiche/comuni",
			COMMERCIALI: "dropdown/commerciali",
			ETA: "dropdown/eta",
			RUOLI: 'dropdown/ruoli',
			RUOLI_WEB: 'dropdown/ruoli/web',
		},

		CARRELLO: {
			CARRELLO: "carrello",
			PACCHETTI_CATEGORIE: "carrello/pacchettiCategorie",
			PRODOTTI_CATEGORIA: "carrello/prodottiCategoria",
			CONFRONTA_PRODOTTI: "carrello/confrontaProdotti",
			PREVENTIVATORE: "carrello/preventivatore",
			PARAMETRI: "carrello/parametri",
			PREFERITI: "carrello/preferiti",
			INFO_PRODOTTO: "carrello/prodotti",
			TOTALI: "carrello/totali",
			COMPLEMENTARI: "carrello/prodotti/complementari",
			EXCEL_PARTECIPANTI: "carrello/upload/excelpartecipanti",
			FILE_PARTECIPANTI: "carrello/upload/immaginepartecipanti",
			CREA_ADESIONI: "carrello/adesioni",
			IMPORT_PRATICHE_MULTIPLE: "carrello/caricapratiche",
			TEMPLATE_PRATICHE_MULTIPLE: "carrello/modelli",
			DESCRIZIONE_PRODOTTO: "carrello/descrizione",
			KEYCLIENT_OK: "carrello/keyclient/ok",
			KEYCLIENT_FINEMESE_OK: "carrello/keyclient/fm/ok",
			MESSAGGI_UTENTI: "carrello/message",
			TERMINI_E_CONDIZIONI: "carrello/terminiecondizioni",
			ELENCO_MODIFICHE_PRATICA: "carrello/modifiche/elenco",
			MODIFICA_PRATICA: "carrello/modifiche/abilitazione",
		},

		AGENZIE: {
			ASSOCIAZIONI: "agenzie/associazioni",
		},

		MY_OVERINS: {
			DATI_AGENZIA: "myoverins/datiagenzia",
			ELENCO_ADESIONI_DISTRIBUTORE: "myoverins/elencoadesioni/distributore",
			ELENCO_ADESIONI_NETWORK: "myoverins/elencoadesioni/network",
			ELENCO_ESTRATTI_CONTO_MIEI: "myoverins/elencoestratticonto/me",
			ELENCO_ESTRATTI_CONTO_NETWORK: "myoverins/elencoestratticonto/network",
			CREDITI_DISTRIBUTORE: "myoverins/distributori/crediti",
			CREDITI_DISTRIBUTORE_TOTALI: "myoverins/distributori/crediti/totali",
			ELENCO_PAGAMENTI: "myoverins/elencopagamenti",
			ELENCO_ADESIONI_FORNITORE: "myoverins/elencoadesioni/fornitore",
			COMMERCIALI_DISTRIBUTORI: "myoverins/commerciali/distributori",
			COMMERCIALI_DISTRIBUTORI_TOTALI: "myoverins/commerciali/distributori/totali",
			COMMERCIALI_DISTRIBUTORI_DETTAGLIO: "myoverins/commerciali/pacchetti",
			UTENTI: "myoverins/utentiagenzia",
			REGISTRAZIONE_AGENZIA: "myoverins/agenzia",
			
		},
		ACCOUNT: {
			CONFERMA_EMAIL: "account/confirmemail",
		},
		AMMINISTRAZIONE: {
			PACCHETTI_CATEGORIE: "amministrazione/pacchettiCategorie",
			POPUP_UTENTE: "amministrazione/popuputente",
			POPUP_UTENTE_CONFERMAPRESAVISIONE: "amministrazione/popuputente/confermapresavisione",
			POPUP_UTENTE_NONMOSTRAREPIU: "amministrazione/popuputente/nonmostrarepiu",
		},

		UTENTI_SIGN_IN_AS: "account/asusers",
		REPORT: process.env.VUE_APP_REPORT_URL,//"http://localhost:3115",
		RICERCA_GARANZIE: "ricercagaranzie",
		PRATICHE_XML: process.env.VUE_APP_XML_URL + "api/envelope/xml/esegui"
	};

	instance: AxiosInstance = null;
	instanceBackground: AxiosInstance = null;
	_store: Store<any> = null;

	/**
	 * Ctor
	 */
	constructor() {
		var self = this;
		this.instance = axios.create({
			baseURL: process.env.VUE_APP_BASE_URL
		});

		this.instance.interceptors.request.use(config => {
			eventHub.$root.$emit(eventHub.events.AXIOS_REQUEST_STARTED, "STARTED");
			config.headers.common["Authorization"] = "bearer " + localStorage.getItem("overins-token");
			config.paramsSerializer = params => {
				// Qs is already included in the Axios package
				return Qs.stringify(params, {
					arrayFormat: "repeat"
				});
			};
			return config;
		});
		this.instance.interceptors.response.use(
			response => {
				eventHub.$emit(eventHub.events.AXIOS_REQUEST_ENDED, "ENDED");
				return response;
			},
			error => {
				eventHub.$emit(eventHub.events.AXIOS_RESPONSE_ERROR, error);
				eventHub.$emit(eventHub.events.AXIOS_REQUEST_ENDED, "ENDED");
				return Promise.reject(error);
			}
		);

		this.instanceBackground = axios.create({
			baseURL: process.env.VUE_APP_BASE_URL
		});

		this.instanceBackground.interceptors.request.use(config => {
			config.headers.common["Authorization"] = "bearer " + localStorage.getItem("overins-token");
			config.paramsSerializer = params => {
				// Qs is already included in the Axios package
				return Qs.stringify(params, {
					arrayFormat: "repeat"
				});
			};
			return config;
		});
		this.instanceBackground.interceptors.response.use(
			response => {
				return response;
			},
			error => {
				console.log("Background Call Error: ");
				console.log(error);

				return Promise.reject(error);
			}
		);
	}

	//#region ** DROPDOWN **
	getDatiDropDown(endpoint): Promise<TextValueItem[] | TextValueExtended[]> {
		var self = this;
		// return new Promise(function (resolve, reject) {
		// 	self.instance
		// 		.get<TextValueItem[]>(endpoint)
		// 		.then(res => {
		// 			resolve(res.data);
		// 		})
		// 		.catch(err => {
		// 			reject(err);
		// 		});
		// });
		return self.getDatiDropDownAny<TextValueItem[]>(endpoint);
	}
	getDatiDropDownAny<T>(endpoint): Promise<T> {
		var self = this;
		return new Promise(function (resolve, reject) {
			self.instance
				.get<T>(endpoint)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	}
	//#endregion

	//#region ** AUTHENTICATION **

	getLocalization(lang: string): any {
		var self = this;
		return new Promise(function (resolve, reject) {
			// var r = messages[lang];

			// resolve(r);
			self.instance
				.get(`/localization/labels/${lang}`)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	}
	getUserInfo(): Promise<UserMeInfo> {
		var self = this;
		return new Promise(function (resolve, reject) {
			self.instance
				.get<UserMeInfo>("/account/me")
				.then(res => {
					self.loadUserConfig().then(innerres => {
						resolve(res.data);
					});
				})
				.catch(err => {
					reject(err);
				});
			// var test = new UserLoggedInfoModel();
			// test.firstName = "firstName";
			// test.emailAddress = "emailAddress";
			// test.lastName = "lastName";
			// test.userName = "signInName";
			// resolve(test)
		});
	}
	private loadUserConfig(): Promise<boolean> {
		var self = this;
		return new Promise(function (resolve, reject) {
			var p = [];
			p.push(self.reloadPopupCarrello());
			Promise.all(p)
				.then(responses => {
					resolve(true);
				})
				.catch(err => {
					reject(err);
				});
		});


	}
	doLogout(): Promise<boolean> {
		var self = this;

		return new Promise(function (resolve, reject) {
			self.instance
				.post("/account/logout")
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	}

	doJwtLogin(credencial: UserLoginModel): Promise<string> {
		var self = this;
		return new Promise(function (resolve, reject) {
			self.instance
				.post<operationResult<string>>("/account/login", credencial)
				.then(res => {
					resolve(res.data.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	}

	doRecuperaPassword(credencial: RecuperaPasswordModel): Promise<boolean> {
		var self = this;
		return new Promise(function (resolve, reject) {
			self.instance
				.post<operationResult<boolean>>("/account/recupera-password", credencial)
				.then(res => {
					resolve(res.data.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	}

	doCambiaPassword(credencial: CambioPasswordModel): Promise<boolean> {
		var self = this;
		return new Promise(function (resolve, reject) {
			self.instance
				.post<operationResult<boolean>>("/account/cambia-password", credencial)
				.then(res => {
					resolve(res.data.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	}

	doForzaCambiaPassword(credencial: ForzaCambioPasswordModel): Promise<boolean> {
		var self = this;
		return new Promise(function (resolve, reject) {
			self.instance
				.put<operationResult<boolean>>("/account/forza-password", credencial)
				.then(res => {
					resolve(res.data.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	}

	doChangePassword(credencial: ChangePasswordModel): Promise<boolean> {
		var self = this;
		return new Promise(function (resolve, reject) {
			self.instance
				.post<operationResult<boolean>>("/account/change-password", credencial)
				.then(res => {
					resolve(res.data.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	}

	setFirstPassword(credencial: ChangePasswordModel): Promise<boolean> {
		var self = this;
		return new Promise(function (resolve, reject) {
			self.instance
				.post<operationResult<boolean>>("/account/set-first-password", credencial)
				.then(res => {
					resolve(res.data.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	}

	//#endregion

	//#region ** PERMESSI **

	getPermessiList(): Promise<Permesso[]> {
		var self = this;
		return new Promise(function (resolve, reject) {

			if (self.permessiList != null)
				resolve(self.permessiList);
			else
				self.instance
					.get<Permesso[]>("/ruoli/permessi-list")
					.then(res => {
						self.permessiList = res.data;
						resolve(self.permessiList);
					})
					.catch(err => {
						reject(err);
					});
		});
	}
	aggiungiRimuoviPermesso(item: any): Promise<operationResult<any>> {
		var self = this;
		return new Promise(function (resolve, reject) {

			self.instance
				.post<operationResult<any>>(`/ruoli/${item.ruoloID}/add-or-remove-permesso`, item)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	}

	getPermessi(ruoloId: number): Promise<RuoloPermesso[]> {
		var self = this;
		return new Promise(function (resolve, reject) {

			self.instance
				.get<RuoloPermesso[]>(`/ruoli/${ruoloId}/permessi`)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	}
	//#endregion ** PERMESSI **

	//#region ** GRIGLIA **
	setGridRow<T>(endpoint: string, item: T): Promise<operationResult<T>> {
		var self = this;
		return new Promise(function (resolve, reject) {
			self.instance
				.post<operationResult<T>>(endpoint, item)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	}

	getDatiGriglia(endpoint, filters?, sort?, page?, pageSize?, group?): Promise<any> {
		var self = this;

		var options = {
			filter: filters,
			sort: helpers.cloneObject(group),
			page: page,
			pageSize: pageSize
		};

		if (sort && sort.length > 0) {
			for (var i = 0; i < sort.length; i++) {
				let s = sort[i];
				options.sort.push(s);
			}
		}

		var params: string = helpers.buildUrlParams(options).join("&");

		let uri = `${endpoint}`;
		if (uri.indexOf("?") >= 0) {
			uri += `&${params}`;
		} else {
			uri += `?${params}`;
		}

		return new Promise(function (resolve, reject) {
			self.instance
				.get<any>(uri)
				.then(res => {
					if( res && res.data && res.data.items && res.data.items.length){
						for( var i = 0; i < res.data.items.length; i++){
							var c = res.data.items[i];
							if( c.inizioViaggio){
								c.inizioViaggio = c.inizioViaggio.substring(0,10);
							}
							if( c.fineViaggio){
								c.fineViaggio = c.fineViaggio.substring(0,10);
							}
						}
					}

					resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	}

	getDatiGrigliaFullText(endpoint, filters?, sort?, page?, pageSize?, group?, fulltext?): Promise<any> {
		var self = this;

		var options = {
			filter: filters,
			sort: helpers.cloneObject(group),
			page: page,
			pageSize: pageSize,
			fullTextSearch: fulltext
		};

		if (sort && sort.length > 0) {
			for (var i = 0; i < sort.length; i++) {
				let s = sort[i];
				options.sort.push(s);
			}
		}

		var params: string = helpers.buildUrlParams(options).join("&");

		let uri = `${endpoint}`;
		if (uri.indexOf("?") >= 0) {
			uri += `&${params}`;
		} else {
			uri += `?${params}`;
		}

		return new Promise(function (resolve, reject) {
			self.instance
				.get<any>(uri)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	}

	deleteRowGriglia(endpoint: string, itemId: number | string): Promise<operationResult<boolean>> {
		var self = this;
		return new Promise(function (resolve, reject) {
			self.instance
				.delete<operationResult<boolean>>(`${endpoint}/${itemId}`)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	}

	getBackground(endpoint): Promise<any> {
		var self = this;
		return new Promise(function (resolve, reject) {
			self.instanceBackground
				.get<any>(endpoint)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	}

	get(endpoint): Promise<any> {
		var self = this;
		return new Promise(function (resolve, reject) {
			self.instance
				.get<any>(endpoint)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	}

	getWithAllDataResponse(endpoint, options?: any): Promise<any> {
		var self = this;
		return new Promise(function (resolve, reject) {
			self.instance
				.get<any>(endpoint, options)
				.then(res => {
					resolve(res);
				})
				.catch(err => {
					reject(err);
				});
		});
	}

	chimataPost<T>(endpoint: string, item): Promise<operationResult<T>> {
		var self = this;
		return new Promise(function (resolve, reject) {
			self.instance
				.post<operationResult<T>>(endpoint, item)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	}

	chiamataPut<T>(endpoint: string, item): Promise<operationResult<T>> {
		var self = this;
		return new Promise(function (resolve, reject) {
			self.instance
				.put<operationResult<T>>(endpoint, item)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	}

	delete(endpoint: string, itemId: number | string): Promise<operationResult<boolean>> {
		var self = this;
		return new Promise(function (resolve, reject) {
			self.instance
				.delete<operationResult<boolean>>(`${endpoint}/${itemId}`)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	}

	postFormData(endpoint: string, formData: FormData): Promise<operationResult<any>> {
		var self = this;

		return new Promise(function (resolve, reject) {
			self.instance
				.post<operationResult<any>>(endpoint, formData, {
					headers: {
						"Content-Type": "multipart/form-data"
					}
				})
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	}

	importPraticheFormData(endpoint: string, formData: FormData): Promise<ImportPraticheResult> {
		var self = this;

		return new Promise(function (resolve, reject) {
			self.instance
				.post<ImportPraticheResult>(endpoint, formData, {
					headers: {
						"Content-Type": "multipart/form-data"
					}
				})
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	}


	postXML(endpoint: string, xmlData: string): Promise<operationResult<any>> {
		var self = this;

		return new Promise(function (resolve, reject) {
			self.instance
				.post<operationResult<any>>(endpoint, xmlData, {
					headers: {
						"Content-Type": "application/xml; charset=utf-8"
					}
				})
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	}

	//#endregion

	//#region ** I TUOI DATI **

	getDatiAgenzia(): Promise<AnagraficaDistributore> {
		var self = this;
		return new Promise(function (resolve, reject) {
			self.instance
				.get("myoverins/datiagenzia")
				.then(res => resolve(res.data))
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}
	// dopo login vanno ricaricati 
	getPopupCarrello(): Promise<PopupCarrelloItem[]> {
		var self = this;
		return new Promise(function (resolve, reject) {
			if (self.popupCarrello == null || self.popupCarrello.length == 0) {
				return self.reloadPopupCarrello();
			}
			else {
				resolve(self.popupCarrello);
			}
		});
	}

	// dopo login vanno ricaricati 
	reloadPopupCarrello(): Promise<PopupCarrelloItem[]> {
		var self = this;
		return new Promise(function (resolve, reject) {
			self.instance
				.get(`${api.endpoint.AMMINISTRAZIONE.POPUP_UTENTE}`)
				.then(res => {
					self.popupCarrello = res.data;
					resolve(self.popupCarrello);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});

	}

	confermaPresaVisione(id: number, version: number): Promise<void> {
		var self = this;
		return new Promise(function (resolve, reject) {
			self.instance
				.post(`${api.endpoint.AMMINISTRAZIONE.POPUP_UTENTE_CONFERMAPRESAVISIONE}`, { id: id, version: version })
				.then(res => {
					resolve();
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}
	nonMostrarePiu(id: number, version: number): Promise<void> {
		var self = this;
		return new Promise(function (resolve, reject) {
			self.instance
				.post(`${api.endpoint.AMMINISTRAZIONE.POPUP_UTENTE_NONMOSTRAREPIU}`, { id: id, version: version })
				.then(res => {
					resolve();
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	//#endregion

	//#region ** DISTRIBUTORI **
	// getDistributoriList(): Promise<Array<AnagraficaDistributoreLight>> {
	// 	var self = this;
	// 	return new Promise(function (resolve, reject) {
	// 		self.instance
	// 			.get<any>(`/anagrafica/distributori-list`)
	// 			.then(res => {
	// 				resolve(res.data);
	// 			})
	// 			.catch(err => {
	// 				reject(err);
	// 			});
	// 	});
	// }
	//#endregion

	//#region ** PAGAMENTI **
	eseguiPagamento(itemIds: number[]): Promise<operationResult<string>> {
		var self = this;
		return new Promise(function (resolve, reject) {
			self.instance
				.post<operationResult<string>>("myoverins/eseguipagamento", itemIds)
				.then(res => resolve(res.data))
				.catch(err => {
					console.log(err);
					reject(err);
				})
		});
	}


	//#endregion

	//#region ** COMMERCIALI DISTRIBUTORI **
	getDistributori(): Promise<any> {
		var self = this;
		return new Promise(function (resolve, reject) {
			self.instance
				.get<any>(`/anagrafica/tipidistributore`)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	}
	//#endregion

	//#region ACCEDI COME

	signInAs(userId: string): Promise<operationResult<LoginUserAsResponse>> {
		var self = this;
		return new Promise<operationResult<LoginUserAsResponse>>(function (resolve, reject) {
			self.instance
				.post<operationResult<LoginUserAsResponse>>(`${self.endpoint.UTENTI_SIGN_IN_AS}/${userId}`)
				.then(res => resolve(res.data))
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	disconnectFromSignInAs(): Promise<operationResult<string>> {
		var self = this;
		return new Promise<operationResult<string>>(function (resolve, reject) {
			self.instance
				.put<operationResult<string>>(`${self.endpoint.UTENTI_SIGN_IN_AS}`)
				.then(res => resolve(res.data))
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	registrazioneNuovaAgenzia(item: AgenzieRegistrazioneItem): Promise<operationResult<string>> {
		var self = this;

		var formData = new FormData();
		var ojb = Object.assign({}, item) as any;
		formData.append("file_VisuraCameraleFile", item.visuraCameraleFile);
		formData.append("file_CartaIdentitaFile", item.cartaIdentitaFile);
		formData.append("file_AutorizzazioneFile", item.autorizzazioneFile);
		formData.append("file_CodiceFiscaleFile", item.codiceFiscaleFile);

		delete ojb.visuraCameraleFile;
		delete ojb.cartaIdentitaFile;
		delete ojb.autorizzazioneFile;
		delete ojb.codiceFiscaleFile;

		ojb.autorizzazioneRegione = ojb.autorizzazioneRegione.text;
		ojb.provincia = ojb.provincia.siglaAutomobilistica;
		ojb.citta = ojb.citta.denominazione;
		ojb.cittaLeg = ojb.cittaLeg && ojb.cittaLeg.denominazione ? ojb.cittaLeg.denominazione || "" : "";
		ojb.provLeg = ojb.provLeg && ojb.provLeg.siglaAutomobilistica ? ojb.provLeg.siglaAutomobilistica || "" : "";

		let json = JSON.stringify(ojb);
		formData.append("data", json);

		return new Promise<operationResult<string>>(function (resolve, reject) {
			self.instance
				.put<operationResult<string>>(`${self.endpoint.MY_OVERINS.REGISTRAZIONE_AGENZIA}`, formData)
				.then(res => resolve(res.data))
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	//#endregion

	//#region ADESIONI
	creaAdesioniDaCarrello(): Promise<operationResult<RispostaAdesioneMultipla>> {
		var self = this;
		return new Promise<operationResult<RispostaAdesioneMultipla>>(function (resolve, reject) {
			self.instance
				.post<operationResult<RispostaAdesioneMultipla>>(`${self.endpoint.CARRELLO.CREA_ADESIONI}`)
				.then(res => resolve(res.data))
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	getTemplatePraticheMultiple(): Promise<TextValueItem[]> {
		var self = this;
		return new Promise<TextValueItem[]>(function (resolve, reject) {
			self.instance
				.get<TextValueItem[]>(`${self.endpoint.CARRELLO.TEMPLATE_PRATICHE_MULTIPLE}`)
				.then(res => resolve(res.data))
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}
	//#endregion

	getCarrello(): Promise<any> {
		var self = this;
		return new Promise(function (resolve, reject) {
			self.instance
				.get<any>(`${self.endpoint.CARRELLO.CARRELLO}`)
				.then(res => {
					if( res && res.data && res.data.length){
						for( var i = 0; i < res.data.length; i++){
							var c = res.data[i];
							if( c.datiCarrello && c.datiCarrello.dati){
								c.datiCarrello.dati.inizioViaggio = c.datiCarrello.dati.inizioViaggio.substring(0,10);
								c.datiCarrello.dati.fineViaggio = c.datiCarrello.dati.fineViaggio.substring(0,10);
								//c.datiCarrello.dati.fineViaggio = moment(c.datiCarrello.dati.fineViaggio).format("YYYY-MM-DD");
							}
						}
					}
					resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	}

	getUtentiRuoli(itemID: number): Promise<Array<number>> {
		var self = this;
		return new Promise(function (resolve, reject) {
			self.instance
				.get<Array<number>>(`utenti/ruoli/${itemID}`)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	}
}

const api = new Api();
export default api;
