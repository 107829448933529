import moment from "moment";
import { Component, Prop, Watch } from "vue-property-decorator";
import baseUserContextComponent from "./baseUserContextComponent";

@Component({})

export default class datePicker extends baseUserContextComponent {
	key: number = 0;
	@Prop({ default: () => null }) value: any;
	@Prop({ default: () => null }) selectionCallBack: any;

	@Prop({ default: () => undefined }) min: Date | string;
	@Prop({ default: () => undefined }) max: Date | string;

	@Prop({ default: () => false }) show: boolean;


	// content: any = null;
	// @Watch('value', { immediate: true })
	// onValuePropChange(newValue): void {
	// 	this.content = newValue;
	// }
	// @Watch('max', { immediate: true })
	// onMaxPropChange(newValue): void {
	// 	this.key++;
	// }
	// @Watch('min', { immediate: true })
	// onMinPropChange(newValue): void {
	// 	this.key++;
	// }
	get content() {
		return this.value;
	}
	// set content(val) { 
	// 	this.$emit('input', val);
	// }
	get minValue(): Date {
		
		//return new Date(2022,4,50);
		//return new Date(this.minValue);
		if (!this.min)
			return new Date();
		if (this.min instanceof Date)
			return this.min;
		return moment(this.min).toDate();
	}
	get maxValue(): Date {
		// return new Date(2022,4,20);
		if (!this.max)
			return new Date(2099, 11, 31);
		if (this.max instanceof Date)
			return this.max;
		return moment(this.max).toDate();
	}

	beforeDestroy() { }
	beforeMount() { }
	mounted() { }
	beforeCreate() { }
	created() {
		this.key = 0;
	}

	onInputDatePickerClick() {
		
	}

	onChangeDate(obj: any) {
		this.key++;
		var value = ((obj || {}).sender || {}).value();

		this.$emit('input', value);

		if (this.selectionCallBack) {
			this.selectionCallBack(value);
		}
	}
}