import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import moment, { Moment } from "moment";
import { TextValueItem } from "@/models/TextValueItem";

@Component({})
export default class headerCellTemplate extends Vue {
	@Prop({ default: () => "" }) field: string;
	@Prop({ default: () => "" }) title: string;
	@Prop({ default: () => false }) sortable: [boolean, object];
	@Prop({ default: () => "" }) sort: any;
	@Prop({ default: () => "" }) sortBy: string;
	@Prop({ default: () => false }) filterable: boolean;
	@Prop({ default: () => false }) isInEditMode: boolean;
	@Prop({ default: () => "string" }) headerType: string;
	@Prop({ default: () => false }) switchValue: boolean;
	@Prop({ default: () => "" }) filterType: string;
	@Prop({ default: () => [] }) itemsForFilter: [];
	//@Prop({ default: () => [] }) filterItemsSelected: any[];
	@Prop({ default: () => false }) showActiveByElements: boolean;
	@Prop({ default: () => "" }) filterTooltipClass: string;
	@Prop({ default: () => "" }) tableName: string;

	show: boolean = false;
	filterText: string = "";
	sortDirection: string = "asc";
	filterMultiSelectText: string = "";
	startDateHeader: Moment | null = null;
	endDateHeader: Moment | null = null;
	localSwitchValue: boolean = false;

	filterItemsSelected: any[] = [];
	filterItemSelected: TextValueItem = null;

	filterShow: boolean = false;
	filterCompareState: any = null;
	filterCompareSelected: string = "contains";
	filterCompareValue2Selected: string = "lt";
	filterTextCompareOptions: any[] = [
		{ id: "contains", name: this.$i18n.t("gridfilter.contains").toString() },
		{ id: "eq", name: this.$i18n.t("gridfilter.eq").toString() },
		{ id: "neq", name: this.$i18n.t("gridfilter.neq").toString() },
		{ id: "startswith", name: this.$i18n.t("gridfilter.startswith").toString() },
		{ id: "doesnotcontain", name: this.$i18n.t("gridfilter.doesnotcontain").toString() },
		{ id: "endswith", name: this.$i18n.t("gridfilter.endswith").toString() }
	];
	filterNumberCompareOptions: any[] = [
		{ id: "eq", name: this.$i18n.t("gridfilter.eq").toString() },
		{ id: "neq", name: this.$i18n.t("gridfilter.neq").toString() },
		{ id: "gte", name: this.$i18n.t("gridfilter.gte").toString() },
		{ id: "gt", name: this.$i18n.t("gridfilter.gt").toString() },
		{ id: "lte", name: this.$i18n.t("gridfilter.lte").toString() },
		{ id: "lt", name: this.$i18n.t("gridfilter.lt").toString() }
	];
	filterSelectCompareOptions: any[] = [
		{ id: "eq", name: this.$i18n.t("gridfilter.eq").toString() },
		{ id: "neq", name: this.$i18n.t("gridfilter.neq").toString() }
	];
	filterDateCompareOptions: any[] = [
		{ id: "gt", name: this.$i18n.t("gridfilter.dategt").toString() },
		{ id: "lt", name: this.$i18n.t("gridfilter.datelt").toString() },
		{ id: "gte", name: this.$i18n.t("gridfilter.dategte").toString() },
		{ id: "lte", name: this.$i18n.t("gridfilter.datelte").toString() }
	];

	datePickerFooter: string = "Oggi - #=kendo.toString(data, 'dd MM yyyy') #";
	filterRadioOptions: any = [
		{ text: "SI", value: true },
		{ text: "NO", value: false }
	];
	filterRadioButtonSelected: any = null;
	isFilterActive: boolean = false;

	@Watch("switchValue")
	onSwitchValueChange(newValue: boolean) {
		this.localSwitchValue = newValue;
	}
	@Watch("filterItemsSelected")
	onFilterItemsSelectedChange(items: []) {
		if (items && items.length == 0 && this.isFilterActive) this.isFilterActive = false;
	}

	created() {
		if (this.filterable) {
			switch (this.filterTypeHeader) {
				case "select":
				case "numeric":
				case "numeric-int":
					this.filterCompareSelected = "eq";
					break;
				case "daterange":
				case "datetimerange":
					this.filterCompareSelected = "gt";
				default:
					break;
			}
		}
	}

	startDateChange(item) {
		if (item.sender.value()) {
			var newStartDate = moment(item.sender.value());
			if (newStartDate > moment(this.endDateHeader)) {
				this.endDateHeader = newStartDate;
			}
			this.startDateHeader = newStartDate;
		} else {
			this.startDateHeader = null;
		}
	}

	endDateChange(item) {
		if (item.sender.value()) {
			var newEndDate = moment(item.sender.value());
			if (newEndDate < moment(this.startDateHeader)) {
				this.startDateHeader = newEndDate;
			}
			this.endDateHeader = newEndDate.endOf("day");
		} else {
			this.endDateHeader = null;
		}
	}
	startDateTimeChange(item) {
		var newStartDate = moment(item.sender.value());
		// if (newStartDate > moment(this.endDateHeader)) {
		// 	this.endDateHeader = newStartDate;
		// }
		this.startDateHeader = newStartDate;
	}

	endDateTimeChange(item) {
		var newEndDate = moment(item.sender.value());
		// if (newEndDate < moment(this.startDateHeader)) {
		// 	this.startDateHeader = newEndDate;
		// }
		this.endDateHeader = newEndDate;
	}
	onSortChange(e) {
		if (this.sortable) this.$emit("sort-changed", this);
	}
	onFilterApply(e) {
		this.$emit("filter-apply", this);
		this.isFilterActive = true;
		this.filterShow = false;
	}
	onFilterRemove(e) {
		this.$emit("filter-remove", this);
		this.filterText = "";
		this.startDateHeader = null;
		this.endDateHeader = null;
		this.filterRadioButtonSelected = null;

		this.show = false;
		this.isFilterActive = false;

		this.filterShow = false;
	}
	switchChange() {
		this.$emit("header-switch-change", this.localSwitchValue);
	}
	switchImageChange() {
		if (!this.isInEditMode) this.$emit("header-switch-change", !this.localSwitchValue);
	}
	changeItemSelection(item: any) {
		if (item.selected) {
			var i = this.filterItemsSelected.findIndex(x => {
				return x == item.id;
			});

			if (i >= 0) {
				this.filterItemsSelected.splice(i, 1);
			}
		} else {
			this.filterItemsSelected.push(item.id);
		}
	}

	get isFilterActiveComp() {
		return this.isFilterActive || (this.showActiveByElements && this.filterItemsSelected && this.filterItemsSelected.length > 0);
	}
	get customTooltipFilterClass() {
		return `${this.filterTooltipClass} ${this.filterType == "boolean" ? " smallInT" : ""}`;
	}
	get startDateString() {
		if (this.startDateHeader) {
			return moment(this.startDateHeader).format("DD/MM/YYYY");
		}

		return "";
	}
	get endDateString() {
		if (this.endDateHeader) {
			return moment(this.endDateHeader).format("DD/MM/YYYY");
		}

		return "";
	}
	get startDateTimeString() {
		if (this.startDateHeader) {
			return moment(this.startDateHeader).format("DD/MM/YYYY HH:mm");
		}

		return "";
	}
	get endDateTimeString() {
		if (this.endDateHeader) {
			return moment(this.endDateHeader).format("DD/MM/YYYY HH:mm");
		}

		return "";
	}
	filterItems() {
		var self = this;
		var itemsToShow = [];

		var itemsFilteredBySelectFilter: any = this.itemsForFilter;

		if (self.filterMultiSelectText) {
			itemsFilteredBySelectFilter = itemsFilteredBySelectFilter.filter(function (o) {
				return o.value.toLowerCase().indexOf(self.filterMultiSelectText.toLowerCase()) >= 0;
			});
		}

		for (var i = 0; i < itemsFilteredBySelectFilter.length; i++) {
			var item = itemsFilteredBySelectFilter[i];
			var isSelected =
				this.filterItemsSelected.findIndex(x => {
					return x == item.id;
				}) >= 0;

			itemsToShow.push({
				id: item.id,
				value: item.value,
				selected: isSelected
			});
		}

		return itemsToShow;
	}
	onFilterSelectChange(event: any) {

	}
	get showSort() {
		if (this.sortable && this.sort) {
			var self = this;
			var field = this.sortBy || this.field;
			if (Array.isArray(this.sort)) {
				var findField = this.sort.find(function (item) {
					return item.field === field;
				});
				if (findField) {
					this.sortDirection = findField.dir;
					return true;
				}
			} else {
				if (this.sort.field == this.field) {
					this.sortDirection = this.sort.dir;
					return true;
				}
			}
		}

		return false;
	}

	get filterTypeHeader() {
		return this.filterType || this.headerType;
	}
}
