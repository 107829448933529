import permessi, { AttributiUtente } from "@/config/permessi";
import Vue from "vue";
import VueRouter from "vue-router";
// import Home from "../views/Home.vue";
Vue.use(VueRouter);
export const routes = [
	{
		path: "/",
		name: "home",
		redirect: { name: "carrelloCategorie" },
		component: function() {
			return import("../pages/home.vue");
		}
	},
	{
		path: "/terminiecondizioni",
		name: "terminiecondizioni",
		component: function() {
			return import("../pages/terminiecondizioni.vue");
		},
		meta: { layout: "empty-layout", allowanonymous: true }
	},
	{
		path: "/login",
		name: "login",
		component: function() {
			return import("../pages/login.vue");
		},
		meta: { layout: "empty-layout", allowanonymous: true }
	},
	{
		path: "/register",
		name: "register",
		component: function() {
			return import("../pages/register.vue");
		},
		meta: { layout: "empty-layout", allowanonymous: true }
	},
	{
		path: "/password-recover",
		name: "password_recover",
		component: function() {
			return import("../pages/passwordRecover.vue");
		},
		meta: { layout: "empty-layout", allowanonymous: true }
	},
	{
		path: "/change-password",
		name: "change_password",
		component: function() {
			return import("../pages/changePassword.vue");
		},
		meta: { layout: "empty-layout", allowanonymous: true }
	},
	{
		path: "/set-password",
		name: "set_password",
		component: function() {
			return import("../pages/changeFirstPassword.vue");
		},
		meta: { layout: "empty-layout", allowanonymous: true }
	},
	{
		path: "/confirm-email",
		name: "confirm_email",
		component: function() {
			return import("../pages/confirmEmail.vue");
		},
		meta: { layout: "empty-layout", allowanonymous: true }
	},
	{
		path: "/adesioni/file",
		name: "adesioni_download",
		component: function() {
			return import("../pages/adesioni-download.vue");
		},
		meta: { layout: "empty-layout", allowanonymous: true }
	},
	{
		path: "/agenzie/file",
		name: "agenzie_download",
		component: function() {
			return import("../pages/agenzie-download.vue");
		},
		meta: { layout: "empty-layout", allowanonymous: true }
	},
	{
		path: "/keyclient",
		redirect: { name: "ritorno-ko" },
		name: "keyclient",
		component: function() {
			return import("../pages/contenitoreEmpty.vue");
		},
		meta: { layout: "empty-layout" },
		children: [
			{
				path: "ritorno-ok",
				name: "ritorno-ok",
				component: function() {
					return import("../pages/keyClient/ritornoOk.vue");
				},
				meta: { layout: "empty-layout" }
			},
			{
				path: "ritorno-fm-ok",
				name: "ritorno-fm-ok",
				component: function() {
					return import("../pages/keyClient/ritornoFineMeseOk.vue");
				},
				meta: { layout: "empty-layout" }
			},
			{
				path: "ritorno-ko",
				name: "ritorno-ko",
				component: function() {
					return import("../pages/keyClient/ritornoKo.vue");
				},
				meta: { layout: "empty-layout" }
			}
		]
	},
	{
		path: "/carrello",
		redirect: { name: "carrelloCategorie" },
		name: "carrelloHome",
		component: function() {
			return import("../pages/contenitoreEmpty.vue");
		},
		meta: {
			layout: "carrello-layout",
			label: "navbar.carrello",
			// color: "app-color1",
			topNav: { label: "navbar.carrello", displayOrder: 1 },
			mustPay: true
		},
		children: [
			{
				path: "carrelloCategorie",
				name: "carrelloCategorie",
				component: function() {
					return import("../pages/carrello/carrelloCategorie.vue");
				},
				meta: {
					permissions: [permessi.WEB.Anagrafica.ACCESSO_CARRELLO],
					layout: "carrello-layout",
					label: "navbar.carrello",
					topSubNav: { label: "navbar.carrello", displayOrder: 1 }
				}
			},
			{
				path: "preventivatore",
				name: "preventivatore",
				component: function() {
					return import("../pages/carrello/preventivatore.vue");
				},
				meta: {
					permissions: [permessi.WEB.Anagrafica.ACCESSO_CARRELLO],
					layout: "carrello-layout",
					label: "navbar.carrello",
					topSubNav: { label: "navbar.carrello", displayOrder: 2 }
				}
			},
			{
				path: "listaProdotti",
				name: "listaProdotti",
				component: function() {
					return import("../pages/carrello/carrelloListaProdotti.vue");
				},
				props: true,

				meta: {
					permissions: [permessi.WEB.Anagrafica.ACCESSO_CARRELLO],
					layout: "carrello-layout",
					label: "navbar.carrello",
					topSubNav: { label: "navbar.carrello", displayOrder: 3 }
				}
			},
			{
				path: "infoProdotto",
				name: "infoProdotto",
				component: function() {
					return import("../pages/carrello/infoProdotto.vue");
				},
				props: { inModifica: false },
				meta: {
					permissions: [permessi.WEB.Anagrafica.ACCESSO_CARRELLO],
					layout: "carrello-layout",
					label: "navbar.carrello",
					topSubNav: { label: "navbar.carrello", displayOrder: 4 }
				}
			}
		]
	},
	// {
	// 	path: "/pratichemultiple",
	// 	redirect: { name: 'elaboraPraticheMultiple' },
	// 	name: "praticheMultiple",
	// 	component: function () {
	// 		return import("../pages/contenitoreEmpty.vue");
	// 	},
	// 	meta: {
	// 		layout: "carrello-layout",
	// 		label: "navbar.praticheMultiple",
	// 		// color: "app-color1",
	// 		topNav: { label: "navbar.praticheMultiple", displayOrder: 2 }
	// 	},
	// 	children: [
	// 		{
	// 			path: "elabora",
	// 			name: "elaboraPraticheMultiple",
	// 			component: function () {
	// 				return import("../pages/praticheMultiple/importaPratiche.vue");
	// 			},
	// 			meta: {
	// 				permissions: [permessi.WEB.Anagrafica.ACCESSO_CARRELLO],
	// 				layout: "default-layout",
	// 				label: "navbar.praticheMultiple",
	// 				topSubNav: { label: "navbar.praticheMultiple", displayOrder: 1 }
	// 			}
	// 		}
	// 	]
	// },
	{
		path: "/mywebins",
		redirect: { name: "myoverins_tuoiAcquisti" },
		name: "myoverins",
		component: function() {
			return import("../pages/contenitoreEmpty.vue");
		},
		meta: {
			layout: "sub-menu-layout",
			label: "navbar.myoverins",
			color: "app-color1",
			topNav: { label: "navbar.myoverins", displayOrder: 3 }
		},
		children: [
			{
				path: "acquistiPolizze",
				name: "myoverins_acquistiPolizze",
				component: function() {
					return import("../pages/acquistiPolizze.vue");
				},
				meta: {
					permissions: [permessi.WEB.MyWebins.ACQUISTI_POLIZZE],
					attributiUtente: [AttributiUtente.fornitore],
					layout: "sub-menu-layout",
					label: "navbar.acquistiPolizze",
					topSubNav: { label: "navbar.acquistiPolizze", displayOrder: 1 }
				}
			},
			// {
			// 	path: "analisiDati",
			// 	name: "myoverins_analisiDati",
			// 	component: function () {
			// 		return import("../pages/analisiDati.vue");
			// 	},
			// 	meta: {
			// 		permissions: [permessi.WEB.Anagrafica.ACCESSO_CARRELLO],
			// 		layout: "sub-menu-layout",
			// 		label: "navbar.analisiDati",
			// 		topSubNav: { label: "navbar.analisiDati", displayOrder: 2 }
			// 	}
			// },
			{
				path: "tuoiAcquisti",
				name: "myoverins_tuoiAcquisti",
				component: function() {
					return import("../pages/tuoiAcquisti.vue");
				},
				meta: {
					permissions: [permessi.WEB.MyWebins.TUOI_ACQUISTI],
					attributiUtente: [AttributiUtente.distributore_agenziadettagliante, AttributiUtente.distributore_touroperator, AttributiUtente.distributore_network],
					label: "navbar.tuoiAcquisti",
					layout: "sub-menu-layout",
					topSubNav: { label: "navbar.tuoiAcquisti", displayOrder: 3 }
				}
			},
			{
				path: "commercialeDistributori",
				name: "myoverins_commercialeDistributori",
				component: function() {
					return import("../pages/commercialeDistributori.vue");
				},
				meta: {
					permissions: [permessi.WEB.MyWebins.COMMERCIALE_DISTRIBUTORI],
					attributiUtente: [AttributiUtente.commerciale],
					label: "navbar.commercialeDistributori",
					layout: "sub-menu-layout",
					topSubNav: { label: "navbar.commercialeDistributori", displayOrder: 4 }
				}
			},
			{
				path: "tuoiCrediti",
				name: "myoverins_tuoiCrediti",
				component: function() {
					return import("../pages/tuoiCrediti.vue");
				},
				meta: {
					permissions: [permessi.WEB.MyWebins.CREDITI],
					attributiUtente: [AttributiUtente.distributore_agenziadettagliante, AttributiUtente.distributore_touroperator, AttributiUtente.distributore_network],
					label: "navbar.tuoiCrediti",
					layout: "sub-menu-layout",
					topSubNav: { label: "navbar.tuoiCrediti", displayOrder: 5 }
				}
			},
			{
				path: "acquistiNetwork",
				name: "myoverins_acquistiNetwork",
				component: function() {
					return import("../pages/acquistiNetwork.vue");
				},
				meta: {
					permissions: [permessi.WEB.MyWebins.ACQUISTI_NETWORK],
					attributiUtente: [AttributiUtente.distributore_network],
					label: "navbar.acquistiNetwork",
					layout: "sub-menu-layout",
					topSubNav: { label: "navbar.acquistiNetwork", displayOrder: 6 }
				}
			},
			{
				path: "estrattiContoNetwork",
				name: "myoverins_estrattiContoNetwork",
				component: function() {
					return import("../pages/estrattiContoNetwork.vue");
				},
				meta: {
					permissions: [permessi.WEB.MyWebins.ESTRATTICONTO_NETWORK],
					attributiUtente: [AttributiUtente.distributore_network],
					label: "navbar.estrattiContoNetwork",
					layout: "sub-menu-layout",
					topSubNav: { label: "navbar.estrattiContoNetwork", displayOrder: 7 }
				}
			},
			{
				path: "tuoiPagamenti",
				name: "myoverins_tuoiPagamenti",
				component: function() {
					return import("../pages/tuoiPagamenti.vue");
				},
				meta: {
					permissions: [permessi.WEB.MyWebins.PAGAMENTI],
					attributiUtente: [AttributiUtente.distributore_agenziadettagliante, AttributiUtente.distributore_touroperator, AttributiUtente.distributore_network],
					label: "navbar.tuoiPagamenti",
					layout: "sub-menu-layout",
					topSubNav: { label: "navbar.tuoiPagamenti", displayOrder: 8 }
				}
			},
			{
				path: "tuoiEstrattiConto",
				name: "myoverins_tuoiEstrattiConto",
				component: function() {
					return import("../pages/tuoiEstrattiConto.vue");
				},
				meta: {
					permissions: [permessi.WEB.MyWebins.ESTRATTICONTO],
					attributiUtente: [AttributiUtente.distributore_agenziadettagliante, AttributiUtente.distributore_touroperator, AttributiUtente.distributore_network],
					label: "navbar.tuoiEstrattiConto",
					layout: "sub-menu-layout",
					topSubNav: { label: "navbar.tuoiEstrattiConto", displayOrder: 9 }
				}
			},
			{
				path: "tuoiUtenti",
				name: "myoverins_tuoiUtenti",
				component: function() {
					return import("../pages/tuoiUtenti.vue");
				},
				meta: {
					permissions: [permessi.WEB.MyWebins.UTENTI],
					label: "navbar.tuoiUtenti",
					layout: "sub-menu-layout",
					topSubNav: { label: "navbar.tuoiUtenti", displayOrder: 10 }
				}
			}
		]
	},
	{
		path: "/praticheXML",
		name: "praticheXML",
		component: function() {
			return import("../pages//praticheXML/praticheXML.vue");
		},
		meta: { layout: "empty-layout", allowanonymous: true }
	}
	/*{
		path: "/modificaPratica",
		redirect: { name: 'modificaPraticaPage' },
		name: "modificaPratica",
		component: function () {
			return import("../pages/contenitoreEmpty.vue");
		},
		meta: {
			layout: "sub-menu-layout",
			label: "navbar.modificaPratica",
			color: "app-color1",
			topNav: { label: "navbar.modificaPratica", displayOrder: 4 }
		},
		children: [
			{
				path: "modificaPratica",
				name: "modificaPraticaPage",
				component: function () {
					return import("../pages/modificaPratica/modificaPratica.vue");
				},
				meta: {
					permissions: [permessi.WEB.Anagrafica.ACCESSO_CARRELLO],
					attributiUtente: [AttributiUtente.fornitore],
					layout: "sub-menu-layout",
					label: "navbar.modificaPratica",
					topSubNav: { label: "navbar.modificaPratica", displayOrder: 1 }
				}
			}
		]
	}*/
	/*
	{
		path: "/commerciale",
		name: "commerciale",
		redirect: { name: 'commerciale_commerciali' },
		component: function() {
			return import("../pages/contenitoreEmpty.vue");
		},
		meta: {
			label: "navbar.commerciale",
			color: "app-color2",
			topNav: { label: "navbar.ommerciale", displayOrder: 2 }
		},
		children: [
			{
				path: "commerciali",
				name: "commerciale_commerciali",
				component: function() {
					return import("../pages/commerciale/anagraficaCommerciale/commerciali.vue");
				},
				meta: {
					layout: "sub-menu-layout",
					label: "navbar.commerciali",
					allowanonymous: true,
					topSubNav: {
						label: "navbar.commerciali",
						displayOrder: 1
					}
				}
			},
			{
				path: "commissioni",
				name: "commerciale_commissioni",
				component: function() {
					return import("../pages/commerciale/commissioni/commissioni.vue");
				},
				meta: {
					layout: "sub-menu-layout",
					label: "navbar.commissioni",
					allowanonymous: true,
					topSubNav: { label: "navbar.commissioni", displayOrder: 2 }
				}
			},
			{
				path: "analisi-dati",
				name: "commerciale_analisiDati",
				component: function() {
					return import("../pages/commerciale/analisiCommissioni/analisiCommissioni.vue");
				},
				meta: {
					layout: "sub-menu-layout",
					label: "navbar.analisiDati",
					topSubNav: { label: "navbar.analisiDati", displayOrder: 3 }
				}
			}
		]
	},
	{
		path: "/gestione-prodotti",
		name: "gestioneProdotti",
		redirect: { name: 'gestioneProdotti_polize' },
		component: function() {
			return import("../pages/contenitoreEmpty.vue");
		},
		meta: {
			label: "navbar.gestioneProdotti",
			color: "app-color3",
			topNav: { label: "navbar.gestioneProdotti", displayOrder: 3 }
		},
		children: [
			{
				path: "polize",
				name: "gestioneProdotti_polize",
				component: function() {
					return import("../pages/gestioneProdotti/polize/polize.vue");
				},
				meta: {
					layout: "sub-menu-layout",
					label: "navbar.polize",
					allowanonymous: true,
					topSubNav: {
						label: "navbar.polize",
						displayOrder: 1
					}
				}
			},
			{
				path: "prodotti",
				name: "gestioneProdotti_prodotti",
				component: function() {
					return import("../pages/gestioneProdotti/prodotti/prodotti.vue");
				},
				meta: {
					layout: "sub-menu-layout",
					label: "navbar.prodotti",
					allowanonymous: true,
					topSubNav: { label: "navbar.prodotti", displayOrder: 2 }
				}
			},
		]
	},
	{
		path: "/gestione-vendite",
		redirect: { name: 'gestioneVendite_ricercaGaranzie' },
		name: "gestioneVendite",
		component: function() {
			return import("../pages/contenitoreEmpty.vue");
		},
		meta: {
			layout: "sub-menu-layout",
			label: "navbar.gestioneVendite",
			color: "app-color4",
			topNav: { label: "navbar.gestioneVendite", displayOrder: 4 }
		},
		children: [
			{
				path: "ricerca-garanzie",
				name: "gestioneVendite_ricercaGaranzie",
				component: function() {
					return import("../pages/gestioneVendite/ricercaGaranzie/ricercaGaranzie.vue");
				},
				meta: {
					layout: "sub-menu-layout",
					label: "navbar.ricercaGaranzie",
					topSubNav: { label: "navbar.ricercaGaranzie", displayOrder: 1 }
				}
			},
			{
				path: "analisi-avanzata",
				name: "gestioneVendite_analisiAvanzata",
				component: function() {
					return import("../pages/gestioneVendite/distributori.vue");
				},
				meta: {
					layout: "sub-menu-layout",
					label: "navbar.analisiAvanzata",
					topSubNav: { label: "navbar.analisiAvanzata", displayOrder: 2 }
				}
			},
			// {
			// 	path: "utenti",
			// 	name: "gestioneVendite_utenti",
			// 	component: function() {
			// 		return import("../pages/gestioneVendite/utenti.vue");
			// 	},
			// 	meta: {
			// 		label: "navbar.tenti",
			// 		layout: "sub-menu-layout",
			// 		topSubNav: { label: "navbar.tenti", displayOrder: 3 }
			// 	}
			// },
			{
				path: "storicoReportFornitore",
				name: "gestioneVendite_storicoReportFornitore",
				component: function() {
					return import("../pages/gestioneVendite/storicoInviiReportFornitore/storicoInviiReportFornitore.vue");
				},
				meta: {
					label: "navbar.storicoReportFornitore",
					layout: "sub-menu-layout",
					topSubNav: { label: "navbar.storicoReportFornitore", displayOrder: 4 }
				}
			},
			{
				path: "reportFornitore",
				name: "gestioneVendite_reportFornitore",
				component: function() {
					return import("../pages/gestioneVendite/reportFornitore/reportFornitore.vue");
				},
				meta: {
					label: "navbar.reportFornitore",
					layout: "sub-menu-layout",
					topSubNav: { label: "navbar.reportFornitore", displayOrder: 4 }
				}
			},
			{
				path: "report-vendite",
				name: "gestioneVendite_reportVendite",
				component: function() {
					return import("../pages/gestioneVendite/elencoCoupon.vue");
				},
				meta: {
					label: "navbar.reportVendite",
					layout: "sub-menu-layout",
					topSubNav: { label: "navbar.reportVendite", displayOrder: 5 }
				}
			},
			// {
			// 	path: "agenzie",
			// 	name: "gestioneVendite_agenzie",
			// 	component: function() {
			// 		return import("../pages/gestioneVendite/agenzie.vue");
			// 	},
			// 	meta: {
			// 		label: "navbar.agenzie",
			// 		layout: "sub-menu-layout",
			// 		topSubNav: { label: "navbar.agenzie", displayOrder: 6 }
			// 	}
			// }
		]
	},
	{
		path: "/gestione-pagamenti",
		name: "gestionePagamenti",
		redirect: { name: 'gestionePagamenti_estrattiConto' },
		component: function() {
			return import("../pages/contenitoreEmpty.vue");
		},
		meta: {
			label: "navbar.gestionePagamenti",
			color: "app-color5",
			topNav: { label: "navbar.gestionePagamenti", displayOrder: 5 }
		},
		children: [
			{
				path: "estratti-conto",
				name: "gestionePagamenti_estrattiConto",
				component: function() {
					return import("../pages/gestionePagamenti/estrattiConto.vue");
				},
				meta: {
					layout: "sub-menu-layout",
					label: "navbar.estrattiConto",
					allowanonymous: true,
					topSubNav: {
						label: "navbar.estrattiConto",
						displayOrder: 1
					}
				}
			},
			{
				path: "registrazione-pagamenti",
				name: "gestionePagamenti_registrazionePagamenti",
				component: function() {
					return import("../pages/gestionePagamenti/registrazionePagamenti.vue");
				},
				meta: {
					layout: "sub-menu-layout",
					label: "navbar.registrazionePagamenti",
					allowanonymous: true,
					topSubNav: { label: "navbar.registrazionePagamenti", displayOrder: 2 }
				}
			},
		]
	}
	*/
];
var router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes: routes,
	scrollBehavior(to, from, savedPosition) {
		if (document.getElementsByClassName('app-content') && document.getElementsByClassName('app-content').length) {
			if (document.getElementsByClassName('app-content')[0].children && document.getElementsByClassName('app-content')[0].children.length) {
				document.getElementsByClassName('app-content')[0].children[0].scrollIntoView();
			}
		}
		
		return { left: 0, top: 0 } as any;
	}
});
export default router;
