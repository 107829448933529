import helpers from "@/helpers";
import { datiViaggio } from "@/models/carrello/datiViaggio";
import { CarrelloInfoProdotto } from "@/models/carrello/infoProdotto";
import { PartecipantiCarrello } from "@/models/carrello/partecipantiCarrello";
import api from "@/services/api";
import { watch } from "fs";
import { Component, Prop, Watch } from "vue-property-decorator";
import { mapMutations, mapState } from "vuex";
import baseContextComponent from "./baseContextComponent";
import baseUserContextComponent from "./baseUserContextComponent";
import gridComponentBase from "./grid/gridComponentBase.vue";

const _ENDPONT_EXCEL = api.endpoint.CARRELLO.EXCEL_PARTECIPANTI,
	_ENDPONT_FILE = api.endpoint.CARRELLO.FILE_PARTECIPANTI;

@Component({
	computed: {
		...mapState({
			datiViaggio: (state: any) => state.datiViaggio,
			prodottoSelezionato: (state: any) => state.prodottoSelezionato,
			width: (state: any) => state.width
		})
	},
	methods: {
		...mapMutations(["setDatiViaggio"])
	}
})
export default class grigliaPartecipanti extends baseContextComponent {
	gridData: PartecipantiCarrello[] = [];
	gridPage: PartecipantiCarrello[] = [];
	currentPage: number = 1;
	pageSize: number = 10;
	immaginePartecipantiGuid: string = null;
	tesseraSingola: boolean = false;
	hideTesseraSingola: boolean = false;
	filePartecipanti: any = null;
	imagePartecipanti: any = null;

	width!: number;

	@Prop({ default: () => null }) partecipanti: string;

	prodottoSelezionato!: CarrelloInfoProdotto;
	datiViaggio!: datiViaggio;
	setDatiViaggio!: (setDatiViaggio: datiViaggio) => void;

	@Watch("currentPage", { immediate: true })
	async changeScrollGrid() {
		this.scrollGrid();
	}
/*
	@Watch("partecipanti", { immediate: true })
	async updateNumeroPartecipanti() {
		debugger
		this.onUpdateNumeroPartecipanti();
	}*/

	updateDatiPartecipanti() {
		this.datiViaggio.partecipanti = this.gridData;
		this.setDatiViaggio(this.datiViaggio);
	}

	updateTesseraSingola() {
		this.datiViaggio.tesseraSingola = this.tesseraSingola;
		this.setDatiViaggio(this.datiViaggio);
	}

	updateImmaginePartecipanti() {
		this.datiViaggio.immaginePartecipanti = this.immaginePartecipantiGuid;
		if (this.datiViaggio.immaginePartecipanti) {
			this.hideTesseraSingola = true;
			this.tesseraSingola = false;
			this.datiViaggio.tesseraSingola = false;
		} else this.hideTesseraSingola = false;
		this.setDatiViaggio(this.datiViaggio);
		this.scrollGrid();
	}

	created() {
		this.initPartecipanti();
	}

	initPartecipanti() {
		if (this.datiViaggio && this.datiViaggio.partecipanti && this.datiViaggio.partecipanti.length) {
			this.gridData = this.datiViaggio.partecipanti;
		} else {
			this.getData();
		}
		if (this.datiViaggio && this.datiViaggio.tesseraSingola) this.tesseraSingola = this.datiViaggio.tesseraSingola;
		if (this.datiViaggio && this.datiViaggio.immaginePartecipanti) {
			this.immaginePartecipantiGuid = this.datiViaggio.immaginePartecipanti;
			this.hideTesseraSingola = true;
		} else this.hideTesseraSingola = false;
		this.scrollGrid();
		this.updateDatiPartecipanti();
	}

	beforeDestroy() {
		this.$root.$off("updatePartecipanti");
		this.$root.$off("changeNumeroPartecipanti");
	}
	mounted() {
		var self = this;
		this.$root.$on("updatePartecipanti", () => {
			self.initPartecipanti();
		});
		this.$root.$on("changeNumeroPartecipanti", () => {
			self.onUpdateNumeroPartecipanti();
		});
	}

	getData() {
		var array = [],
			len = parseInt(this.partecipanti);
		if (len < 1) len = 1;
		for (var i = 0; i < len; i++) {
			var obj = new PartecipantiCarrello();
			obj.ItemID = i + 1;
			obj.Cognome = "";
			obj.Nome = "";
			obj.CodiceFiscale = "";
			obj.DataNascita = null;
			array[i] = obj;
		}
		this.gridData = array;
	}

	onUpdateNumeroPartecipanti() {
		var valore = parseInt(this.partecipanti),
			ds = this.gridData;

		if (valore < 1) valore = 1;
		/*if (!valore) {
			valore = postiMin;
			this.value(valore);
		}*/

		var len = ds.length;
		if (valore < len) {
			for (var i = valore; i < len; i++) ds.pop();
			//ds.remove(ds.at(ds.data().length - 1));
		} else {
			for (var i = len; i < valore; i++) {
				//if (that.imageFile) // se c'è un' immagine, aggiungo i trattini
				//	ds.add({ ItemID: i + 1, Cognome: '---', Nome: '---', CodiceFiscale: '', DataNascita: ''});
				//else
				//ds.add({ ItemID: i + 1 });
				var obj = new PartecipantiCarrello();
				obj.ItemID = i + 1;
				obj.Nome = "";
				obj.Cognome = "";
				obj.CodiceFiscale = "";
				obj.DataNascita = null;

				ds.push(obj);
			}
		}

		this.currentPage = 1;
		this.scrollGrid();
	}

	scrollGrid() {
		this.gridPage = [];
		if (this.immaginePartecipantiGuid) {
			this.gridPage.push(this.gridData[0]);
			return;
		}

		if (this.isAppMobile == false) {
			for (var i = (this.currentPage - 1) * this.pageSize; i < this.currentPage * this.pageSize; i++) {
				if (i >= this.gridData.length) break;
				this.gridPage.push(this.gridData[i]);
			}
		}
		else {
			for (var i = 0; i < this.gridData.length; i++) {
				this.gridPage.push(this.gridData[i]);
			}
		}
	}

	cancellaTutti() {
		this.getData();
		this.scrollGrid();
	}

	onInputFileValue(value: File) {
		var self = this;
		this.filePartecipanti = value;

		if (!value) return;

		var formData = new FormData();
		formData.append("filePartecipanti", value);

		api.postFormData(_ENDPONT_EXCEL, formData).then(res => {
			if (!res) return;

			var dati = res.data,
				currData = self.gridData,
				nuoviDati = [] as PartecipantiCarrello[],
				j = 0;

			if (!dati || !dati.length) {
				var msg = "Verificare il file caricato: non risultano partecipanti presenti al suo interno";
				this.$bvToast.toast(msg, { variant: "danger", title: this.$i18n.t("msg.erroreGenerico").toString(), solid: true });
				return;
			}

			for (var i = 0; i < currData.length; i++) {
				if (currData[i].Nome && currData[i].Cognome) {
					nuoviDati.push(helpers.cloneObject(currData[i]));
					nuoviDati[j].ItemID = ++j;
				}
			}

			var currLength = nuoviDati.length + 1;
			for (var i = 0; i < dati.length; i++) {
				var obj = dati[i];
				nuoviDati.push({ ItemID: currLength + i, Cognome: obj.cognome, Nome: obj.nome, CodiceFiscale: obj.codiceFiscale, DataNascita: obj.dataNascita });
			}

			self.gridData = nuoviDati;
			self.datiViaggio.partecipanti = self.gridData;

			(self.$parent as any).setNumeroPartecipanti(nuoviDati.length);

			setTimeout(() => {
				self.onUpdateNumeroPartecipanti();
			}, 1000);
		});

		setTimeout(function() {
			self.filePartecipanti = null;
		}, 3000);
	}

	onInputImageValue(value: File) {
		var self = this;

		if (!value) return;

		var formData = new FormData();
		formData.append("imgPartecipanti", value);

		api.postFormData(_ENDPONT_FILE, formData).then(res => {
			if (!res || !res.data) return;

			self.imagePartecipanti = value;
			self.immaginePartecipantiGuid = res.data;
			self.updateImmaginePartecipanti();

			/// DOPO AVER FATTO UPLOAD AZZERO TUTTI I VALORI
			self.cancellaTutti();

			setTimeout(function() {
				self.imagePartecipanti = null;
			}, 3000);
		});
	}

	onRimuoviFile() {
		var self = this;

		api.delete(`${_ENDPONT_FILE}`, this.immaginePartecipantiGuid).then(res => {
			if (!res || !res.data) return;

			self.immaginePartecipantiGuid = null;
			self.immaginePartecipantiGuid = "";
			self.updateImmaginePartecipanti();
		});
	}
}
