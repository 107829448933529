import { appRoute } from "@/models/appRoute";
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { mapActions, mapGetters, mapMutations } from "vuex";
import * as s from "@/router/router";
import { Route } from "vue-router";



class brkpoints {
	xs: number;
	sm: number;
	md: number;
	lg: number;
	xl: number;
}

// copied
// $grid-breakpoints: (
//   xs: 0,
//   sm: 576px,
//   md: 768px,
//   lg: 992px,
//   xl: 1200px
// ) !default;
const bootstrap_4_5_Breakpoints = {
	xs: 0,
	sm: 576,
	md: 768,
	lg: 992,
	xl: 1200
};

const bootstrap_4_5_Breakpoints_ratio2 = {
	xs: 0,
	sm: 1152,
	md: 1536,
	lg: 1984,
	xl: 2100
};

const brkp_mobile: number = 787;
const brkp_mobile_large: number = 1200;
const brkp: brkpoints = bootstrap_4_5_Breakpoints;
const brkpr2: brkpoints = bootstrap_4_5_Breakpoints_ratio2;

@Component({
	computed: {
		...mapGetters([

			"appWidth",
			"getAppCulture",

			"getRequestCount",
			// "getIsAnyRequestPanding"
		])
	}
})
export default class baseContextComponent extends Vue {
	routerGoBack() {
		this.$router.go(-1);
	}
	viewScrollOnTop(refName: string) {
		if (refName != null && this.$refs[refName] != null) {
			var elem = this.$refs[refName];
			(elem as any).scrollTo(0, 0);
		}
		else
			window.scrollTo(0, 0);
	}
	getRequestCount: number;
	// getIsAnyRequestPanding: boolean;
	getAppCulture: string;

	appWidth: number;

	get xs(): boolean {
		return true;
	}
	get xsOnly(): boolean {
		return this.appWidth < brkp.sm;
	}
	get xsAndUp(): boolean {
		return true;
	}
	get xsAndDown(): boolean {
		return this.appWidth < brkp.sm;
	}
	get sm(): boolean {
		return this.appWidth >= brkp.sm;
	}
	get smOnly(): boolean {
		return this.appWidth >= brkp.sm && this.appWidth < brkp.md;
	}
	get smAndUp(): boolean {
		return this.appWidth >= brkp.sm;
	}
	get smAndDown(): boolean {
		return this.appWidth < brkp.md;
	}
	get md(): boolean {
		return this.appWidth >= brkp.md;
	}
	get mdOnly(): boolean {
		return this.appWidth >= brkp.md && this.appWidth < brkp.lg;
	}
	get mdAndUp(): boolean {
		return this.appWidth >= brkp.md;
	}
	get mdAndDown(): boolean {
		return this.appWidth < brkp.lg;
	}
	get lg(): boolean {
		return this.appWidth >= brkp.lg;
	}
	get lgOnly(): boolean {
		return this.appWidth >= brkp.lg && this.appWidth < brkp.xl;
	}
	get lgAndUp(): boolean {
		return this.appWidth >= brkp.lg;
	}
	get lgAndDown(): boolean {
		return this.appWidth < brkp.xl;
	}
	get xl(): boolean {
		return this.appWidth >= brkp.xl;
	}
	get xlOnly(): boolean {
		return this.appWidth >= brkp.xl;
	}
	get xlAndUp(): boolean {
		return this.appWidth >= brkp.xl;
	}
	get xlAndDown(): boolean {
		return true;
	}
	get isAppMobile(): boolean {
		var pixelRatio = 1;

		if (window && window.devicePixelRatio > 1) {
			pixelRatio = window.devicePixelRatio
		}

		// if (pixelRatio > 1.5) {
		// 	return this.appWidth < brkp_mobile_large;
		// }
		
		return this.appWidth <= brkp_mobile;
		//return this.lgAndDown;
	}

	scrollToTop() {
		window.scrollTo(0, 0);
	}

	get routesList(): appRoute[] {
		return s.routes as any;
	}

	get routes(): appRoute[] {
		return this.$router.getRoutes() as any;
	}
	get variant(): string {
		return this.getRouteColor(this.$route);
	}
	getRouteColor(r: Route): string {
		if (r == null)
			return 'app-primary';
		if (r.meta != null && r.meta.color != null)
			return r.meta.color;
		var route = (this.$router.getRoutes().find(x => x.name == r.name) as any)
		var parent = route == null ? null : route.parent;

		if (parent == null)
			return 'app-primary';
		return this.getRouteColor(parent);
	}
	todo() {
		var self = this;
		self.$bvToast.toast(self.$i18n.t("msg.funzioneNonImplementata").toString(), {
			variant: "warning",
			title: self.$i18n.t("msg.erroreGenerico").toString(),
			solid: true
		});
	}
}
