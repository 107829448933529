import { datiViaggio } from "@/models/carrello/datiViaggio";
import { CarrelloInfoProdotto } from "@/models/carrello/infoProdotto";
import { RigaCarrelloInfo } from "@/models/carrello/rigaCarrello";
import api from "@/services/api";
import { Component, Prop } from "vue-property-decorator";
import { mapMutations, mapState } from "vuex";
import baseUserContextComponent from "./baseUserContextComponent";
import helpers from "@/helpers";

const _ENDPONT = api.endpoint.CARRELLO.CARRELLO;

@Component({
	computed: {
		...mapState({
			datiViaggio: (state: any) => state.datiViaggio
		})
	},
	methods: {
		...mapMutations(["setProdottiCarrello", "setDatiViaggio", "setProdottoSelezionato"])
	}
})
export default class prodottoSelezioanto extends baseUserContextComponent {
	expand: boolean = false;

	@Prop({ default: () => undefined }) prodotto: RigaCarrelloInfo;

	datiViaggio!: datiViaggio;

	setProdottiCarrello!: (setProdottiCarrello: RigaCarrelloInfo[]) => void;
	setProdottoSelezionato!: (setProdottoSelezionato: CarrelloInfoProdotto) => void;
	setDatiViaggio!: (setDatiViaggio: datiViaggio) => void;

	get getDestinazione() {
		var destID = this.prodotto.datiCarrello.dati.statoID,
			destinazioni = this.prodotto.datiProdotto.destinazioni,
			d = destinazioni.find(des => des.code === destID);
		return d ? d.nazione : "";
	}

	get getNazionalita() {
		var nazID = this.prodotto.datiCarrello.dati.nazionalitaID,
			nazioni = this.prodotto.datiProdotto.nazionalita,
			n = nazioni.find(naz => naz.codice === nazID);
		return n ? n.nazione : "";
	}

	get infoTotali() {
		var tot = this.prodotto.datiCarrello.dati.infoTotali;
		tot = tot ? JSON.parse(atob(tot)) : {};
		return tot;
	}

	get getNomiPartecipanti() {
		var elencoPart = this.prodotto.datiCarrello.dati.elencoPartecipanti ? JSON.parse(atob(this.prodotto.datiCarrello.dati.elencoPartecipanti)) : [],
			parts = [];
		for (var i = 0; i < elencoPart.length; i++) {
			if (elencoPart[i].Nome || elencoPart[i].Cognome) parts.push((elencoPart[i].Nome || "") + " " + elencoPart[i].Cognome);
		}
		return parts.join(", ");
	}

	get getPrimoPartecipante() {
		var elencoPart = this.prodotto.datiCarrello.dati.elencoPartecipanti ? JSON.parse(atob(this.prodotto.datiCarrello.dati.elencoPartecipanti)) : [];
		return elencoPart.length ? elencoPart[0].Nome + " " + elencoPart[0].Cognome : "ERRORE PARTECIPANTI";
	}

	get textTotalePersona() {
		//return this.prodotto.datiCarrello.dati.numeroAlloggi ? "Totale per Alloggio" :"Totale per Persona"
		return "Totale per Persona";
	}

	beforeDestroy() {}
	beforeMount() {}
	mounted() {}
	beforeCreate() {}
	created() {}

	onModifica() {
		this.$root.$emit("closeCarrello");

		var d = new datiViaggio(),
			prod = this.prodotto.datiCarrello;

		this.setProdottoSelezionato(this.prodotto.datiProdotto);

		d.note = prod.dati.note;
		d.pacchettoID = prod.dati.pacchettoID;
		d.riferimentoPratica = prod.dati.riferimentoPratica;
		d.tesseraSingola = prod.dati.tesseraSingola;
		d.immaginePartecipanti = prod.dati.imageFilePartecipanti || "";
		//d.complementari = prod.complementari || [];

		var pCollegati = prod.dati.prodottiCollegati || "",
			pCollegatiArray = pCollegati ? JSON.parse(pCollegati) || [] : [];
		// esempio array prod collegati -> "[{\"PacchettoID\":769,\"DestinazioneID\":\"ITA\"}]"

		d.prodottiCollegati = pCollegatiArray;

		d.parametriDichiarazione.checkPolizza = true;
		d.parametriDichiarazione.checkPrivacy = true;
		d.parametriDichiarazione.adeguatezza = prod.dati.adeguatezza;

		var partecipanti = prod.dati.elencoPartecipanti;
		var part = partecipanti ? JSON.parse(atob(partecipanti)) || [] : [];
		d.partecipanti = part;

		d.parametri.codicePromo = prod.dati.codicePromo;
		d.parametri.destinazioneStato = prod.dati.statoID; // da rivedere
		d.parametri.fineViaggio = prod.dati.fineViaggio;
		d.parametri.inizioViaggio = prod.dati.inizioViaggio;
		d.parametri.importoViaggio = prod.dati.importoViaggio;
		d.parametri.secondoImporto = prod.dati.secondoImporto || null;
		d.parametri.nazionalita = prod.dati.nazionalitaID;
		d.parametri.numeroPartecipanti = prod.dati.numeroPartecipanti;
		d.parametri.numeroAlloggi = prod.dati.numeroAlloggi || null;

		d.ulterioriDati = prod.dati.ulterioriDati ? JSON.parse(atob(prod.dati.ulterioriDati)) : [];

		var tot = prod.dati.infoTotali;
		var totali = tot ? JSON.parse(atob(tot)) || [] : [];
		d.totali = totali ? totali.prodotto : {};

		d.itemID = prod.dati.itemID;
		d.inModifica = true;

		this.setDatiViaggio(d);
		if (this.$router.currentRoute && this.$router.currentRoute.name != "infoProdotto") {
			this.$router.push({ path: "infoProdotto?inModifica=true" as string });
		} else {
			this.$root.$emit("setInModifica", true);
		}
	}

	async onElimina() {
		if (!confirm("Eliminare prodotto?")) return;

		await api.delete(_ENDPONT, this.prodotto.datiCarrello.dati.itemID);

		if (this.datiViaggio && this.datiViaggio.itemID == this.prodotto.datiCarrello.dati.itemID) {
			if (this.$router.currentRoute && this.$router.currentRoute.name == "infoProdotto") {
				this.$router.push({ path: "carrelloCategorie" as string });
			}

			this.setDatiViaggio(new datiViaggio());
		}
		var prodottiCarrello = await api.get(_ENDPONT);
		this.setProdottiCarrello(prodottiCarrello);
	}

	async getTerminiECondizioni(prodotto) {
		var self = this;

		
		helpers.getTerminiECondizioni(this,prodotto.pacchettoID, prodotto.prodottoID);

		/*api.getWithAllDataResponse(`${api.endpoint.CARRELLO.TERMINI_E_CONDIZIONI}?pacchettoId=${prodotto.pacchettoID}&prodottoId=${prodotto.prodottoID}`, { responseType: "blob" })
			.then(res => {
				const blob = new Blob([res.data], { type: res.headers["content-type"] });

				if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
					(window.navigator as any).msSaveOrOpenBlob(blob);
				} else {
					var fileURL = URL.createObjectURL(blob);
					window.open(fileURL, "_blank");
				}
			})
			.catch(err => {
				self.$bvToast.toast(self.$i18n.t("errore.fileNotFound").toString(), {
					variant: "danger",
					title: self.$i18n.t("generico.errore").toString(),
					solid: true
				});
			});*/
	}

	get isDescriptionNameOfProduct() {
		if (this.prodotto && this.prodotto.datiProdotto && this.prodotto.datiProdotto.nomeProdotto && this.prodotto.datiProdotto.descrizioneSintetica 
			&& this.prodotto.datiProdotto.nomeProdotto.toLowerCase() == this.prodotto.datiProdotto.descrizioneSintetica.toLowerCase()) 
		{
				return true;
		}

		return false;
	}
}
