export class Preferiti {
	constructor() {
		this.itemID = null;
		this.userID = null;
		this.itemIDPacchetto = null;
	}
	itemID: number | null;
	userID: number | null;
	itemIDPacchetto: number | null;
}
