import { Component, Watch } from "vue-property-decorator";
import { mapActions, mapGetters, mapMutations } from "vuex";
import baseUserContextComponent from "./baseUserContextComponent";
import { CambioPasswordModel, UserLoggedInfoModel } from "@/models/userModels";
import { RouteRecordPublic } from "vue-router";
import * as s from "@/router/router";
import { appRoute } from "@/models/appRoute";
import eventHub from "@/eventHub";
import { AnagraficaDistributore } from "../models/anafrafica/AnagraficaDistributore";
import api from "@/services/api";
import permessi from "@/config/permessi";
import { datiViaggio } from "@/models/carrello/datiViaggio";
import { associaDistributoriPacchettiExtended } from "@/models/carrello/prodotti";
import { anagraficaPacchettiCategorie } from "@/models/carrello/categoria";

@Component({
	methods: {
		...mapGetters(["getToken"]),
		...mapMutations(["setProdotti", "setCategoria", "setAppCulture", "resetProductSearched", "setDatiViaggio"]),
		...mapActions(["logoutUser", "loginUser"])
	}
})
export default class topNavBar extends baseUserContextComponent {
	showCambioPasswordModal: boolean = false;
	cambioPwd: CambioPasswordModel = new CambioPasswordModel();
	myData: AnagraficaDistributore = null;
	showModalAccediCome: boolean = false;
	showDialogPagamentoFineMese: boolean = false;

	resetProductSearched!: () => void;
	setDatiViaggio!: (datiViaggio: datiViaggio) => void;
	setProdotti!: (prodotti: associaDistributoriPacchettiExtended[]) => void;
	setCategoria!: (categoria: anagraficaPacchettiCategorie) => void;

	setAppCulture!: (culture: string | null) => void;
	updateUserInfo!: () => Promise<UserLoggedInfoModel>;
	logoutUser!: () => Promise<boolean>;
	loginUser!: (token: any) => Promise<UserLoggedInfoModel>;
	getToken: () => string;
	doLogout() {
		this.logoutUser().finally(() => this.$router.push({ name: "login" }));
	}
	beforeDestroy() {}
	beforeMount() {}
	mounted() {}
	beforeCreate() {}
	created() {
		var self = this;

		if (this.userMustPay || this.userSegnalazionePagamentoFineMese) {
			this.showDialogPagamentoFineMese = true;
		}

		api.getDatiAgenzia()
			.then(res => {
				self.myData = res;
			})
			.catch(err => {
				console.log(err);
			});
	}
	@Watch("getTokenWrapper")
	onTokenChange() {
		var self = this;
		api.getDatiAgenzia()
			.then(res => {
				self.myData = res;
			})
			.catch(err => {
				console.log(err);
			});
	}
	get getTokenWrapper() {
		return this.getToken();
	}
	get logoPath() {
		return `${this.$appConfig.baseUrl}logo.png`;
		// return `${process.env.BASE_URL}logo.png`;
		//return   `${process.env.VUE_APP_BASE_URL}/logo.png`;
	}
	get items() {
		var self = this;
		return self.routes
			.filter(x => x.meta != null && (!(x.meta as any).mustPay || ((x.meta as any).mustPay && self.userMustPay == false)) && x.meta.topNav != null && self.getRouteAllowedChildren(x).length > 0)
			.sort((a, b) => (a.meta.topNav.displayOrder > b.meta.topNav.displayOrder ? 1 : -1));
	}
	get showButtonAccedicome(): boolean {
		var self = this;
		return self.hasPermesso(permessi.BK.Impostazioni.ACCEDICOME);
	}
	getPath(r: appRoute): string {
		let c = this.getPermissionsFirstChild(r);
		return c.path;
	}
	getLabel(r: appRoute): string {
		return r.meta.label;
	}
	getActiveClass(r: appRoute): string {
		// per ora ci fermiamo a due livelli di navigazione
		var self = this;
		let name = self.$route.name;
		let asctive = self.getRouteAllowedChildren(r).some(x => x.name == name);
		//if (asctive) return "router-link-active text-" + this.variant;
		if (asctive) return "router-link-active text-" + this.variant;
		else return "";
	}
	onChangeCulture(culture: string) {
		this.setAppCulture(culture);
	}
	onAccediComeModalShow() {
		this.showModalAccediCome = true;
	}
	onDisconnectFromUser() {
		var self = this;
		api.disconnectFromSignInAs()
			.then(data => {
				let path = "/";
				self.loginUser(data.data).then(() => {
					// if (self.$route.fullPath != null && self.$route.fullPath != "" && self.$route.fullPath != "/") {
					// 	self.$router.push({ path: path as string });
					// } else {
					// 	self.$emit("user-changed", true);
					// }
					if (self.$route.meta && self.hasPermessi(self.$route.meta.permissions) == false && self.$route.fullPath != null && self.$route.fullPath != "" && self.$route.fullPath != "/") {
						self.$router.push({ path: path as string });
					}

					location.reload();
				});
			})
			.catch(err => {
				console.log(err);
			});
	}
	onCambiaPassword() {
		this.showCambioPasswordModal = true;
	}
	onCambiaPasswordConferma() {
		var self = this;

		var title = this.$i18n.t("msg.salvataggioRiuscito").toString();
		var message = this.$i18n.t("msg.passwordCambiata").toString();
		api.doCambiaPassword(self.cambioPwd).then(token => {
			self.$bvToast.toast(message, {
				variant: "success",
				title: title,
				solid: true
			});
			this.showCambioPasswordModal = false;
		});
	}
	getPermissionsFirstChild(r: appRoute): appRoute {
		if (r == null) return null;
		var self = this;
		let children = self.getRouteAllowedChildren(r);
		return children != null && children.length > 0 ? children[0] : null;
	}

	async goToPreferiti() {
		this.setDatiViaggio(new datiViaggio());
		this.resetProductSearched();

		this.setProdotti([]);
		var categoria = new anagraficaPacchettiCategorie();
		categoria.categoria = "Prodotti Preferiti";
		categoria.descrizione = "preferiti";
		this.setCategoria(categoria);

		eventHub.$emit(eventHub.events.TOGGLE_PREFERITI);
		// if (this.$router.currentRoute.name != "listaProdotti") {
		// 	return "/carrello/listaProdotti";
		// }
	}

	async goToPreventivatore() {
		this.setDatiViaggio(new datiViaggio());
		//this.resetProductSearched();
		
		if (this.$router.currentRoute.name != 'preventivatore') this.$router.push({ name: 'preventivatore' });
	}

	async goToPathname(pathname: string) {
		this.setDatiViaggio(new datiViaggio());

		eventHub.$emit(eventHub.events.RESET_PRODUCT_SEARCHED);

		this.resetProductSearched();
		
		if (this.$router.currentRoute.name != pathname) this.$router.push({ name: pathname });
	}

	get areaManagerUrl() {
		return `${this.$appConfig.areaManagerUrl}/login?tk=${this.getToken()}`;
	}
	areaManagerUrlclick() {
		window.location.href = `${this.$appConfig.areaManagerUrl}/login?tk=${this.getToken()}`;
		return;
	}
	get logoPathDistributore(): string {
		if (this.myData == null || this.myData.bannerHPInterna == null || this.myData.bannerHPInterna == "") return null;
		return `${this.myData.bannerHPInterna}`;
	}

	get testoPagamentoFineMese(): string {
		if (this.userMustPay) {
			return this.$i18n.t("generico.testoForzaPagamentoFineMese").toString();
		}

		return this.$i18n.t("generico.testoSegnalazionePagamentoFineMese").toString();
	}
}
