export class anagraficaPacchettiCategorie {
	itemID: number | null;
	categoria: string;
	descrizione: string;
	ordine: number | null;
	escludiImportoViaggio: boolean | null;
	backgroundColorFrontCard: string;
	colorFrontCard: string;
	backgroundColorBackCard: string;
	backgroundColorDettaglioCategoria: string;
	colorBackCard: string;
	backgroundColorButton: string;
	colorButton: string;
	image: string;
}