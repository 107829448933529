import { anagraficaPacchettiCategorie } from "@/models/carrello/categoria";
import { datiViaggio } from "@/models/carrello/datiViaggio";
import { associaDistributoriPacchettiExtended } from "@/models/carrello/prodotti";
import api from "@/services/api";
import { Component, Prop } from "vue-property-decorator";
import { mapMutations } from "vuex";
import baseUserContextComponent from "./baseUserContextComponent";

const _ENDPONT = api.endpoint.CARRELLO.CONFRONTA_PRODOTTI;
@Component({ methods: {
	...mapMutations(['setProdotti', 'setCategoria', 'setItemsSelected', 'setDatiViaggio'])
} })
export default class carrelloCategoria extends baseUserContextComponent {
	@Prop({ default: () => null }) categoria: anagraficaPacchettiCategorie;

	setProdotti!: (prodotti: associaDistributoriPacchettiExtended[]) => void;
	setCategoria!: (categoria: anagraficaPacchettiCategorie) => void;
	setItemsSelected!: (categoria: associaDistributoriPacchettiExtended[]) => void;
	setDatiViaggio!: (datiViaggio: datiViaggio) => void;

	beforeDestroy() {}
	beforeMount() {}
	mounted() {}
	beforeCreate() {}
	created() {}

	async findProduct() {
		var res = await api.chimataPost<associaDistributoriPacchettiExtended[]>(`${_ENDPONT}`, {
			listIDs: [],
			categoriaID: this.categoria.itemID
		});
		this.setProdotti(res.data);
		this.setCategoria(this.categoria);
		this.setItemsSelected([]);
		this.setDatiViaggio(new datiViaggio());
		this.$router.push({ name: "listaProdotti" });
	}
}
