"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GridCell = void 0; // @ts-ignore

var Vue = require("vue");

var allVue = Vue;
var gh = allVue.h;
var inject = allVue.inject;

var utils_1 = require("./../utils");

var kendo_vue_intl_1 = require("@progress/kendo-vue-intl");

var kendo_vue_common_1 = require("@progress/kendo-vue-common");
/**
 * Represents the default `GridCell` component.
 */


var GridCell = {
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    colSpan: Number,
    columnIndex: Number,
    columnsCount: Number,
    dataIndex: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String,
    render: [String, Function, Object],

	//custom properties
	type: String,
	renderFunction: Function,
	disabled: Boolean,
	pathFallback: String,
	values: Array,
	customButtons: Array,
	downloadFileUrlType: String,
	label: String,
  },
  inject: {
    kendoIntlService: {
      default: null
    }
  },
  methods: {
    triggerClick: function triggerClick() {
      this.$emit('cellclick', {
        dataItem: this.$props.dataItem,
        field: this.$props.field
      });
    },
    triggerKeydown: function triggerKeydown(e) {
      this.$emit('cellkeydown', {
        event: e,
        dataItem: this.$props.dataItem,
        field: this.$props.field
      });
    },
    triggerEdit: function triggerEdit(dataItem) {
      this.$emit('edit', dataItem);
    },
    triggerAdd: function triggerAdd(dataItem) {
      this.$emit('add', dataItem);
    },
    triggerCancel: function triggerCancel(dataItem) {
      this.$emit('cancel', dataItem);
    },
    triggerSave: function triggerSave(dataItem) {
      this.$emit('save', dataItem);
    },
    triggerRemove: function triggerRemove(dataItem) {
      this.$emit('remove', dataItem);
    }
  },
  // @ts-ignore
  setup: !gh ? undefined : function () {
    var v3 = !!gh;
    var kendoIntlService = inject('kendoIntlService', {});
    return {
      v3: v3,
      kendoIntlService: kendoIntlService
    };
  },
  // @ts-ignore
  render: function render(createElement) {
    var h = gh || createElement;
    var defaultRendering = null;

    if (this.$props.rowType === 'groupFooter') {
      defaultRendering = h("td", {
        "class": this.$props.className
      });
    } else if (this.$props.field !== undefined && this.$props.rowType !== 'groupHeader') {
      var data = utils_1.getNestedValue(this.$props.field, this.$props.dataItem);
      var dataAsString = '';

      if (data !== undefined && data !== null) {
        dataAsString = this.$props.format ? kendo_vue_intl_1.provideIntlService(this).format(this.$props.format, data) : data.toString();
      }

      defaultRendering = h("td", {
        colSpan: this.$props.colSpan,
        attrs: this.v3 ? undefined : {
          colSpan: this.$props.colSpan
        },
        "class": this.$props.className,
        onKeydown: this.triggerKeydown,
        on: this.v3 ? undefined : {
          "keydown": this.triggerKeydown,
          "click": this.triggerClick
        },
        onClick: this.triggerClick
      }, [dataAsString]);
    }

    return kendo_vue_common_1.getTemplate.call(this, {
      h: h,
      template: this.$props.render,
      defaultRendering: defaultRendering,
      additionalProps: this.$props,
      additionalListeners: {
        click: this.triggerClick,
        keydown: this.triggerKeydown,
        edit: this.triggerEdit,
        add: this.triggerAdd,
        cancel: this.triggerCancel,
        save: this.triggerSave,
        remove: this.triggerRemove
      }
    });
  }
};
exports.GridCell = GridCell;