import baseContextComponent from "@/components/baseContextComponent";
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component({})
export default class defaultLayout extends baseContextComponent {
	get fixedFooter(): boolean {
		return this.$appConfig.footer.fixed == null ? true : this.$appConfig.footer.fixed;
	}
}
