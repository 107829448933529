
const appConfig = {

	footer: {
		htmlText: `
			<div class="text-center " style="color: white">
				<div class="mb-1" style="font-weight: 600; font-size: 11px">Webins.It By B&T Insurance Service Srl</div>
				<div style="font-size: 11px">Numero Verde: <span class="">800.063.300</span></div>
				<div style="font-size: 11px"><a class="" style="color: white" href= "mailto:info@webins.it">info@webins.it</a></div>
			</div>`,
		cssClass: 'px-3 pb-2 pt-2 bg-black-lighten',
		fixed: false
	},
	loginPage: {
		headerText: "WebIns"
	}
}
export default appConfig