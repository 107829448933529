// import Vue from 'vue'
import { TextValueItem } from "@/models/TextValueItem";
import api from "@/services/api";
import { Component, Prop, Watch } from "vue-property-decorator";
import gridComponentBase from "../../components/grid/gridComponentBase.vue";
import permessi from "@/config/permessi";
import apiFile from "@/services/apiFile";
import { ModificheAbilitazione } from "@/models/modificaPratica/modificheAbilitazione";
import { ElencoAdesioni } from "@/models/modificaPratica/elencoAdesioni";
import helpers from "@/helpers";
import { ModificaOperazioneItem } from "@/models/modificaPratica/modificaOperazioneItem";
import moment from "moment";
import { PartecipantiCarrello } from "@/models/carrello/partecipantiCarrello";

const _ENDPONT = api.endpoint.CARRELLO.MODIFICA_PRATICA;

@Component({})
export default class modificaPraticaDettaglioPage extends gridComponentBase {
	@Prop({ default: () => null }) pratica: ElencoAdesioni;

	/*get PERMESSI(): string[] {
		return [permessi.BK.Vendite.GARANZIE];
	}*/

	abilitazioni: ModificheAbilitazione = new ModificheAbilitazione();
	modifiche: ModificaOperazioneItem = new ModificaOperazioneItem();

	dataEditable: boolean = false;
	importoEditable: boolean = false;
	numPartecipantiEditable: boolean = false;
	partecipantiEditable: boolean = false;

	causaliDate: string[] = [];
	causaliImporto: string[] = [];
	causaliNumPartecipanti: string[] = [];
	causaliPartecipanti: string[] = [];

	partecipantiPageData: PartecipantiCarrello[] = [];
	datiPartecipanti: PartecipantiCarrello[] = [];
	pagePartecipanti: number = 1;
	pageSizePartecipanti: number = 10;

	
	richiestaModificaDisabled: boolean = false;

	get partecipanti() {
		return this.getPartecipanti(this.pratica.partecipantiElenco).join(", ");
	}

	@Watch("pagePartecipanti", { immediate: true })
	async changeScrollGrid() {
		this.scrollGrid();
	}

	created() {
		this.getData();
	}
	getData() {
		var self = this;
		api.get(`${_ENDPONT}/${this.pratica.itemID}`).then(res => {
			self.abilitazioni = res.item1;
			self.modifiche.adesioneModificata = helpers.cloneObject(self.pratica);

			if (self.abilitazioni.chDateCausali) {
				self.causaliDate = self.abilitazioni.chDateCausali.split(";");
				self.modifiche.chDateCausale = self.causaliDate[0];
			}
			if (self.abilitazioni.chImportoViaggioCausali) {
				self.causaliImporto = self.abilitazioni.chImportoViaggioCausali.split(";");
				self.modifiche.chImportoViaggioCausale = self.causaliImporto[0] ;
			}
			if (self.abilitazioni.chNumeroAssicuratiCausali) {
				self.causaliNumPartecipanti = self.abilitazioni.chNumeroAssicuratiCausali.split(";");
				self.modifiche.chNumeroAssicuratiCausale = self.causaliNumPartecipanti[0];
			}
			if (self.abilitazioni.chNomiCausali) {
				self.causaliPartecipanti = self.abilitazioni.chNomiCausali.split(";");
				self.modifiche.chNomiCausali = self.causaliPartecipanti[0];
			}

			self.dateEditable = self.abilitazioni.chDate && (!self.abilitazioni.chDateDeltaGiorni || moment(self.pratica.inizioViaggio).add(self.abilitazioni.chDateDeltaGiorni) < moment());
			self.importoEditable = self.abilitazioni.chImportoViaggio && (!self.abilitazioni.chImportoViaggioDeltaGiorni || moment(self.pratica.inizioViaggio).add(self.abilitazioni.chImportoViaggioDeltaGiorni) < moment());
			self.numPartecipantiEditable = self.abilitazioni.chNumeroAssicurati && (!self.abilitazioni.chNumeroAssicuratiDeltaGiorni || moment(self.pratica.inizioViaggio).add(self.abilitazioni.chNumeroAssicuratiDeltaGiorni) < moment());
			self.partecipantiEditable = self.abilitazioni.chNomi && (!self.abilitazioni.chNomiDeltaGiorni || moment(self.pratica.inizioViaggio).add(self.abilitazioni.chNomiDeltaGiorni) < moment());
	

			if (self.abilitazioni.soloRichiestaModifica) {
				self.modifiche.richiestaModifica = true;
			}

			self.initPartecipanti();
		});
	}

	getPartecipanti(data: string) {
		var array = JSON.parse(data) as PartecipantiCarrello[];
		let partecipanti: string[] = [];
		if (array && array.length > 0) {
			partecipanti = array.map(function(item) {
				return `${item.Cognome} ${item.Nome}`;
			});
		}
		return partecipanti;
	}

	onSalva() {
		var self = this,
			dati = this.modifiche,
			newAd = dati.adesioneModificata,
			abilitazioni = this.abilitazioni;

		debugger;
		// DATE
		if (this.dateEditable || dati.richiestaModifica) {
			if (!moment(newAd.inizioViaggio).isSame(moment(this.pratica.inizioViaggio), "day") || !moment(newAd.fineViaggio).isSame(moment(this.pratica.fineViaggio), "day")) {
				// modifica effettuata

				if (!dati.chDateCausale && abilitazioni.chDateCausaleObbligatoria) {
					this.$bvToast.toast("Specificare una causale per la modifica delle date di viaggio", { variant: "danger", title: this.$i18n.t("msg.erroreGenerico").toString(), solid: true });
					return;
				}


				if (!dati.chDateFlagWarning && abilitazioni.chDateFlagWarning) {
					this.$bvToast.toast("Confermare di possedere adeguata documentazione per la modifica delle date di viaggio", { variant: "danger", title: this.$i18n.t("msg.erroreGenerico").toString(), solid: true });
					return;
				}
			}
		} else {
			newAd.inizioViaggio = this.pratica.inizioViaggio;
			newAd.fineViaggio = this.pratica.fineViaggio;
		}

		// IMPORTO
		if (this.importoEditable || dati.richiestaModifica) {
			if (newAd.importoViaggio !== this.pratica.importoViaggio) {
				if (!dati.chImportoViaggioCausale && abilitazioni.chImportoViaggioCausaleObbligatoria) {
					this.$bvToast.toast("Specificare una causale per la modifica dell'importo di viaggio", { variant: "danger", title: this.$i18n.t("msg.erroreGenerico").toString(), solid: true });
					return;
				}

				if (!dati.chImportoViaggioFlagWarning && abilitazioni.chImportoViaggioFlagWarning) {
					this.$bvToast.toast("Confermare di possedere adeguata documentazione per la modifica dell'importo di viaggio", { variant: "danger", title: this.$i18n.t("msg.erroreGenerico").toString(), solid: true });
					return;
				}
			}
		} else {
			newAd.importoViaggio = this.pratica.importoViaggio;
		}


		// NUMERO PARTECIPANTI
		if (this.numPartecipantiEditable || dati.richiestaModifica) {
			if (newAd.numeroPartecipanti !== this.pratica.numeroPartecipanti) {
				if (!dati.chNumeroAssicuratiCausale && abilitazioni.chNumeroAssicuratiCausaleObbligatoria) {
					this.$bvToast.toast("Specificare una causale per la modifica del numero dei partecipanti", { variant: "danger", title: this.$i18n.t("msg.erroreGenerico").toString(), solid: true });
					return;
				}

				if (!dati.chNumeroAssicuratiFlagWarning && abilitazioni.chNumeroAssicuratiFlagWarning) {
					this.$bvToast.toast("Confermare di possedere adeguata documentazione per la modifica del numero dei partecipanti", { variant: "danger", title: this.$i18n.t("msg.erroreGenerico").toString(), solid: true });
					return;
				}
			}
		} else {
			newAd.numeroPartecipanti = this.pratica.numeroPartecipanti;
		}


		// PARTECIPANTI
		var partecipantiGriglia = this.datiPartecipanti;
		if (this.partecipantiEditable || dati.richiestaModifica) {
			if (JSON.stringify(partecipantiGriglia) !== this.pratica.partecipantiElenco) {

				for (let i = 0; i < partecipantiGriglia.length; i++) {
					var p = partecipantiGriglia[i];
					if (!p.Cognome) {
						this.$bvToast.toast("Cognome richiesto per tutti i partecipanti", { variant: "danger", title: this.$i18n.t("msg.erroreGenerico").toString(), solid: true });
						return;
					}
		
					if (p.CodiceFiscale && p.CodiceFiscale.length > 16) {
						this.$bvToast.toast("Il codice fiscale del passeggero " + (p.Nome || "") + " " + (p.Cognome || "") + " ha piu' di 16 caratteri. Correggerlo", { variant: "danger", title: this.$i18n.t("msg.erroreGenerico").toString(), solid: true });
						return;
					}
				}

				if (!dati.chNomiCausali && abilitazioni.chNomiCausaleObbligatoria) {
					this.$bvToast.toast("Specificare una causale per la modifica dei partecipanti di viaggio", { variant: "danger", title: this.$i18n.t("msg.erroreGenerico").toString(), solid: true });
					return;
				}

				if (!dati.chNomiFlagWarning && abilitazioni.chNomiFlagWarning) {
					this.$bvToast.toast("Confermare di possedere adeguata documentazione per la modifica dei partecipanti di viaggio", { variant: "danger", title: this.$i18n.t("msg.erroreGenerico").toString(), solid: true });
					return;
				}
			}
		} else {
			newAd.partecipantiElenco = this.pratica.partecipantiElenco;
		}
		api.chimataPost(`${_ENDPONT}/${this.pratica.itemID}`, dati).then(res => { 
			self.$bvToast.toast("Modifica salvata correttamente!", {
				variant: "success",
				title: "Modifica eseguita",
				solid: true
			});
			self.$emit("reload-pratiche");

		}).catch(err => {
			//this.$bvToast.toast(err || "Si sono verificati degli errori", { variant: "danger", title: this.$i18n.t("msg.erroreGenerico").toString(), solid: true });
					
		});

	}


	initPartecipanti(){
		var array = JSON.parse(this.pratica.partecipantiElenco) as PartecipantiCarrello[];
		this.datiPartecipanti = array;
		this.scrollGrid();
	}

	scrollGrid() {
		this.partecipantiPageData = [];

		for(var i = (this.pagePartecipanti - 1) * this.pageSizePartecipanti; i<this.pagePartecipanti * this.pageSizePartecipanti; i++) {
			if(i >= this.datiPartecipanti.length) break;
			this.partecipantiPageData.push(this.datiPartecipanti[i]);
		} 
	}

	changeNumPartecipanti(e) {
		var valore = e.value || 0,
			ds = this.datiPartecipanti;

		if( valore < 1)
			valore = 1;

		var len = ds.length,
			i = 0;
		if (valore < len) {
			for (i = valore; i < len; i++)
				ds.pop();
		}
		else {
			for (i = len; i < valore; i++) {
				var obj = new PartecipantiCarrello();
				obj.ItemID = i+1;
				obj.Nome = "";
				obj.Cognome = "";
				obj.CodiceFiscale = "";
				obj.DataNascita = null;
				ds.push(obj);
			}
		}

		this.pagePartecipanti = 1;
		this.scrollGrid();
	}
}
