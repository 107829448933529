export class Ruolo {
	/**
	 *
	 */
	constructor() {
		this.itemID = null;
		this.codice = null;
		this.descrizione = null;
		this.superAdmin = false;
		this.backOffice = false;
		this.fisso = false;
		this.disponibileAssociazioneWeb = false;
	}
	itemID: number | null;
	codice: string;
	descrizione: string;
	superAdmin: boolean;
	backOffice: boolean;
	fisso: boolean;
	disponibileAssociazioneWeb: boolean;
}

export class PermessoGruppo {
	constructor() {
		this.gruppo = '';
		this.permessi = [];
		this.visible = true;
	}
	gruppo: string;
	permessi: Permesso[];
	visible: boolean;
}

export interface Permesso {
	itemID: number;
	codice: string;
	descrizione: string;
	azioneSingola: boolean | null;
	gruppo: string;
	sottoGruppo: string;
	distplayOrder: number | null;
	backOffice: boolean | null;
}

export interface RuoloPermesso {
	ruoloID: number;
	codice: string;
	azioni: Azioni[];
}

export enum Azioni {
	R = "R", // read
	C = "C", // create
	U = "U", // update
	D = "D", // delete
	E = "E", // ex
}